import React from "react";
import { FormInput } from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import logo from "../../images/ixperience1.svg";
import logoLangResize from "../../images/logo-lang-resize.png";

import Button from "../../components/other/Button";
import ModalContact from "../../components/general/ModalContact";

export default class SigninAxa extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reference: "",
      licensePlate: "",
    };
  }

  handleSignin = (event) => {
    event.preventDefault();

    if (this.state.reference && this.state.licensePlate) {
      document.location.href = `/axa/${this.state.reference}/${this.state.licensePlate}`;
    }
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      isError: false,
    });
  };

  render() {
    return (
      <>
        <Row className="w-100 overflow-y-auto">
          {window.innerWidth > 540 ? (
            <Col xs={12} md={6} className="d-flex">
              <div className="background-test">
                <img
                  src={logo}
                  alt="logo ixperience"
                  style={{ maxWidth: "100%", maxHeight: "36px" }}
                />
                <h3>
                  Bienvenue sur le portail dédié au suivi de votre dossier de
                  sinistre.
                </h3>
                <img
                  height={60}
                  alt="groupe lang & associés"
                  src={logoLangResize}
                />
                <p>
                  Espace personnel qui vous permet de suivre en temps réel
                  l'évolution du traitement de votre dossier et recevoir des
                  informations ou des documents de la part de votre expert.
                </p>
                <p>
                  Vous pouvez également prendre rendez-vous avec nos services,
                  nous contacter par messagerie ou nous faire parvenir des
                  documents..
                </p>
              </div>
            </Col>
          ) : (
            <Col xs={12} md={6} className="d-flex">
              <div className="mt-auto ml-auto mr-auto text-center">
                <img
                  src={logo}
                  alt="logo ixperience"
                  style={{ maxWidth: "100%", height: "36px" }}
                />
                <h4>
                  Bienvenue sur le portail dédié au suivi de votre dossier de
                  sinistre.
                </h4>
                <img
                  height={60}
                  alt="groupe lang & associés"
                  src={logoLangResize}
                />
              </div>
            </Col>
          )}
          <Col xs={12} md={6} className="mt-20 mb-20 d-flex">
            <div
              className="m-auto bg-white"
              style={{
                borderRadius: "15px",
                padding: "40px",
              }}
            >
              <h2 className="m-0 mb-10">Accès dossier AXA</h2>
              <FormInput
                className="mb-20"
                title="Référence sinistre"
                placeholder="1234"
                name="reference"
                type="reference"
                value={this.state.reference}
                onChange={this.handleInputChange}
                required
              />
              <FormInput
                className="mb-20"
                title="Immatriculation"
                placeholder="AA123AA"
                name="licensePlate"
                type="licensePlate"
                value={this.state.licensePlate}
                onChange={this.handleInputChange}
                required
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    this.handleSignin(e);
                  }
                }}
              />
              <div className="d-flex">
                <Button
                  className="mt-20 w-auto ml-auto"
                  text="Continuer"
                  onClick={this.handleSignin}
                >
                  <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <ModalContact />
      </>
    );
  }
}
