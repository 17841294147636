import axiosConfig from "../utils/axiosConfig";

const getElectronicSignature = (data, callback) => {
  axiosConfig("other/electronic-signature/get-electronic-signature", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const getExternalElectronicSignature = (data, callback) => {
  axiosConfig("other/electronic-signature/get-external-electronic-signature", {
    data,
  })
    .then((res) => {
      callback(res.data);
    })
    .catch((err) => {
      callback({ success: false });
    });
};

const updateElectronicSignature = (data, callback) => {
  axiosConfig("other/electronic-signature/update-electronic-signature", {
    data,
  })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

export {
  getElectronicSignature,
  getExternalElectronicSignature,
  updateElectronicSignature,
};
