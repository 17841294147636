import React from "react";
import {
  Card,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Pagination,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";

import AuthContext from "../../context/AuthContext";

export default class List extends React.Component {
  constructor(props) {
    super(props);

    this.sortRef = [
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
    ];
  }

  static contextType = AuthContext;

  render() {
    const tableHead = [
      { title: "État", sort: false },
      { title: "N° dossier", nameSort: "folder", sort: true },
      { title: "N° sinistre", nameSort: "sinister", sort: true },
      { title: "Accident", nameSort: "date", sort: true },
      { title: "Véhicule", nameSort: "vehicle", sort: true },
      { title: "Immat.", nameSort: "registration", sort: true },
      ...(this.context.source !== "client"
        ? [{ title: "Assuré", nameSort: "client", sort: true }]
        : []),
      ...(this.context.source !== "garage"
        ? [{ title: "Réparateur", nameSort: "repairman", sort: true }]
        : []),
      ...(this.context.source !== "company"
        ? [{ title: "Compagnie", nameSort: "company", sort: true }]
        : []),
      { title: "Cabinet", nameSort: "agency", sort: true },
    ];

    return (
      <Card className="mb-20">
        <div className="overflow-x-auto">
          <Table empty={!this.props.data.length ? "Aucun dossier" : ""}>
            <Thead>
              <Tr>
                {tableHead.map((elem, idx) => (
                  <Th
                    key={idx}
                    ref={this.sortRef[idx]}
                    sort={elem.sort ? true : false}
                    nameSort={elem.nameSort}
                    onSort={
                      elem.sort
                        ? (desc) =>
                            this.props.handleSortClick(desc, idx, this.sortRef)
                        : null
                    }
                  >
                    {elem.title}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {this.props.data.map((element, key) => (
                <Tr
                  key={key}
                  className="cursor-pointer"
                  onClick={() => {
                    if (this.props.onClick) {
                      this.props.onClick(element);
                    } else {
                      this.props.history.push(
                        `/folder/${element.token}`,
                        this.props.state
                      );
                    }
                  }}
                >
                  <Td
                    className={`nowrap bg-${element.statusColor}-light text-center ${element.statusColor} pl-10 pr-10`}
                    style={{ fontSize: "12px" }}
                  >
                    {element.statusHome}
                  </Td>
                  <Td className="nowrap">{element.reference}</Td>
                  <Td className="nowrap">{element.sinisterId}</Td>
                  <Td className="nowrap">{element.date}</Td>
                  <Td>
                    {element.brand} {element.model}
                  </Td>
                  <Td className="nowrap">{element.registration}</Td>
                  {this.context.source !== "client" ? (
                    <Td>{element.name}</Td>
                  ) : null}
                  {this.context.source !== "garage" ? (
                    <Td>{element.repairman}</Td>
                  ) : null}
                  {this.context.source !== "company" ? (
                    <Td>{element.company}</Td>
                  ) : null}
                  <Td>{element.agency}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </div>
        {this.props.total > this.props.tabLength ? (
          <Pagination
            className="mt-10"
            activePage={this.props.activePage}
            itemsCountPerPage={this.props.tabLength}
            totalItemsCount={this.props.total}
            pageRangeDisplayed={4}
            onChange={this.props.handlePageChange.bind(this)}
            firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
            lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
            prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
            nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
            hideNavigation={true}
            hideFirstLastPages={true}
          />
        ) : null}
      </Card>
    );
  }
}
