import React from "react";
import { Card, FullModal } from "ui-kit-ck-consultant";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getFileUrl } from "../../../utils/general";

import AuthContext from "../../../context/AuthContext";

export default class Picture extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalPicture: false,
      modalIdx: null,
      pictures: [],
    };
  }

  static contextType = AuthContext;

  render() {
    return (
      <>
        <Card
          title="Photos du dossier"
          className="h-100"
          classNameChildren="mt-auto mb-auto d-flex overflow-x-auto"
        >
          <div
            style={{ whiteSpace: "nowrap" }}
            className="d-flex ml-auto mr-auto"
          >
            {this.props.dataPictures.map((picture, idx) => (
              <div
                key={picture.token}
                className={"d-flex flex-column"}
                style={{ position: "relative" }}
              >
                {this.context.source === "agency" && (
                  <div
                    className="p-5 cursor-pointer"
                    style={{
                      position: "absolute",
                      top: -10,
                      left: -5,
                      background: "#E54E4E",
                      borderRadius: "100%",
                      zIndex: 10,
                    }}
                    onClick={() => {
                      if (
                        window.confirm(
                          "Êtes-vous sûr de vouloir masquer cette image ?"
                        )
                      ) {
                        this.context.hideFile(
                          this.props.dataPictures[idx].token
                        );
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faEyeSlash} color="white" />
                  </div>
                )}
                <img
                  className="cursor-pointer mb-10 mr-10"
                  style={{
                    height: "120px",
                    border: "solid 1px gray",
                    borderRadius: "8px",
                    minWidth: 80,
                  }}
                  alt={`folder-${idx}`}
                  loading="lazy"
                  src={getFileUrl(
                    picture.folderId,
                    picture.token,
                    picture.source
                  )}
                  onClick={() =>
                    this.setState({
                      isModalPicture: true,
                      modalIdx: idx,
                    })
                  }
                />
              </div>
            ))}
          </div>
        </Card>
        <FullModal
          show={this.state.isModalPicture}
          onClose={() =>
            this.setState({
              isModalPicture: false,
              modalIdx: null,
            })
          }
          className="text-center"
        >
          {this.props.dataPictures[this.state.modalIdx] ? (
            <img
              style={{ maxHeight: "100%", maxWidth: "100%" }}
              alt="full"
              src={getFileUrl(
                this.props.dataPictures[this.state.modalIdx].folderId,
                this.props.dataPictures[this.state.modalIdx].token,
                this.props.dataPictures[this.state.modalIdx].source
              )}
            />
          ) : null}
        </FullModal>
      </>
    );
  }
}
