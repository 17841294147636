import React from "react";
import { Redirect } from "react-router-dom";
import {
  Header,
  ButtonIcon,
  Footer,
  Modal,
  Loader,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faPowerOff } from "@fortawesome/free-solid-svg-icons";

import Style from "../components/Style";

import logo from "../images/Ixperience4.svg";

import { legal } from "../assets/legal";
import { rgpdAndCookies } from "../assets/rgpd";

import { getMessageTypes } from "../requests/contact";
import { getFolder } from "../requests/folder";
import { signout } from "../requests/auth";

import AuthContext from "../context/AuthContext";

export default class DashboardLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      isReady: false,
      isModalLegal: false,
      isModalRGPD: false,
      color: null,
      // Folder
      id: null,
      messageTypes: [],
      agency: {},
      expert: {},
      garage: {},
      principal: {},
      sinister: {},
      vehicle: {},
      client: {},
      totalReportDutyFree: "",
      dataAdministrative: [],
      countFolder: 0,
      token: null,
    };
  }

  static contextType = AuthContext;

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  componentDidMount() {
    if (
      this.props.match.path !== "/folder/:token" &&
      this.props.match.path !== "/folder/:token/:message" &&
      this.props.match.path !== "/axa/:reference/:licensePlate"
    ) {
      this.setState({ isReady: true });
    } else {
      this.getFolder();
    }
  }

  handleSignOut = () => {
    signout(() => {
      document.location.href = "/signin";
    });
  };

  getFolder = () => {
    if (
      this.props.match.params.token ||
      (this.props.match.params.reference &&
        this.props.match.params.licensePlate)
    ) {
      getFolder(
        {
          folderToken: this.props.match.params.token,
          reference: this.props.match.params.reference,
          licensePlate: this.props.match.params.licensePlate,
        },
        (resultFolder) => {
          if (resultFolder.success) {
            const dataFolder = resultFolder.data;

            getMessageTypes(
              { folderId: dataFolder.id },
              (resultMessageTypes) => {
                if (resultMessageTypes.success) {
                  let messageTypes = resultMessageTypes.data;

                  this.setState({
                    isReady: true,
                    color: dataFolder.color,
                    id: dataFolder.id,
                    messageTypes: [
                      {
                        value: "DEFAULT",
                        text: "Choisir une option",
                        disabled: true,
                      },
                      ...messageTypes,
                    ],
                    agency: dataFolder.agency,
                    expert: dataFolder.expert,
                    garage: dataFolder.garage,
                    principal: dataFolder.principal,
                    sinister: dataFolder.sinister,
                    vehicle: dataFolder.vehicle,
                    client: dataFolder.client,
                    owner: dataFolder.owner,
                    totalReportDutyFree: dataFolder.totalReportDutyFree,
                    dataAdministrative: dataFolder.dataAdministrative,
                    countFolder: dataFolder.countFolder,
                    token: dataFolder.token,
                  });
                } else {
                  alert(
                    "Vos identifiants ne vous permettent pas d'accéder à ce dossier, merci de nous contacter"
                  );
                  document.location.href = "/home";
                }
              }
            );
          } else {
            alert(
              "Vos identifiants ne vous permettent pas d'accéder à ce dossier, merci de nous contacter"
            );
            if (document.location.href.includes("/axa/")) {
              document.location.href = "/axa";
            } else {
              document.location.href = "/home";
            }
          }
        }
      );
    }
  };

  getSourceText = (source) => {
    if (source === "client") {
      return "Assuré";
    } else if (source === "garage") {
      return "Réparateur";
    } else if (source === "company") {
      return "Assureur";
    } else if (source === "principal") {
      return "Mandant";
    } else if (source === "agency") {
      return "Expert";
    } else {
      return "";
    }
  };

  render() {
    if (
      this.props.connected === false &&
      !document.location.href.includes("/axa/")
    ) {
      return <Redirect to="/signin" />;
    } else if (!this.state.isReady) {
      return <Loader show={true} />;
    } else {
      return (
        <AuthContext.Provider
          value={{
            ...this.context,
            color: this.context.color || this.state.color,
            // Folder
            id: this.state.id,
            token: this.props.match.params.token || this.state.token,
            messageTypes: this.state.messageTypes,
            agency: this.state.agency,
            expert: this.state.expert,
            garage: this.state.garage,
            principal: this.state.principal,
            sinister: this.state.sinister,
            vehicle: this.state.vehicle,
            client: this.state.client,
            owner: this.state.owner,
            totalReportDutyFree: this.state.totalReportDutyFree,
            dataAdministrative: this.state.dataAdministrative,
            countFolder: this.state.countFolder,
          }}
        >
          <Style color={this.context.color || this.state.color} />
          <div className="Dashboard-container">
            <div className="Dashboard-container-body">
              <Header
                logo={logo}
                height="42"
                onClick={() =>
                  this.props.history.push("/home", this.props.location.state)
                }
              >
                {window.innerWidth >= 1000 ? (
                  <h3
                    className="white m-auto mr-10 nowrap"
                    style={{ fontWeight: "normal", fontSize: "16px" }}
                  >
                    {this.props.username} -{" "}
                    {this.getSourceText(this.props.source)}
                  </h3>
                ) : (
                  ""
                )}
                <ButtonIcon
                  style={{ color: "#ffffff" }}
                  onClick={() => this.props.history.push("/setting")}
                >
                  <FontAwesomeIcon icon={faUser} />
                </ButtonIcon>
                <ButtonIcon
                  style={{ color: "#fff" }}
                  onClick={this.handleSignOut}
                >
                  <FontAwesomeIcon icon={faPowerOff} />
                </ButtonIcon>
              </Header>
              <div className="Dashboard-content">
                <div
                  style={{
                    maxWidth: "1400px",
                    width: "100%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  {this.props.connected === true ||
                  document.location.href.includes("/axa/")
                    ? this.props.children
                    : ""}
                </div>
                <Footer
                  logo={logo}
                  onLegalClick={() => this.setState({ isModalLegal: true })}
                  onRgpdClick={() => this.setState({ isModalRGPD: true })}
                />
                <Modal
                  show={this.state.isModalLegal}
                  large
                  onClose={() => this.setState({ isModalLegal: false })}
                  title="Mentions légales"
                >
                  {legal}
                </Modal>
                <Modal
                  show={this.state.isModalRGPD}
                  large
                  onClose={() => this.setState({ isModalRGPD: false })}
                  title="Données personnelles & utilisation des cookies"
                >
                  {rgpdAndCookies}
                </Modal>
                <Loader show={this.state.isLoad} />
              </div>
            </div>
          </div>
        </AuthContext.Provider>
      );
    }
  }
}
