import React from "react";

export default class Style extends React.Component {
  render() {
    if (!this.props.color) {
      return null;
    }

    return (
      <style>
        {`
          *{
            --primary-color: ${this.props.color.primary} !important;
            --secondary-color: ${this.props.color.secondary} !important;
          }
        `}
      </style>
    );
  }
}
