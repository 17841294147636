import React from "react";
import {
  Modal,
  FormInput,
  FormSelect,
  FormTextarea,
  Loader,
  DropzoneMultiple,
} from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { RCamera } from "react-camera-media-stream";

import { insertMessage } from "../../requests/folder";

import { getIndex } from "../../utils/general";

import AuthContext from "../../context/AuthContext";
import Button from "../other/Button";

export default class ModalMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      to: "",
      subject: "",
      message: "",
      files: [],
      isCamera: false,
      isInvoice: false,
      invoice: "",
    };
  }

  static contextType = AuthContext;

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value }, () => {
      if (name === "subject") {
        if (
          parseInt(value) ===
          this.getValueOptions("Facture réparation sinistre")
        ) {
          this.invoiceVerification();
        } else {
          this.setState({
            isInvoice: false,
          });
        }
      }
    });
  };

  componentDidUpdate(prevProps) {
    if (
      (prevProps.messageType !== this.props.messageType ||
        prevProps.show !== this.props.show) &&
      this.props.show === true
    ) {
      this.selectSubject();
    }
  }

  getValueOptions = (text) => {
    if (this.context.messageTypes.filter((el) => el.text === text).length > 0) {
      return this.context.messageTypes.filter((el) => el.text === text)[0]
        .value;
    } else {
      return "";
    }
  };

  selectSubject = () => {
    switch (this.props.messageType) {
      case "contact":
        this.setState({ subject: this.getValueOptions("Contact") });
        break;
      case "claim":
        this.setState({ subject: this.getValueOptions("Réclamation") });
        break;
      case "more":
        this.setState({
          subject: this.getValueOptions("Demande de supplément"),
        });
        break;
      case "other":
        this.setState({ subject: this.getValueOptions("Divers") });
        break;
      case "invoice":
        this.setState(
          { subject: this.getValueOptions("Facture réparation sinistre") },
          this.invoiceVerification
        );
        break;
      default:
        this.setState({
          subject: "",
        });
        break;
    }
  };

  onClose = (isReload = false) => {
    this.setState(
      {
        isLoad: false,
        subject: "",
        message: "",
        invoice: "",
        files: [],
        isCamera: false,
        isInvoice: false,
      },
      () => this.props.onClose(isReload)
    );
  };

  invoiceVerification = () => {
    this.setState({
      isInvoice: true,
      invoice: "",
    });
  };

  insertMessage = () => {
    if (
      (this.props.to || this.state.to) &&
      this.state.subject &&
      ((this.state.isInvoice && this.state.invoice) || !this.state.isInvoice)
    ) {
      if (
        (this.state.isInvoice && this.state.files.length > 0) ||
        !this.state.isInvoice
      ) {
        this.setState({ isLoad: true }, () => {
          let idx = getIndex(
            this.context.messageTypes,
            parseInt(this.state.subject),
            "value"
          );

          let name = "";
          if (idx >= 0) {
            name = this.context.messageTypes[idx].text;
          }

          insertMessage(
            {
              folderId: this.context.id,
              type: this.state.subject,
              name,
              message: this.state.isInvoice
                ? `${this.state.message}</br></br>Montant réparation HT : ${this.state.invoice} €`
                : this.state.message,
              isInvoice:
                parseInt(
                  this.state.invoice.replace(/,/g, ".").replace(/ /g, "")
                ) === parseInt(this.context.totalReportDutyFree),
              files: this.state.files,
              toSource: this.props.to ? this.props.to : this.state.to,
            },
            (result) => {
              if (result.success) {
                this.setState({ isLoad: false }, () => {
                  this.props.callback();
                  alert("Votre message a bien été envoyé");
                  this.onClose(true);
                });
              } else {
                this.setState({ isLoad: false }, () => {
                  alert("Une erreur est survenue");
                });
              }
            }
          );
        });
      } else {
        alert("Veuillez ajouter la facture en pièce jointe");
      }
    } else {
      alert("Veuillez remplir tous les champs");
    }
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onClose={this.onClose}
        title="Nouveau message"
        large
      >
        <Row>
          <Col xs={12} md={6}>
            <FormSelect
              className="mb-20"
              title="Destinataire"
              name="to"
              value={this.props.to ? this.props.to : this.state.to}
              ignore={true}
              options={[
                {
                  value: "DEFAULT",
                  text: "Veuillez sélectionner un destinataire",
                  disabled: true,
                },
                {
                  value: "agency",
                  text: `Agence - ${this.context.agency.name}`,
                  disabled:
                    this.context.source === "agency" && this.context.user !== 1,
                },
                {
                  value: "client",
                  text: `Assuré - ${this.context.client.name}`,
                  disabled: this.context.source === "client",
                },
                {
                  value: "garage",
                  text: `Réparateur - ${this.context.garage.name}`,
                  disabled: this.context.source === "garage",
                },
              ]}
              disabled={!!this.props.to}
              onChange={this.handleInputChange}
            />
          </Col>
          <Col xs={12} md={6}>
            <FormSelect
              className="mb-20"
              title="Objet"
              name="subject"
              value={this.state.subject}
              ignore={true}
              options={
                this.props.messageType === "claim"
                  ? this.context.messageTypes
                  : this.context.messageTypes.filter((type) => type.value !== 1)
              }
              disabled={["contact", "claim", "invoice"].includes(
                this.props.messageType
              )}
              onChange={this.handleInputChange}
            />
          </Col>
        </Row>
        <FormTextarea
          className="mb-20"
          type="text"
          title="Votre message"
          name="message"
          value={this.state.message}
          placeholder={"Ecrire votre message ici..."}
          rows={5}
          onChange={this.handleInputChange}
        />
        {this.state.isInvoice ? (
          <Row>
            <Col xs={10}>
              <FormInput
                className="mb-20"
                type="number"
                title="Montant HT de la facture"
                name="invoice"
                value={this.state.invoice}
                onChange={this.handleInputChange}
                required
              />
            </Col>
            <Col
              xs={2}
              className="d-flex"
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                € HT
              </span>
            </Col>
          </Row>
        ) : null}

        <Row>
          <Col xs={12} className="mb-20">
            <p className="mt-0 mb-0 red">
              La dernière mise à jour du navigateur CHROME interdit l'import de
              documents qui se trouvent dans le répertoire système Windows.
            </p>
            <p className="mt-0 mb-0 red">
              Nous vous invitons à déplacer ces documents avant de procéder à
              l'import pour que l'envoi fonctionne.
            </p>
          </Col>
          <Col xs={12} md={6}>
            <DropzoneMultiple
              ref={(ref) => (this.refDropzoneMultiple = ref)}
              className="mb-20"
              extensions={[
                "jpeg",
                "jpg",
                "png",
                "pdf",
                "doc",
                "docx",
                "xls",
                "xlsx",
                "JPEG",
                "JPG",
                "PNG",
                "PDF",
                "DOC",
                "DOCX",
                "XLS",
                "XLSX",
              ]}
              maxFileSize={6}
              maxImageSize={1600}
              onDrop={(files) => this.setState({ files })}
            />
          </Col>
          <Col xs={12} md={6} className="d-flex mb-20">
            <Button
              className="mt-auto mb-auto"
              text="Faire des photos"
              onClick={() => this.setState({ isCamera: true })}
              outline
            >
              <FontAwesomeIcon icon={faCamera} />
            </Button>
          </Col>
        </Row>

        <Button
          className="mb-4"
          text="Envoyer"
          onClick={() => this.insertMessage()}
        />

        {this.state.isCamera ? (
          <RCamera
            isConfirm={false}
            onTakePicture={(base64) => {
              const mime = base64.split(";")[0].split(":")[1];

              this.refDropzoneMultiple.addFile({
                base64,
                extension: mime.split("/")[1],
                mime: mime,
                name: `ixperience-${new Date().getTime()}`,
              });
            }}
            onClose={() => this.setState({ isCamera: false })}
            isFullscreen={true}
            namePicture="ixperience"
            isTorch={true}
          />
        ) : null}
        <Loader show={this.state.isLoad} />
      </Modal>
    );
  }
}
