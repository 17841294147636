import React from "react";
import { CardColor } from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";

import AuthContext from "../../context/AuthContext";

export default class Warnings extends React.Component {
  static contextType = AuthContext;

  getColor = (value) => {
    if (value > 5) {
      return "bg-red";
    } else if (value > 0) {
      return "bg-orange";
    } else if (value === 0) {
      return "bg-green";
    } else {
      return "bg-gray";
    }
  };

  render() {
    return (
      <Row>
        {this.props.dataWarnings.map((element, idx) => (
          <Col key={idx} md={6} lg={4} className="mb-20">
            <CardColor
              count={element.count}
              title={
                this.context.source === "garage" ? element.title : element.id
              }
              className={this.getColor(element.count)}
              onClick={() =>
                this.props.handleInputChange(
                  { target: { name: "status", value: element.value } },
                  true
                )
              }
            />
          </Col>
        ))}
      </Row>
    );
  }
}
