const legal = 
  <>
    <h3>1. Présentation du site :‍</h3>
    Conformément aux dispositions des articles 6-III et 19 de la Loi
    n° 2004-575 du 21 juin 2004 pour la Confiance dans l'économie
    numérique, dite L.C.E.N., nous portons à la connaissance des
    utilisateurs et visiteurs du site : ixperience.fr les
    informations suivantes : Informations légales :
    <p>
    Statut du propriétaire : Société
    <br />
    Préfixe : <b>SAS</b>
    <br />
    Nom de la Société : <b>GROUPE LANG & ASSOCIES</b>
    <br />
    Adresse : <b>96 CHEMIN DE LARRAMET, 31170 TOURNEFEUILLE</b>
    <br />
    Tél : <b>05 61 57 44 44</b>
    <br />
    Au Capital de :{" "}
    <b>
      9 999 630,00 €<br />
    </b>
    SIRET : <b>888 087 061</b>
    <br />
    R.C.S. : <b>TOULOUSE</b>
    <br />
    Adresse de courrier électronique :{" "}
    <b>cedricvillar@groupelang.fr</b>
    </p>
    <p>
    Le Créateur du site est : <b>GROUPE LANG & ASSOCIES</b>
    <br />
    Le Responsable de la publication est : <b>CEDRIC VILLAR</b>
    <br />
    Contactez le responsable de la publication :
    <b>cedricvillar@groupelang.fr</b>
    <br />
    Le responsable de la publication est une personne physique
    </p>
    <p>
    Le Webmaster est : <b>GROUPE LANG & ASSOCIES</b>
    <br />
    Contactez le Webmaster : <b>cedricvillar@groupelang.fr</b>
    <br />
    L’hébergeur du site est : <b>OVH www.ovh.com 59100 Roubaix</b>
    </p>
    ‍<h3>2. Description des services fournis :‍</h3>
    <p>
    Le site ixperience.fr a pour objet de fournir une
    information concernant l’ensemble des activités de la société.
    <br />
    Le propriétaire du site s’efforce de fournir sur le site
    ixperience.fr des informations aussi précises que
    possible. Toutefois, il ne pourra être tenue responsable des
    omissions, des inexactitudes et des carences dans la mise à
    jour, qu’elles soient de son fait ou du fait des tiers
    partenaires qui lui fournissent ces informations.
    <br />
    Tous les informations proposées sur le site
    ixperience.fr sont données à titre indicatif, sont non
    exhaustives, et sont susceptibles d’évoluer. Elles sont
    données sous réserve de modifications ayant été apportées
    depuis leur mise en ligne.
    </p>
    <h3>3. Propriété intellectuelle et contrefaçons :‍</h3>
    <p>
    Le propriétaire du site est propriétaire des droits de
    propriété intellectuelle ou détient les droits d’usage sur
    tous les éléments accessibles sur le site, notamment les
    textes, images, graphismes, logo, icônes, sons, logiciels…
    <br />
    Toute reproduction, représentation, modification, publication,
    adaptation totale ou partielle des éléments du site, quel que
    soit le moyen ou le procédé utilisé, est interdite, sauf
    autorisation écrite préalable à l'email :
    contact[@]groupelang.fr
    <br />
    Toute exploitation non autorisée du site ou de l’un quelconque
    de ces éléments qu’il contient sera considérée comme
    constitutive d’une contrefaçon et poursuivie conformément aux
    dispositions des articles L.335-2 et suivants du Code de
    Propriété Intellectuelle.
    </p>
    <h3>4. Liens hypertextes et cookies :‍</h3>
    <p>
    Le site ixperience.fr contient un certain nombre de
    liens hypertextes vers d’autres sites (partenaires,
    informations …) mis en place avec l’autorisation de le
    propriétaire du site . Cependant, le propriétaire du site n’a
    pas la possibilité de vérifier le contenu des sites ainsi
    visités et décline donc toute responsabilité de ce fait quand
    aux risques éventuels de contenus illicites.
    </p>
    <p>
    L’utilisateur est informé que lors de ses visites sur le site
    ixperience.fr, un ou des cookies sont susceptible de
    s’installer automatiquement sur son ordinateur. Un cookie est
    un fichier de petite taille, qui ne permet pas
    l’identification de l’utilisateur, mais qui enregistre des
    informations relatives à la navigation d’un ordinateur sur un
    site. Les données ainsi obtenues visent à faciliter la
    navigation ultérieure sur le site, et ont également vocation à
    permettre diverses mesures de fréquentation.
    </p>
    <p>
    Le paramétrage du logiciel de navigation permet d’informer de
    la présence de cookie et éventuellement, de refuser de la
    manière décrite à l’adresse suivante : www.cnil.fr
    <br />
    Le refus d’installation d’un cookie peut entraîner
    l’impossibilité d’accéder à certains services. L’utilisateur
    peut toutefois configurer son ordinateur de la manière
    suivante, pour refuser l’installation des cookies :<br />
    Sous Internet Explorer : onglet outil / options internet.
    Cliquez sur Confidentialité et choisissez Bloquer tous les
    cookies. Validez sur Ok.
    <br />
    Sous Netscape : onglet édition / préférences. Cliquez sur
    Avancées et choisissez Désactiver les cookies. Validez sur Ok.
    </p>
  </>;

export { legal };