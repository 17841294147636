import React from "react";
import { Card, Status } from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";

import expertLoupe from "../../../images/expert-loupe.svg";

export default class DamageAnalysis extends React.Component {
  getStatus = (value) => {
    switch (value) {
      case 1:
        return (
          <Status
            text="Oui"
            className="m-auto mr-0 nowrap bg-special-color-2 special-color-1"
            style={{ height: "28px", fontSize: "14px" }}
          />
        );
      case 2:
        return (
          <Status
            text="Non"
            className="m-auto mr-0 nowrap bg-special-color-2 special-color-1"
            style={{ height: "28px", fontSize: "14px" }}
          />
        );
      case 3:
        return (
          <Status
            text="En attente de réponse"
            className="m-auto mr-0 nowrap bg-special-color-2 special-color-1"
            style={{ height: "28px", fontSize: "14px" }}
          />
        );
      default:
        return (
          <Status
            text="NC"
            className="m-auto mr-0 nowrap bg-special-color-2 special-color-1"
            style={{ height: "28px", fontSize: "14px" }}
          />
        );
    }
  };

  render() {
    const data = this.props.dataDamageAnalysis;

    return (
      <Card
        title="Analyse des dommages par l'expert"
        className="h-100"
        imgRight={!this.props.isSmall ? expertLoupe : null}
        customWidthImgRight="25%"
        forceRight
        classNameChildren="mt-auto mb-auto"
      >
        <Row>
          <Col xs={12} md={6} lg={4}>
            <span className="d-block mb-5">
              Le véhicule est économiquement réparable ?
            </span>
            {this.getStatus(
              data.economicallyRepairable === null
                ? 3
                : data.economicallyRepairable === 0
                ? 2
                : data.economicallyRepairable
            )}
            <span className="d-block mb-20"></span>
          </Col>
          <Col xs={12} md={6} lg={4}>
            <span className="d-block mb-5">
              Le véhicule est techniquement réparable ?
            </span>
            {this.getStatus(
              data.technicallyRepairable === null
                ? 3
                : data.technicallyRepairable === 0
                ? 2
                : data.technicallyRepairable
            )}
            <span className="d-block mb-20"></span>
          </Col>
          <Col xs={12} md={6} lg={4}>
            <span className="d-block mb-5">
              Le véhicule est soumis à la procédure VGE ?
            </span>
            {this.getStatus(
              data.VGE === null
                ? 3
                : data.VGE === undefined
                ? null
                : data.VGE === 1
                ? 1
                : 2
            )}
            <span className="d-block mb-20"></span>
          </Col>
          <Col xs={12} md={6} lg={4}>
            <span className="d-block mb-5">
              Des pièces de réemploi ont été chiffrées ?
            </span>
            {this.getStatus(
              data.PRE === null || !data.totalReportDutyFree
                ? 3
                : data.PRE === 1
                ? 1
                : 2
            )}
            <span className="d-block mb-20"></span>
          </Col>
          <Col xs={12} md={6} lg={4}>
            <span className="d-block mb-5">
              De la vétusté a été appliquée sur des pièces ?
            </span>
            {this.getStatus(
              data.dilapidatedDutyFree === null || !data.totalReportDutyFree
                ? 3
                : data.dilapidatedDutyFree > 0
                ? 1
                : 2
            )}
            <span className="d-block mb-20"></span>
          </Col>
        </Row>
      </Card>
    );
  }
}
