import React from "react";
import {
  Loader,
  FloatActionButton,
  FormInput,
  FormTextarea,
  Modal,
  Button,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion, faCheck } from "@fortawesome/free-solid-svg-icons";

import { sendHelpMessage } from "../../requests/contact";

import AuthContext from "../../context/AuthContext";

export default class ModalContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      emailContact: "",
      phoneContact: "",
      nameContact: "",
      licensePlateContact: "",
      subjectContact: "",
      messageContact: "",
    };
  }

  static contextType = AuthContext;

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  sendHelpMessage = () => {
    if (
      (this.state.emailContact || this.context.email) &&
      (this.state.phoneContact || this.context.phone) &&
      (this.state.nameContact || this.context.username) &&
      (this.state.licensePlateContact ||
        !window.document.location.href.includes("new-account/client")) &&
      this.state.subjectContact &&
      this.state.messageContact
    ) {
      this.setState({ isLoad: true }, () => {
        sendHelpMessage(
          {
            email: this.state.emailContact || this.context.email,
            phone: this.state.phoneContact || this.context.phone,
            name: this.state.nameContact || this.context.username,
            licensePlate: this.state.licensePlateContact,
            subject: this.state.subjectContact,
            message: this.state.messageContact,
            lastToken: localStorage.getItem("lastToken"),
          },
          (result) => {
            if (result.success) {
              this.setState(
                {
                  isLoad: false,
                  emailContact: "",
                  phoneContact: "",
                  nameContact: "",
                  messageContact: "",
                  subjectContact: "",
                  licensePlateContact: "",
                  isModalContact: false,
                },
                () => alert("Message envoyé")
              );
            } else {
              this.setState({ isLoad: false }, () =>
                alert("Une erreur est survenue")
              );
            }
          }
        );
      });
    } else {
      alert("Veuillez remplir tous les champs");
    }
  };

  render() {
    return (
      <>
        <div style={{ position: "relative", width: 0, height: 0, zIndex: 10 }}>
          <FloatActionButton
            icon={<FontAwesomeIcon icon={faQuestion} />}
            onClick={() => {
              if (this.props.onClick) {
                this.props.onClick();
              } else {
                this.setState({ isModalContact: true });
              }
            }}
          />
        </div>
        <Modal
          show={this.state.isModalContact}
          large
          onClose={() => this.setState({ isModalContact: false })}
          title="Demande d'assistance technique"
        >
          <div
            className="bg-red p-20 white mb-20"
            style={{ borderRadius: "20px" }}
          >
            <p className="m-0">
              Ce formulaire permet de contacter l'assistance technique du
              portail IxPérience. Les réponses ne seront pas lues par le cabinet
              d'expertise.
            </p>
          </div>
          <FormInput
            className="mb-20"
            title="E-mail"
            name="emailContact"
            value={this.state.emailContact || this.context.email}
            onChange={this.handleInputChange}
            required
          />
          <FormInput
            className="mb-20"
            title="N° de téléphone"
            name="phoneContact"
            value={this.state.phoneContact || this.context.phone}
            onChange={this.handleInputChange}
            required
          />
          <FormInput
            className="mb-20"
            title="Nom"
            name="nameContact"
            value={this.state.nameContact || this.context.username}
            onChange={this.handleInputChange}
            required
          />
          {window.document.location.href.includes("new-account/client") ? (
            <FormInput
              className="mb-20"
              title="Immatriculation"
              name="licensePlateContact"
              value={this.state.licensePlateContact}
              onChange={this.handleInputChange}
              required
            />
          ) : null}
          <FormInput
            className="mb-20"
            title="Sujet"
            name="subjectContact"
            value={this.state.subjectContact}
            onChange={this.handleInputChange}
            required
          />
          <FormTextarea
            className="mb-20"
            type="text"
            title="Votre message"
            name="messageContact"
            value={this.state.messageContact}
            placeholder={"Ecrire votre message ici..."}
            rows={5}
            onChange={this.handleInputChange}
            required
          />
          <div className="d-flex">
            <Button
              className="w-auto ml-auto"
              text="Envoyer"
              onClick={this.sendHelpMessage}
            >
              <FontAwesomeIcon icon={faCheck} />
            </Button>
          </div>
        </Modal>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
