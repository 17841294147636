import React from "react";
import {
  Card,
  FormSelect,
  FormInput,
  FormCheckbox,
} from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import Button from "../other/Button";

import AuthContext from "../../context/AuthContext";

export default class ShowFilters extends React.Component {
  static contextType = AuthContext;

  getDefaultFormInput = (name, title, placeholder = "", isChange = false) => {
    return (
      <Col xs={12} md={3} lg={2} className="mb-10">
        <FormInput
          type="text"
          title={title}
          name={name}
          value={this.props[name]}
          placeholder={placeholder}
          onChange={this.props.handleInputChange}
          onKeyDown={(e) => {
            if (isChange && e.keyCode === 13) {
              this.props.handleValidateChange();
            }
          }}
          onBlur={() => {
            if (isChange) {
              this.props.handleValidateChange();
            }
          }}
        />
      </Col>
    );
  };

  getDefaultFormSelect = (name, title, options, isChange = false) => {
    return (
      <Col xs={12} md={3} lg={2} className="mb-10">
        <FormSelect
          title={title}
          name={name}
          value={this.props[name]}
          ignore={true}
          options={
            options
              ? options.map((option) => ({
                  value: option.value ? option.value : "",
                  text: option.text,
                  disabled: option.disabled,
                }))
              : []
          }
          onChange={(e) => this.props.handleInputChange(e, isChange)}
        />
      </Col>
    );
  };

  render() {
    return (
      <Card
        title={`Trouver un dossier - ${this.props.total} dossier(s)`}
        className="mb-10 mb-20-xl"
      >
        <Row>
          {this.getDefaultFormInput(
            "folder",
            "Numéro de dossier",
            "N° dossier",
            true
          )}
          {this.getDefaultFormInput(
            "sinister",
            "Numéro de sinistre",
            "N° sinistre",
            true
          )}
          {["company", "principal", "garage", "agency"].includes(
            this.context.source
          )
            ? this.getDefaultFormInput(
                "client",
                "Assuré",
                "Nom de l'assuré",
                true
              )
            : null}
          {this.getDefaultFormSelect(
            "year",
            "Année du dossier",
            this.props.yearOptions,
            true
          )}
          {this.getDefaultFormSelect(
            "status",
            "État du dossier",
            this.props.statusOptions,
            true
          )}
          {["agency", "company", "principal", "garage"].includes(
            this.context.source
          )
            ? this.getDefaultFormSelect(
                "natureExpertise",
                "Nature d'expertise",
                this.props.natureExpertiseOptions,
                true
              )
            : null}
          {this.getDefaultFormInput(
            "brand",
            "Marque du véhicule",
            "Marque véhicule"
          )}
          {this.getDefaultFormInput(
            "model",
            "Modèle du véhicule",
            "Modèle véhicule"
          )}
          {this.getDefaultFormInput(
            "registration",
            "Immatriculation",
            "N° d'immatriculation"
          )}
          {["company", "principal", "client", "agency"].includes(
            this.context.source
          )
            ? this.getDefaultFormInput(
                "repairman",
                "Réparateur",
                "Nom du réparateur"
              )
            : null}
          {["garage", "company", "client", "agency"].includes(
            this.context.source
          )
            ? this.getDefaultFormInput("principal", "Mandant", "Nom du mandant")
            : null}
          {["garage", "agency", "client"].includes(this.context.source)
            ? this.getDefaultFormSelect(
                "company",
                "Compagnie",
                this.props.companyOptions
              )
            : null}
          {["agency"].includes(this.context.source)
            ? this.getDefaultFormSelect(
                "region",
                "Région",
                this.props.regionOptions
              )
            : null}
          {["company"].includes(this.context.source) &&
          this.context.username === "ALLIANZ"
            ? this.getDefaultFormSelect(
                "regionAllianz",
                "Région",
                this.props.regionAllianzOptions.map((region, idx) => ({
                  value: idx,
                  text: region.text,
                }))
              )
            : null}
          {this.context.username !== "ALLIANZ"
            ? this.getDefaultFormSelect(
                "agency",
                "Agence",
                this.props.agencyOptions
              )
            : null}
          {["company"].includes(this.context.source) &&
          this.context.username === "ALLIANZ"
            ? this.getDefaultFormSelect(
                "agencyAllianz",
                "Agence",
                this.props.regionAllianz !== ""
                  ? this.props.regionAllianzOptions[this.props.regionAllianz]
                      .agencies
                  : null
              )
            : null}
          <Col xs={12} md={3} lg={2} className="mb-10 d-flex">
            <Button
              text="Rechercher"
              className="mt-auto"
              onClick={() => this.props.handleValidateChange()}
            >
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          </Col>
          <Col xs={12}>
            <FormCheckbox
              name="isClosingYear"
              checked={this.props.isClosingYear}
              onChange={this.props.handleCheckboxChange}
              text="Inclure les dossiers clôturés depuis plus d'un an"
            />
          </Col>
        </Row>
      </Card>
    );
  }
}
