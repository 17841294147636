import React from "react";
import { Loader } from "ui-kit-ck-consultant";

import {
  getElectronicSignature,
  updateElectronicSignature,
} from "../../requests/other";

import logoLang from "../../images/logo-lang.png";

import AuthContext from "../../context/AuthContext";

export default class ElectronicSignature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      iframeUrl: null,
      otherOK: false,
    };
  }

  componentDidMount() {
    this.getElectronicSignature();
    window.addEventListener("message", this.eventMessage);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.eventMessage);
  }

  eventMessage = (e) => {
    if (e.data.event === "success") {
      this.updateElectronicSignature();
    }
  };

  getElectronicSignature = () => {
    this.setState({ isLoad: true }, () => {
      const token = this.props.match.params.token;
      const id = this.props.match.params.id;
      getElectronicSignature({ token, id }, (result) => {
        this.setState(
          {
            isLoad: false,
          },
          () => {
            if (result.success && result.otherOK) {
              this.setState({
                otherOK: result.otherOK,
              });
            } else if (result.success && result.iframeUrl) {
              this.setState({
                iframeUrl: result.iframeUrl,
              });
            } else {
              alert("Une erreur est survenue");
            }
          }
        );
      });
    });
  };

  updateElectronicSignature = () => {
    this.setState({ isLoad: true }, () => {
      const token = this.props.match.params.token;
      const id = this.props.match.params.id;
      updateElectronicSignature({ token, id }, (result) => {
        this.setState({ isLoad: false }, () => {
          if (!result.success) {
            alert("Une erreur est survenue");
          }
        });
      });
    });
  };

  render() {
    return (
      <AuthContext.Provider
        value={{
          principal: {},
          sinister: {},
        }}
      >
        <>
          {this.state.iframeUrl ? (
            <iframe
              title="Signature électronique"
              frameBorder={0}
              style={{ width: "100%", height: "100%" }}
              src={`${this.state.iframeUrl}&disable_domain_validation=true`}
            />
          ) : (
            <div style={{ width: "100%", height: "100%", display: "flex" }}>
              <div
                style={{
                  margin: "auto",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h1>Proposition de cession</h1>
                <img
                  className="mt-10 mb-10"
                  style={{ height: "120px", margin: "auto", maxWidth: "100%" }}
                  src={logoLang}
                  alt="lang"
                />
                <p>La signature a été effectuée</p>
              </div>
            </div>
          )}
          <Loader show={this.state.isLoad} />
        </>
      </AuthContext.Provider>
    );
  }
}
