import React from "react";
import { Row, Col } from "react-flexbox-grid";

import General from "./General";
import TimeLine from "./TimeLine";
import ExpertiseSolution from "./ExpertiseSolution";
import Picture from "./Picture";
import ElectronicSignature from "../electronic-signature/ElectronicSignature";
import Parts from "./parts/Parts";
import Agency from "./Agency";
import PRE from "./PRE";
// import Carbon from "./Carbon";
import DamageAnalysis from "./DamageAnalysis";
import Message from "./Message";

import AuthContext from "../../../context/AuthContext";
export default class Monitoring extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.expertiseSolutionRef = null;
  }

  static contextType = AuthContext;

  render() {
    return (
      <Row>
        <Col xs={12} md={3} className="mb-20">
          <General sinister={this.props.sinister} />
        </Col>
        <Col xs={12} md={9} className="mb-20">
          <TimeLine
            dataTimeLine={this.props.dataTimeLine}
            isSmall={this.props.isSmall}
          />
        </Col>
        {["client", "agency"].includes(this.context.source) &&
        !this.context.isLimit ? (
          <Col xs={12} className="col_full_height">
            {
              // margin bottom is in Card
            }
            <ElectronicSignature
              isSmall={this.props.isSmall}
              getMessages={this.props.getMessages}
              getFiles={this.props.getFiles}
              getFolder={this.props.getFolder}
              dataTimeLine={this.props.dataTimeLine}
              isLoadDocument={this.props.isLoadDocument}
            />
          </Col>
        ) : null}
        {!this.context.isLimit ? (
          <Col xs={12} className="mb-20 col_full_height">
            <ExpertiseSolution
              ref={(ref) => {
                this.expertiseSolutionRef = ref;
              }}
              dataPRE={this.props.dataPRE}
              dataTimeLine={this.props.dataTimeLine}
              dataExpertiseForm={this.props.dataExpertiseForm}
              dataExpertiseFormPage={this.props.dataExpertiseFormPage}
              dataExpertiseCatnat={this.props.dataExpertiseCatnat}
              dataExpertiseCatnatPage={this.props.dataExpertiseCatnatPage}
              updateExpertiseSolution={this.props.updateExpertiseSolution}
              updateCatnat={this.props.updateCatnat}
              isSmall={this.props.isSmall}
              onCloseAlert={() => this.props.onCloseAlert()}
            />
          </Col>
        ) : null}
        {this.props.dataPictures.length ? (
          <Col xs={12} className="mb-20 col_full_height">
            <Picture dataPictures={this.props.dataPictures} />
          </Col>
        ) : null}
        {["garage", "agency"].includes(this.context.source) &&
        this.context.sinister &&
        this.context.sinister.natureExpertiseCode &&
        !this.context.sinister.natureExpertiseCode.includes("I", "3") ? (
          <Col xs={12} className="mb-20 col_full_height">
            <Parts />
          </Col>
        ) : null}
        <Col xs={12} md={8} className="mb-20 col_full_height">
          <PRE
            dataPRE={this.props.dataPRE}
            updatePRE={this.props.updatePRE}
            isSmall={this.props.isSmall}
          />
        </Col>
        {/* {this.context.projectE ? (
          <Col xs={12} md={4} className="mb-20 col_full_height">
            <Carbon />
          </Col>
        ) : null} */}
        <Col xs={12} md={4} className="mb-20 col_full_height">
          <Agency />
        </Col>
        <Col
          xs={12}
          md={this.context.projectE ? 8 : 12}
          // md={12}
          className="mb-20 col_full_height"
        >
          <DamageAnalysis
            dataDamageAnalysis={this.props.dataDamageAnalysis}
            isSmall={this.props.isSmall}
          />
        </Col>
        <Col xs={12} className="mb-20 col_full_height">
          <Message
            isAlert={this.props.isAlert}
            match={this.props.match}
            getMessages={this.props.getMessages}
            getFiles={this.props.getFiles}
            dataMessages={this.props.dataMessages}
            readMessage={this.props.readMessage}
            readDocument={this.props.readDocument}
            isSmall={this.props.isSmall}
            isLoadMessage={this.props.isLoadMessage}
            isLoadDocument={this.props.isLoadDocument}
            openModalCatnat={() => this.expertiseSolutionRef.openModalCatnat()}
            dataExpertiseCatnatPage={this.props.dataExpertiseCatnatPage}
            alertRef={this.props.alertRef}
          />
        </Col>
      </Row>
    );
  }
}
