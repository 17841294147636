import React, { Fragment } from "react";
import {
  Table,
  Td,
  Tr,
  Tbody,
  Th,
  Thead,
  Card,
  Loader,
  FormInput,
  Modal,
  ButtonIcon,
} from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPen,
  faCheck,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

import ContactLink from "../../folder/ContactLink";
import Button from "../../other/Button";

import data from "./data.js";

import { getIndex } from "../../../utils/general";

import { getAlias, resetToken, updateData } from "../../../requests/folder";

import AuthContext from "../../../context/AuthContext";

export default class Administrative extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      isModalResetPassword: false,
      typeModalResetPassword: null,
      aliasAccounts: [],
    };
  }

  static contextType = AuthContext;

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  getElement = (cardId, line) => {
    const value = this.getValue(cardId, line.id);

    return (
      <p className="mt-5 mb-5">
        {line.name} :{" "}
        {value
          ? this.getTypeValue(
              value,
              line.id,
              cardId,
              this.context && this.context[cardId] ? this.context[cardId] : {}
            )
          : "N.C."}
      </p>
    );
  };

  getTypeValue = (value, id, cardId, data) => {
    switch (id) {
      case "email":
        if (
          cardId === "principal" ||
          ["agency"].includes(this.context.source)
        ) {
          return (
            <a
              style={{ display: "inline-block", textAlign: "left" }}
              href={`mailto:${value}`}
            >
              {value}
            </a>
          );
        } else {
          return (
            <ContactLink
              folderId={this.context.id}
              toName={data.name}
              toId={data.id}
              toSource={cardId === "expert" ? "agency" : cardId}
            />
          );
        }
      case "phone":
        return (
          <a
            style={{ display: "inline-block", textAlign: "left" }}
            href={`tel:${value}`}
          >
            {value}
          </a>
        );
      case "cellphone":
        return (
          <a
            style={{ display: "inline-block", textAlign: "left" }}
            href={`tel:${value}`}
          >
            {value}
          </a>
        );
      default:
        return <span style={{ fontWeight: "bold" }}>{value}</span>;
    }
  };

  handleResetTokenClick(type, id = null) {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        resetToken({ folderId: this.context.id, type, id }, (result) => {
          if (result.success === true) {
            this.setState({ isLoad: false }, () => {
              alert(
                "La demande de réinitialisation du mot de passe a bien été envoyée par mail."
              );
            });
          } else {
            this.setState({ isLoad: false }, () => {
              alert("Une erreur est survenue");
            });
          }
        });
      }
    );
  }

  updateData = () => {
    let tmpData = [];

    Object.keys(this.state).forEach((key) => {
      const element = this.state[key];

      const length = key.split("-").length;

      if (length === 3) {
        const idx1 = getIndex(data, key.split("-")[1]);
        if (idx1 >= 0) {
          let dataCol = [];

          if (data[idx1].data.length > 1) {
            dataCol = [...data[idx1].data[0], ...data[idx1].data[1]];
          } else {
            dataCol = [...data[idx1].data[0]];
          }

          const idx2 = getIndex(dataCol, key.split("-")[2]);

          if (idx2 >= 0) {
            const value = this.getValue(data[idx1].id, dataCol[idx2].id, true);

            if (value !== element) {
              tmpData.push({
                id: `${data[idx1].id}-${dataCol[idx2].id}`,
                type: data[idx1].name,
                name: dataCol[idx2].name,
                value: element,
              });
            }
          }
        }
      }
    });

    if (tmpData.length) {
      this.setState({ isLoad: true }, () => {
        updateData(
          {
            folderId: this.context.id,
            data: tmpData,
          },
          (result) => {
            if (result.success) {
              this.setState({ isLoad: false });
            } else {
              this.setState({ isLoad: false }, () => {
                alert("Une erreur est survenue");
              });
            }
          }
        );
      });
    }
  };

  getActionCard = (card, cardIndex) => {
    let result = {
      action: null,
      onClick: () => {},
    };

    if (
      ["client", "garage", "principal"].includes(card.id) &&
      this.context.source === "agency"
    ) {
      result.action = <FontAwesomeIcon icon={faEye} />;
      result.onClick = () => {
        this.setState(
          {
            isModalResetPassword: true,
            typeModalResetPassword: card.id,
          },
          () => {
            if (card.id !== "client") {
              this.getAlias(`${card.id}-alias`);
            }
          }
        );
      };
    } else if (
      (["client"].includes(card.id) && this.context.source === "client") ||
      (["garage"].includes(card.id) && this.context.source === "garage") ||
      (["principal"].includes(card.id) && this.context.source === "principal")
    ) {
      result.action = this.state[`isEdit${cardIndex}`] ? (
        <FontAwesomeIcon icon={faCheck} />
      ) : (
        <FontAwesomeIcon icon={faPen} />
      );
      result.onClick = () => {
        this.setState(
          {
            [`isEdit${cardIndex}`]: !this.state[`isEdit${cardIndex}`],
          },
          () => {
            if (!this.state[`isEdit${cardIndex}`]) {
              this.updateData();
            }
          }
        );
      };
    }

    return result;
  };

  getFormInput = (card, line) => {
    const value = this.getValue(card.id, line.id);

    return (
      <FormInput
        title={line.name}
        className="mb-5"
        value={this.state[`input-${card.id}-${line.id}`] || value}
        name={`input-${card.id}-${line.id}`}
        onChange={this.handleInputChange}
      />
    );
  };

  getValue = (cardId, lineId, isUpdate = false) => {
    let value = "";

    if (this.state[`input-${cardId}-${lineId}`] && !isUpdate) {
      value = this.state[`input-${cardId}-${lineId}`];
    } else if (
      this.context &&
      this.context.dataAdministrative &&
      getIndex(this.context.dataAdministrative, `${cardId}-${lineId}`) >= 0
    ) {
      value =
        this.context.dataAdministrative[
          getIndex(this.context.dataAdministrative, `${cardId}-${lineId}`)
        ].value;
    } else if (
      this.context &&
      this.context[cardId] &&
      this.context[cardId][lineId]
    ) {
      value = this.context[cardId][lineId];
    }

    return value;
  };

  getAlias = (type) => {
    this.setState({ isLoad: true }, () => {
      getAlias({ folderId: this.context.id, type: type }, (result) => {
        if (result.success) {
          this.setState({
            isLoad: false,
            aliasAccounts: result.data,
          });
        } else {
          this.setState({
            isLoad: false,
            aliasAccounts: [],
          });
        }
      });
    });
  };

  render() {
    return (
      <>
        <Row>
          {data.map((card, cardIndex) => (
            <Col
              key={cardIndex}
              xs={card.xs}
              lg={card.lg}
              className="mb-20 col_full_height"
            >
              <Card
                title={card.name}
                action={this.getActionCard(card, cardIndex).action}
                onClick={this.getActionCard(card, cardIndex).onClick}
                className="h-100"
                classNameChildren="mb-auto"
              >
                <Row>
                  {card.data.map((col, colIndex) => (
                    <Col key={colIndex} xs={12} md={12 / card.data.length}>
                      {col.map((line, lineIndex) => (
                        <Fragment key={lineIndex}>
                          {!this.state[`isEdit${cardIndex}`]
                            ? this.getElement(card.id, line)
                            : this.getFormInput(card, line)}
                        </Fragment>
                      ))}
                    </Col>
                  ))}
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
        <Modal
          title="Rénitialisation du mot de passe"
          show={this.state.isModalResetPassword}
          onClose={() =>
            this.setState({
              isModalResetPassword: false,
              typeModalResetPassword: null,
              aliasAccounts: [],
            })
          }
        >
          {this.state.typeModalResetPassword !== "client" ? (
            <Table
              empty={this.state.aliasAccounts.length ? "" : "Aucun sous-compte"}
            >
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>E-mail</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {this.state.aliasAccounts.map((element, idx) => (
                  <Tr key={idx}>
                    <Td className="nowrap">{idx + 1}</Td>
                    <Td className="w-100">{element.email}</Td>
                    <Td className="nowrap">
                      <ButtonIcon
                        small
                        className="blue"
                        onClick={() =>
                          this.handleResetTokenClick(
                            `${this.state.typeModalResetPassword}-alias`,
                            element.id
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faEnvelope} />
                      </ButtonIcon>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          ) : null}
          {this.state.typeModalResetPassword !== "principal" ? (
            <Button
              className="mt-10"
              text="Rénitialiser le mot de passe"
              onClick={() =>
                this.handleResetTokenClick(this.state.typeModalResetPassword)
              }
            >
              <FontAwesomeIcon icon={faEnvelope} />
            </Button>
          ) : null}
        </Modal>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
