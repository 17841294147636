import React from "react";
import { Card } from "ui-kit-ck-consultant";

import Status from "../Status";

import AuthContext from "../../../context/AuthContext";

export default class General extends React.Component {
  static contextType = AuthContext;

  render() {
    let statusNav =
      this.props.sinister.statusNav || this.context.sinister.statusNav;

    return (
      <Card
        title={`Dossier n°${this.context.sinister.reference}`}
        className="h-100"
      >
        <p className="m-0">Sinistre n°{this.context.sinister.sinister}</p>
        <p className="m-0">
          Date du sinistre : {this.context.sinister.dateAccident}
        </p>
        <Status
          status={statusNav}
          date={this.context.sinister.date}
          className={`w-100 mt-5 bg-${
            this.props.sinister.statusColor || this.context.sinister.statusColor
          }-light ${
            this.props.sinister.statusColor || this.context.sinister.statusColor
          } text-left`}
          dateAppointment={this.context.sinister.dateAppointment}
        />
      </Card>
    );
  }
}
