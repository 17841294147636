import React from "react";
import { Button as ButtonUiKit } from "ui-kit-ck-consultant";

export default class Button extends React.Component {
  render() {
    return (
      <ButtonUiKit
        style={{
          backgroundColor: "#041F43",
          borderColor: "#041F43",
        }}
        {...this.props}
      >
        {this.props.children}
      </ButtonUiKit>
    );
  }
}
