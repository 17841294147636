import React from "react";
import { Card, ListingHorizontal, ListingVertical } from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes, faCog } from "@fortawesome/free-solid-svg-icons";

import AuthContext from "../../../context/AuthContext";

export default class TimeLine extends React.Component {
  static contextType = AuthContext;

  render() {
    let data = [];
    let oldId = null;

    this.props.dataTimeLine.forEach((element, idx) => {
      if (oldId === 62 && element.situationCodeId === 10) {
        return null;
      } else if (oldId !== element.situationCodeId) {
        oldId = element.situationCodeId;

        data.push({
          ...element,
          subtitle: element.statusTimeline.replace(
            "<DATERDV>",
            this.context.sinister.dateAppointment
          ),
          color:
            this.props.dataTimeLine.length - 1 === idx
              ? "var(--orange)"
              : "var(--special-color-1)",
          backgroundColor:
            this.props.dataTimeLine.length - 1 === idx
              ? "var(--orange-light)"
              : "var(--special-color-2)",
          icon: <FontAwesomeIcon icon={faCheck} />,
        });
      }
    });

    if (
      data.length &&
      data[data.length - 1].subtitle === "Annulation du dossier"
    ) {
      data[data.length - 1].icon = <FontAwesomeIcon icon={faTimes} />;
      data[data.length - 1].color = "var(--red)";
      data[data.length - 1].backgroundColor = "var(--red-light)";
    } else if (data.length) {
      data[data.length - 1].icon = <FontAwesomeIcon icon={faCog} />;
    }

    return (
      <Card
        title="Suivi du dossier"
        className="h-100"
        classNameChildren="mt-auto mb-auto"
      >
        {this.props.dataTimeLine.length > 0 ? (
          <>
            {this.props.isSmall ? (
              <ListingVertical data={data} />
            ) : (
              <ListingHorizontal data={data} />
            )}
          </>
        ) : (
          <div
            style={{
              color: "gray",
              border: "solid 1px gray",
              textAlign: "center",
              padding: "1.5rem",
              borderRadius: "6px",
              height: "100%",
            }}
          >
            <span>Aucune étape enregistrée</span>
          </div>
        )}
      </Card>
    );
  }
}
