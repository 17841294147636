import {
  faFileAlt,
  faFileExcel,
  faFileWord,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";

const getIconFile = (extension) => {
  extension = extension ? extension : "";

  if (extension.includes("pdf")) {
    return [faFilePdf, "red"];
  } else if (extension.includes("doc")) {
    return [faFileWord, "blue"];
  } else if (extension.includes("docx")) {
    return [faFileWord, "blue"];
  } else if (extension.includes("xls")) {
    return [faFileExcel, "green"];
  } else if (extension.includes("xlsx")) {
    return [faFileExcel, "green"];
  } else {
    return [faFileAlt, "orange"];
  }
};

const getIndex = (data, value, key = "id") => {
  var index = data.findIndex((item) => {
    return item[key] === value;
  });

  return index;
};

const getFileUrl = (folderId, fileToken, fileSource) => {
  return `/api/folder/file/get-file/${folderId}/${fileToken}`;
};

export { getIconFile, getIndex, getFileUrl };
