import React from "react";

export default class GeneralLayout extends React.Component {
  render() {
    return (
      <div className="Auth">
        <div className="Auth-container">
          {this.props.children}
        </div>
      </div>
    );
  }
} 