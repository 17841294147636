import React from "react";
import { Card } from "ui-kit-ck-consultant";

import ContactLink from "../../folder/ContactLink";

import AuthContext from "../../../context/AuthContext";

export default class Agency extends React.Component {
  static contextType = AuthContext;

  render() {
    return (
      <Card
        title="Agence"
        className="h-100"
        classNameChildren="mt-auto mb-auto"
      >
        <p className="my-10">
          Interlocuteur technique : <b>{this.context.expert.name}</b>
        </p>
        <p className="my-10">
          Agence : <b>{this.context.agency.name}</b>
        </p>
        <p className="my-10">
          Téléphone :{" "}
          <b>
            <a
              href={`tel:${this.context.agency.phone}`}
              style={{
                display: "inline-block",
                textAlign: "left",
              }}
            >
              {this.context.agency.phone}
            </a>
          </b>
        </p>
        <p className="my-10">
          E-mail :{" "}
          {document.location.href.includes("/axa/") ? (
            <a
              href={`mailto:${this.context.agency.email}`}
              style={{
                display: "inline-block",
                textAlign: "left",
              }}
            >
              {this.context.agency.email}
            </a>
          ) : (
            <ContactLink
              folderId={this.context.id}
              toName={this.context.agency.name}
              toId={this.context.agency.id}
              toSource={"agency"}
            />
          )}
        </p>
        <p className="my-10">
          Adresse : <b>{this.context.agency.address1}</b>
        </p>
        <p className="my-10">
          Code postal : <b>{this.context.agency.zipCode}</b>
        </p>
        <p className="my-10">
          Ville : <b>{this.context.agency.city}</b>
        </p>
      </Card>
    );
  }
}
