import React from "react";
import moment from "moment";
import { Modal } from "ui-kit-ck-consultant";

import AuthContext from "../../../../context/AuthContext";

export default class ModalAnswerExpertiseSolution extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static contextType = AuthContext;

  getCorrectInputAnswer = (name, text) => {
    const data = this.props.dataExpertiseForm;

    if (data[`${name}_radio`] === 0 || data[`${name}_radio`] === 1) {
      return (
        <>
          <p className="m-0 mt-10">
            {text} :{" "}
            <b style={data[`${name}_radio`] === 0 ? { color: "red" } : {}}>
              {data[`${name}_radio`] === 0
                ? `Non ${data[`${name}_text`]}`
                : "Oui"}
            </b>
          </p>
        </>
      );
    } else {
      return null;
    }
  };

  getRadioAnswer = (name, text, isText = false) => {
    const data = this.props.dataExpertiseForm;

    if (
      (isText === false && data[`${name}_radio`] > 0) ||
      data[`${name}_radio`] === 0
    ) {
      return (
        <>
          <p className="m-0 mt-10">
            {text} : <b>{data[`${name}_radio`] === 0 ? "Non" : "Oui"}</b>
          </p>
        </>
      );
    } else if (isText && data[`${name}_radio`]) {
      return (
        <>
          <p className="m-0 mt-10">
            {text} : <b>{data[`${name}_radio`].replace("?", "")}</b>
          </p>
        </>
      );
    } else {
      return null;
    }
  };

  getData = () => {
    return (
      <>
        {this.getCorrectInputAnswer(
          "cellphone",
          <>
            Votre numéro de téléphone portable est bien le{" "}
            <b>{this.context.client.cellphone}</b> ?
          </>
        )}
        {this.getCorrectInputAnswer(
          "email",
          <>
            Votre adresse e-mail est bien <b>{this.context.client.email}</b> ?
          </>
        )}
        {this.getCorrectInputAnswer(
          "licensePlate",
          <>
            L'immatriculation de votre véhicule est bien{" "}
            <b>{this.context.vehicle.licensePlate}</b> ?
          </>
        )}
        {this.getCorrectInputAnswer(
          "position",
          this.context.sinister.natureEventType !== 14 ? (
            <>
              Les dommages se situent bien sur{" "}
              <b>{this.context.sinister.position}</b> ?
            </>
          ) : (
            <>Les dommages correspondent bien à des impacts de grêle ?</>
          )
        )}
        {this.getCorrectInputAnswer(
          "natureEvent",
          <>
            La nature du sinistre que vous avez subi est bien{" "}
            <b>{this.context.sinister.natureEvent}</b> ?
          </>
        )}
        {this.getRadioAnswer("rolling", "Votre véhicule est-il roulant ?")}
        {this.getRadioAnswer(
          "straight",
          "Quand vous roulez en ligne droite, votre volant est-il de travers ?"
        )}
        {this.getRadioAnswer(
          "majorDamage",
          "Les dommages sont-ils importants ?"
        )}
        {this.getRadioAnswer(
          "highCosts",
          "Pensez-vous que le montant des réparations risque de dépasser la valeur de votre véhicule ?"
        )}
        {this.getRadioAnswer(
          "mechanical",
          "Pensez-vous que des pièces mécaniques ont été touchées ?"
        )}
        {this.props.dataExpertiseFormPage.includes(4) ? (
          <>
            {this.getRadioAnswer(
              "repair",
              "Souhaitez-vous faire réparer votre véhicule ?",
              true
            )}
            {this.getRadioAnswer(
              "blockedGarage",
              "Est-ce que votre véhicule est immobilisé au garage ?"
            )}
          </>
        ) : null}
        {this.props.dataExpertiseFormPage.includes(5) ? (
          <>
            {this.getRadioAnswer(
              "smartphone",
              "Possédez-vous un smartphone connecté à internet ?"
            )}
          </>
        ) : null}
        {this.props.dataExpertiseFormPage.includes(6) ? (
          <>
            {this.getRadioAnswer(
              "picture",
              "Souhaitez-vous faire vous-même les photos des dommages de votre véhicule ?"
            )}
          </>
        ) : null}
        {this.props.dataExpertiseFormPage.includes(7) ? (
          <>
            {this.getRadioAnswer(
              "videoConference",
              "Souhaitez-vous faire que notre expert vous assiste en visioexpertise ?"
            )}
          </>
        ) : null}
        {this.props.dataExpertiseFormPage.includes(8) ? (
          <>
            {this.getRadioAnswer(
              "pictureRepairman",
              "Souhaitez-vous que votre garage fasse les photos des dommages de votre véhicule ?"
            )}
          </>
        ) : null}
        {this.props.dataExpertiseFormPage.includes(9) ? (
          <>
            {this.getRadioAnswer(
              "expertiseLocation",
              "Souhaitez-vous que l'expertise soit réalisée",
              true
            )}
            {this.props.dataExpertiseForm.dateCalendarAppointment ? (
              <p className="m-0 mt-10">
                Date expertise :{" "}
                <b>
                  {moment(
                    this.props.dataExpertiseForm.dateCalendarAppointment
                  ).format("DD/MM/YYYY")}
                </b>
              </p>
            ) : null}
            {this.props.dataExpertiseForm.timeCalendarAppointment ? (
              <p className="m-0 mt-10">
                Heure expertise :{" "}
                <b>{this.props.dataExpertiseForm.timeCalendarAppointment}</b>
              </p>
            ) : null}
            {this.props.dataExpertiseForm.inputExpertiseLocation ? (
              <p className="m-0 mt-10">
                Lieu expertise :{" "}
                <b>{this.props.dataExpertiseForm.inputExpertiseLocation}</b>
              </p>
            ) : null}
          </>
        ) : null}
        {this.props.dataExpertiseFormPage.includes(10) ? <> </> : null}
        {this.props.dataExpertiseFormPage.includes(11) ? (
          <>
            {this.props.dataExpertiseForm.dateCalendarVisio ? (
              <p className="m-0 mt-10">
                Date visioconférence :{" "}
                <b>
                  {moment(
                    this.props.dataExpertiseForm.dateCalendarVisio
                  ).format("DD/MM/YYYY")}
                </b>
              </p>
            ) : null}
            {this.props.dataExpertiseForm.timeCalendarVisio ? (
              <p className="m-0 mt-10">
                Heure visioconférence :{" "}
                <b>{this.props.dataExpertiseForm.timeCalendarVisio}</b>
              </p>
            ) : null}
          </>
        ) : null}
        {this.props.dataExpertiseFormPage.includes(12) ? (
          <>
            {this.props.dataExpertiseForm.inputGarageAddress ? (
              <p>
                Adresse réparateur :{" "}
                {this.props.dataExpertiseForm.inputGarageAddress}
              </p>
            ) : null}
            {this.props.dataExpertiseForm.inputGarageZipCode ? (
              <p>
                Code postal réparateur :{" "}
                {this.props.dataExpertiseForm.inputGarageZipCode}
              </p>
            ) : null}
            {this.props.dataExpertiseForm.inputGarageCity ? (
              <p>
                Ville réparateur :{" "}
                {this.props.dataExpertiseForm.inputGarageCity}
              </p>
            ) : null}
            {this.props.dataExpertiseForm.inputGaragePhone ? (
              <p>
                Téléphone réparateur :{" "}
                {this.props.dataExpertiseForm.inputGaragePhone}
              </p>
            ) : null}
          </>
        ) : null}
        {this.getRadioAnswer(
          "PRE",
          "Etes-vous d’accord pour l’utilisation de pièces de réemploi ?"
        )}
        <p>{this.props.text}</p>
      </>
    );
  };

  render() {
    return (
      <Modal
        title="Réponses"
        className="m-auto"
        show={this.props.show}
        onClose={this.props.onClose}
        large
      >
        {this.getData()}
      </Modal>
    );
  }
}
