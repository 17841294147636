import React from "react";
import { Button, Card, Pagination } from "ui-kit-ck-consultant";
import { Col, Row } from "react-flexbox-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import { getParts } from "../../../../requests/folder";

import AuthContext from "../../../../context/AuthContext";

export default class Parts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parts: [],
      isLoad: false,
      activePage: 1,
      pageLength: 4,
    };
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.getParts();
  }

  getParts = () => {
    this.setState({ isLoad: true }, () => {
      getParts(
        {
          folderReference: this.context.sinister.reference,
        },
        (result) => {
          this.setState({ isLoad: false, parts: result.parts || [] });
        }
      );
    });
  };

  render() {
    return (
      <Card
        title="PIEC disponibles"
        className="h-100"
        classNameChildren="mt-auto mb-auto"
        isLoad={this.state.isLoad}
      >
        <Row>
          {this.state.parts
            .slice(
              (this.state.activePage - 1) * this.state.pageLength,
              this.state.activePage * this.state.pageLength
            )
            .map((part, idx) => (
              <Col xs={12} md={4} lg={3} key={idx} className="mb-10">
                <div
                  style={{
                    backgroundColor: "#eeeeee",
                    borderRadius: "10px",
                    padding: "1rem",
                    textAlign: "center",
                    gap: "1rem",
                  }}
                  className="h-100 d-flex flex-column"
                >
                  <h3
                    style={{
                      fontSize: "14px",
                      margin: 0,
                    }}
                  >
                    {part.name}
                  </h3>

                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      maxWidth: "100%",
                      height: 120,
                      overflow: "hidden",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={part.image}
                      alt={`part-${idx}`}
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        height: 120,
                      }}
                    />
                  </div>

                  <p
                    style={{
                      fontSize: "12px",
                      margin: 0,
                      overflowWrap: "break-word",
                    }}
                  >
                    {part.description}
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      margin: 0,
                      marginTop: "auto",
                    }}
                  >
                    État:{" "}
                    <b>
                      {part.condition
                        ? part.condition
                            .replace("Good", "Bon")
                            .replace("Bad", "Mauvais")
                        : ""}
                    </b>
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      margin: 0,
                    }}
                  >
                    Référence: <b>{part.reference}</b>
                  </p>
                  <Button
                    text="Voir la pièce"
                    onClick={() => {
                      window.open(part.url, "_blank");
                    }}
                  ></Button>
                </div>
              </Col>
            ))}
        </Row>
        <Pagination
          activePage={this.state.activePage}
          itemsCountPerPage={this.state.pageLength}
          totalItemsCount={this.state.parts.length}
          pageRangeDisplayed={8}
          onChange={(activePage) => {
            this.setState({ activePage: activePage });
          }}
          firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
          lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
          prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
          nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
        />
      </Card>
    );
  }
}
