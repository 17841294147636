import axiosConfig from "../utils/axiosConfig";

const getMessageTypes = (data, callback) => {
  axiosConfig("contact/get-message-types", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const sendHelpMessage = (data, callback) => {
  axiosConfig("contact/send-help-message", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

export { getMessageTypes, sendHelpMessage };
