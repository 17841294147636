import axiosConfig from "../utils/axiosConfig";

const signin = (data, callback) => {
  axiosConfig("auth/signin", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const signout = (callback) => {
  axiosConfig("auth/signout")
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const refreshToken = (token, key, callback) => {
  axiosConfig("auth/refresh-token", { data: { token, key } })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const forgot = (data, callback) => {
  axiosConfig("auth/forgot", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const forgotCheckToken = (data, callback) => {
  axiosConfig("auth/forgot-check-token", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const savePasswordForgot = (data, callback) => {
  axiosConfig("auth/save-password-forgot", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const savePasswordNewAccount = (data, callback) => {
  axiosConfig("auth/save-password-new-account", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const checkTokenNewAccount = (data, callback) => {
  axiosConfig("auth/check-token-new-account", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const changeInformation = (data, callback) => {
  axiosConfig("auth/change-information", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const changePassword = (data, callback) => {
  axiosConfig("auth/change-password", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

export {
  signin,
  signout,
  refreshToken,
  forgot,
  forgotCheckToken,
  savePasswordForgot,
  savePasswordNewAccount,
  checkTokenNewAccount,
  changeInformation,
  changePassword,
};
