import logoLangResize from "../images/logo-lang-resize.png";

const rgpd = (
  <>
    <p>
      GROUPE LANG & ASSOCIES collecte et traite de nombreuses données à
      caractère personnel pour son compte et celui de ses clients et partenaires
      commerciaux.
    </p>
    <p>
      GROUPE LANG & ASSOCIES est fortement engagé dans la démarche de conformité
      de ses systèmes et de ses pratiques aux dispositions du Règlement Européen
      de Protection de Données.
    </p>
    <p>
      La présente charte de données personnelles a pour objet de décrire les
      principes mis en œuvre par GROUPE LANG & ASSOCIES afin de respecter le
      règlement et de protéger la vie privée des personnes physiques dont les
      données sont traitées.
    </p>
    <p>
      Elle précise également le cadre général des traitements de données à
      caractère personnel réalisés au sein de GROUPE LANG & ASSOCIES et, en ce
      sens, a pour objectif de fournir aux personnes concernées les informations
      nécessaires au parfait respect de la règlementation en vigueur.
    </p>
    <h3>1. Comment les données sont-elles collectées ?</h3>
    <p>
      GROUPE LANG & ASSOCIES collecte, au travers de ses activités, des données
      dont certaines permettent d’identifier ou de rendre identifiables des
      personnes physiques.
    </p>
    <h4>1.1. Le fondement légal de la collecte</h4>
    <p>
      La législation liste les bases légales de collecte de données
      personnelles, autrement les justifications légitimes d’une collecte de
      données. Ces bases légales sont explicitées et/ou rappelées dans le cadre
      des collectes réalisées par GROUPE LANG & ASSOCIES.
    </p>
    <p>
      A ce titre, GROUPE LANG & ASSOCIES est susceptible de collecter des
      données à caractère personnel en se fondant sur :
    </p>
    <ul>
      <li>
        <p>le consentement de la personne concernée ;</p>
        <p>
          N.B. : en France, la CNIL reconnaît deux exceptions au consentement
          préalable en matière de prospection électronique détaillées dans une
          fiche sur la prospection électronique datant d’octobre 2016 :
        </p>
        <ul>
          <li>
            <p>
              Dans les rapports entre professionnels, le consentement préalable
              de la personne concernée n’est pas requis pour des sollicitations
              commerciales envoyées sur l’adresse électronique professionnelle
              dès que ces sollicitations sont en rapport avec la profession de
              la personne en question. Cette tolérance est appelée « exception
              BtoB ». Les activités de GROUPE LANG & ASSOCIES étant
              principalement mises en œuvre entre professionnels, les collectes
              sont réalisées souvent suite à une information préalable.
            </p>
          </li>
          <li>
            <p>
              Le consentement préalable n’est pas non plus requis pour toute
              sollicitation envoyée à une personne concernée pour des
              services/produits analogues à ceux que cette personne aurait déjà
              acquis auprès du même organisme.
            </p>
          </li>
        </ul>
      </li>
      <li>
        <p>l’exécution des obligations au titre d’un contrat ;</p>
        <p>
          N.B. : Le recueil des données personnelles de nos clients et
          utilisateurs est nécessaire afin d’exécuter les termes du contrat et
          d’assurer la fourniture du service souscrit ou du produit acquis par
          la personne physique concernée. Ainsi, dans ce contexte, le
          consentement de la personne n’est pas nécessaire puisque les
          traitements réalisés sont liés à l’exécution du contrat.
        </p>
      </li>
      <li>
        <p>l’intérêt légitime¹ du responsable de traitement ;</p>
        <p>
          N.B. : Dans certaines circonstances, la nature même du service fourni
          par GROUPE LANG & ASSOCIES implique le recueil des données
          personnelles de ses clients et utilisateurs et la transmission de ces
          informations vers des tiers désignés (par ex. les garagistes,
          concessions automobiles…). Ces traitements liés à l’intérêt légitime
          du responsable de traitement dans cette hypothèse sont considérés
          comme une attente raisonnable de la part de la personne concernée au
          regard de la description du service fourni. Bien entendu, GROUPE LANG
          & ASSOCIES évalue constamment si son intérêt légitime n’est pas
          contrebalancé par l’intérêt de la personne concernée ou par le respect
          de ses droits et libertés fondamentaux.
        </p>
      </li>
      <li>
        <p>une obligation légale rendant le traitement obligatoire.</p>
        <p>
          N.B. : Le contexte règlementaire d’une activité peut rendre
          obligatoire certains traitements et transfert de données : par ex.
          pour la facturation des produits ou services, les activités de
          formation (feuille de présence), etc …
        </p>
      </li>
    </ul>
    <h4>1.2. Les modes de collecte :</h4>
    <h5>1.2.1. La collecte par le biais de formulaires</h5>
    <p>
      L’accès, l’utilisation, le téléchargement, l’achat ou la souscription à
      certains services ou de certains produits impliquent le recueil de données
      personnelles concernant le prospect client ou utilisateur. Dans ces
      hypothèses, lors du remplissage des formulaires papier, électronique, les
      personnes transmettent des informations les concernant. Ces formulaires
      précisent systématiquement :
    </p>
    <ul>
      <li>Le nom du responsable de traitement,</li>
      <li>Les finalités associées à la collecte réalisée,</li>
      <li>
        Si la collecte est rendue nécessaire par la souscription du service
        concerné ou par l’achat du produit envisagé,
      </li>
      <li>
        Les éventuelles autres exploitations envisagées et la base légale de la
        collecte réalisée ;
      </li>
      <li>
        Un renvoi aux pages pertinentes de la présente charte sur les modalités
        d’exercice des droits par les personnes physiques, les coordonnées du
        DPO, les règles concernant la durée de conservation des données, les
        modalités de réclamation auprès de l’autorité de contrôle, etc…
      </li>
    </ul>
    <h5>1.2.2. La collecte par le biais des cookies</h5>
    <p>
      Le terme « cookies » est à prendre au sens large : l’ensemble des traceurs
      déposés et / ou lus, par exemple, lors de la consultation d’un site
      internet, de la lecture d’un courrier électronique, de l’installation ou
      de l’utilisation d’un logiciel ou d’une application mobile.
    </p>
    <p>
      Les cookies basés sur un fichier pouvant être stocké sur l’ordinateur de
      l’utilisateur lors de sa navigation ont notamment pour but de simplifier
      la navigation sur les sites (authentification automatique,
      personnalisation de certaines informations …) ou de personnaliser la
      publicité apparaissant lors de la navigation des utilisateurs.
    </p>
    <p>
      Certains cookies sont déposés par GROUPE LANG & ASSOCIES directement lors
      de la navigation sur l’un de ses sites. L’utilisateur du site peut à tout
      moment s’opposer à l’utilisation de ces cookies en paramétrant son
      navigateur étant précisé que de tels paramétrages sont susceptibles de
      modifier les conditions d’accès aux produits, contenus et services
      nécessitant l’utilisation de cookies.
    </p>
    <p>
      Les indications pour paramétrer votre navigateur figurent en Annexe 1 de
      la présente charte.
    </p>
    <p>
      Par ailleurs, d’autres cookies sont déposés par des sociétés extérieures à
      GROUPE LANG & ASSOCIES afin de collecter les données de navigation des
      utilisateurs lors de leur navigation sur différents sites. GROUPE LANG &
      ASSOCIES travaille avec certaines de ces sociétés.
    </p>
    <p>
      Pour aider les utilisateurs à détecter les cookies pouvant être installés
      sur leurs ordinateurs, certains sites tels que{" "}
      <a
        style={{ display: "inline-block" }}
        href="http://www.youronlinechoices.com/fr/controler-ses-Cookies/"
        target="_blank"
        rel="noreferrer"
      >
        YourOnlineChoices
      </a>{" "}
      proposent des outils permettant de lister et de paramétrer vos cookies.
    </p>
    <p>
      Les sites de GROUPE LANG & ASSOCIES utilisent principalement les cookies
      suivants :
    </p>
    <p>Cookies de mesure d’audience :</p>
    <ul>
      <li>
        <a
          style={{ display: "inline-block" }}
          href="https://tools.google.com/dlpage/gaoptout"
          target="_blank"
          rel="noreferrer"
        >
          Google Analytics
        </a>
      </li>
    </ul>
    <p>Cookies réseaux sociaux :</p>
    <p>
      Conformément aux dispositions légales en vigueur, avant de déposer ou de
      lire un cookie sur l’ordinateur d’un utilisateur, GROUPE LANG & ASSOCIES:
    </p>
    <ul>
      <li>Informe les internautes de la finalité des cookies</li>
      <li>Obtient leur consentement lorsque celui-ci est requis ;</li>
      <li>Indique aux utilisateurs les moyens de les refuser.</li>
    </ul>
    <p>
      Les cookies et des traceurs strictement nécessaires à la fourniture d’un
      service expressément demandé par l’utilisateur ne nécessitent pas le
      consentement préalable des utilisateurs. Ainsi, par exemple, les traceurs
      suivants ne requièrent pas de consentement des utilisateurs :
    </p>
    <ul>
      <li>Les cookies de » panier d’achat » pour un site marchand ;</li>
      <li>
        Les cookies » identifiants de session «, pour la durée d’une session, ou
        les cookies persistants limités à quelques heures dans certains cas ;
      </li>
      <li>Les cookies d’authentification ;</li>
      <li>Les cookies de session créés par un lecteur multimédia ;</li>
      <li>
        Les cookies de session d’équilibrage de charge (» load balancing «) ;
      </li>
      <li>Certaines solutions d’analyse de mesure d’audience (analytics) ;</li>
      <li>
        Les cookies persistants de personnalisation de l’interface utilisateur
        (choix de langue ou de présentation).
      </li>
    </ul>
    <p>
      Tout autre cookie nécessite une information préalable et une demande de
      consentement, par exemple :
    </p>
    <ul>
      <li>Les cookies liés aux opérations relatives à la publicité ;</li>
      <li>
        Les cookies des réseaux sociaux générés par les boutons de partage de
        réseaux sociaux lorsqu’ils collectent des données personnelles sans
        consentement des personnes concernées ;
      </li>
      <li>Certains cookies de mesure d’audience.</li>
    </ul>
    <p>
      Conformément aux recommandations de la CNIL, le recueil du consentement se
      fait par l’apparition d’un bandeau visible sur le site internet qui doit
      contenir les informations suivantes :
    </p>
    <ul>
      <li>Des finalités précises des cookies utilisés ;</li>
      <li>
        De la possibilité de s’opposer à ces cookies et de changer les
        paramètres en cliquant sur un lien "en savoir plus et paramétrer les
        cookies" présent dans le bandeau (avec un renvoi au présent paragraphe)
        ;
      </li>
      <li>
        du fait que la poursuite de sa navigation vaut accord au dépôt de
        Cookies sur son terminal.
      </li>
    </ul>
    <p>
      De manière générale, si l’utilisateur partage son ordinateur avec d’autres
      personnes, il doit veiller à supprimer les cookies installés sur son
      ordinateur via le paramétrage de son navigateur.
    </p>
    <h5>1.2.3. La collecte par téléphone</h5>
    <p>
      GROUPE LANG & ASSOCIES réalise certaines prestations par téléphone et à
      cette occasion peut recueillir des données personnelles. Lorsque cela est
      possible, le contact téléphonique est confirmé par l’envoi d’un courriel
      permettant ainsi à la personne concernée de conserver une trace écrite de
      la conversation et de pouvoir exercer ses droits à tout moment.
    </p>
    <h5>1.2.4. Collecte indirecte</h5>
    <p>
      GROUPE LANG & ASSOCIES peut obtenir des données à caractère personnel
      auprès de tiers (voir le chapitre 5). Dans une telle hypothèse, GROUPE
      LANG & ASSOCIES:
    </p>
    <ul>
      <li>
        Établit un contrat avec ce tiers conformément aux dispositions du
        Règlement ;
      </li>
      <li>
        Avise les personnes du transfert de leurs données vers GROUPE LANG &
        ASSOCIES dans les conditions définies par le règlement ;
      </li>
      <li>
        Indique dans ses fichiers la source des données afin d’en assurer la
        traçabilité ;
      </li>
      <li>
        Informe les personnes concernées des modalités d’exercice de leurs
        droits.
      </li>
    </ul>
    <h3>2. Quels types d’informations sont recueillis ?</h3>
    <p>
      Certaines des informations recueillies constituent des « Données à
      caractère Personnel », à savoir des données concernant des personnes
      permettant de les identifier.
    </p>
    <p>
      En conformité avec la législation en vigueur, GROUPE LANG & ASSOCIES a
      adopté le principe de minimisation dans la collecte et ne collecte que les
      données strictement nécessaires à l’objectif poursuivi et explicité auprès
      des personnes physiques concernées, en leur laissant toute capacité
      d’exercice de leurs droits.
    </p>
    <p>
      Les données à caractère personnel susceptibles d’être demandées, en
      fonction de la nature des services ou produits fournis, sont les suivantes
      :
    </p>
    <p>Principalement :</p>
    <ul>
      <li>
        Votre nom et vos coordonnées, y compris vos adresses électronique et
        postale,
      </li>
      <li>Votre entreprise et fonction,</li>
      <li>Vos numéros de téléphone et de télécopie,</li>
    </ul>
    <p>Le cas échéant pour certains produits et services :</p>
    <ul>
      <li>Les équipements informatiques utilisés lors de la navigation,</li>
      <li>
        Vos données de connexion et de navigation (adresses IP, logs) etc….
      </li>
    </ul>
    <h3>3. A quoi servent les données recueillies ?</h3>
    <h4>3.1. Utilisation des données collectées</h4>
    <p>
      GROUPE LANG & ASSOCIES peut utiliser les données à caractère personnel
      dont il est en possession afin de :
    </p>
    <ul>
      <li>
        Envoyer des informations commerciales relatives à ses produits, ses
        promotions, ses offres, ainsi que d’autres informations ayant trait à
        ses produits ou services adaptées aux centres d’intérêts des personnes
        concernées ;
      </li>
      <li>
        Transmettre des informations sur les produits et les offres de tiers –
        clients ou partenaires commerciaux de GROUPE LANG & ASSOCIES en relation
        avec la fonction et/ou au regard d’un intérêt identifié par rapport à
        l’activité de la personne concernée ou celle de l’organisation à
        laquelle elle appartient ;
      </li>
      <li>
        Éditer des annuaires payants de professionnels et de décideurs afin de
        leur proposer des produits et des offres en relation avec leurs
        fonctions et/ou au regard d’un intérêt identifié par rapport à
        l’activité de la personne concernée ou celle de l’organisation à
        laquelle elle appartient.
      </li>
    </ul>
    <p>
      Ces données à caractère personnel seront utilisées par GROUPE LANG &
      ASSOCIES dans le cadre de ses activités relevant de la promotion de ses
      propres produits et services comme de la prospection pour le compte de
      tiers. Elles ne sont utilisées que dans les strictes limites définies par
      la législation en vigueur.
    </p>
    <h4>3.2. Modalités d’envoi d’information</h4>
    <p>
      Selon les coordonnées qui auront été collectées, GROUPE LANG & ASSOCIES et
      ses partenaires pourront transmettre des informations par les moyens
      suivants :
    </p>
    <ul>
      <li>
        Message texte envoyé à une personne (SMS ou MMS, notification, courriel,
        et/ou toute autre forme de message électronique) ;
      </li>
      <li>Message via les réseaux sociaux ;</li>
      <li>Téléphone ;</li>
      <li>Courrier postal ;</li>
      <li>Bannière promotionnelle web ;</li>
      <li>Moteur de recherche sur internet.</li>
    </ul>
    <h4>3.3. Objectifs de la collecte</h4>
    <p>
      La finalité de la collecte est systématiquement indiquée lorsqu’elle est
      directement réalisée par GROUPE LANG & ASSOCIES et rappelée lors du
      transfert des données lorsque la collecte a été effectuée par un tiers.
      GROUPE LANG & ASSOCIES est susceptible d’utiliser les données à caractère
      personnel d’une personne notamment pour les objectifs suivants :
    </p>
    <ul>
      <li>
        Afin de l’enregistrer sur ses sites internet et/ou systèmes
        d’information et de gérer la livraison et la facturation des
        services/produits fournis par toute société de GROUPE LANG & ASSOCIES (y
        compris le traitement de toutes recherches ou demandes d’informations
        nous concernant ou portant sur ses produits ou services). Ex. :
        traitement des commandes ou d’une inscription.
      </li>
      <li>
        Afin d’être en mesure d’exécuter les obligations qui lui incombent aux
        termes de tout contrat le liant à la personne concernée et dans le cadre
        de la gestion de ce type de contrat. Ex. : gestion des identifiants
        d’accès utilisateurs à un logiciel, badges d’accès à un salon etc.
      </li>
      <li>
        Aux fins de respecter les obligations légales lui incombant ; Ex. :
        gestion de la participation à une session de formation : tenue d’une
        feuille de présence
      </li>
      <li>
        Aux fins du suivi, de l’examen critique et de l’amélioration de son
        offre de produits et de services ;
      </li>
      <li>
        Aux fins d’analyse de données de connexion et de navigation dans le but
        d’en déduire un comportement de navigation et/ou d’adapter les contenus
        proposés en fonction des affinités constatées ;
      </li>
      <li>
        Afin de tenir des dossiers à usage administratif interne (réclamations
        clients, fidélisation etc…) ;
      </li>
      <li>
        À des fins de prospection commerciale pour son compte ou celles de ses
        partenaires commerciaux et annonceurs, dans les conditions définies
        ci-après dans la section ci-dessus « Utilisation des données collectées
        »;
      </li>
      <li>
        Aux fins de participation à des concours, loteries ou à des promotions.
      </li>
    </ul>
    <h3>4. Comment et combien de temps les données sont-elles stockées ?</h3>
    <p>
      Des actions de traitements sont réalisées sur les données figurant dans
      les bases de GROUPE LANG & ASSOCIES, en appliquant des règles de contrôle
      strictes, conformes à l’état de l’art technologique et aux recommandations
      de l’autorité de contrôle compétente.
    </p>
    <h4>4.1. Le Stockage des données à caractère personnel</h4>
    <p>
      GROUPE LANG & ASSOCIES prend toutes précautions utiles afin de préserver
      la sécurité et la confidentialité des Données à caractère personnel et
      notamment d’empêcher qu’elles ne soient déformées, endommagées ou que des
      tiers non autorisés y aient accès.
    </p>
    <p>
      Les recommandations de la Commission Nationale Informatique et Liberté
      sont prises en compte dans la gestion de la sécurité pour l’ensemble du
      Groupe.
    </p>
    <h4>4.2. La durée de conservation des données et l’archivage</h4>
    <p>
      La durée de conservation dépend de l’activité concernée, de la nature du
      contact (client ou prospect) et des usages du secteur.
    </p>
    <ul>
      <li>
        GROUPE LANG & ASSOCIES conserve certains documents obligatoires
        (factures etc…) pour la durée légale de conservation.
      </li>
      <li>
        La durée de conservation des données personnelles est fixée par défaut
        pour l’ensemble de GROUPE LANG & ASSOCIES pour une durée de 6 ans.
      </li>
      <li>
        Certaines données sont conservées pour une durée de conservation plus
        courte :
        <ul>
          <li>
            Les cookies expirent treize mois après leur dernière mise à jour.
          </li>
          <li>
            Les données des prospects sont supprimées au-delà d’une durée de 3
            ans sans réponse à aucune sollicitation.
          </li>
          <li>
            Les CV des candidats sont conservés pendant une durée de 2 ans.
          </li>
        </ul>
      </li>
      <li>
        La durée est parfois liée à la pertinence ou la nécessité de son
        traitement : les données des clients sont conservées pour la durée de la
        relation commerciale ou les données présentes dans les annuaires sont
        conservées pour la durée de mandats des personnes concernées.
      </li>
    </ul>
    <h3>
      5. Qui sont les tiers ayant accès aux données personnelles collectées ?
    </h3>
    <h4>5.1. A l’intérieur de GROUPE LANG & ASSOCIES</h4>
    <p>
      GROUPE LANG & ASSOCIES est composé de plusieurs sociétés situées en Union
      Européenne ou non et susceptibles de se voir communiquer des données
      personnelles provenant d’une autre filiale du groupe, dans le cadre de son
      organisation fonctionnelle².
    </p>
    <p>
      A titre d’exemple, certains traitements sont réalisés par l’un des membres
      du personnel d’une autre filiale du groupe afin d’exécuter des prestations
      d’assistance commerciale, d’études de marché ou de services au client,
      ainsi qu’en matière de gestion de comptes, de fourniture de produits ou de
      services fournis aujourd’hui ou à l’avenir, ou de participation à des
      concours, loteries ou promotions.
    </p>
    <p>
      La commercialisation et la production de certains produits et services de
      GROUPE LANG & ASSOCIES sont dans certains cas réalisées de manière
      transverse entre plusieurs entités du groupe, le partage de ressources
      pouvant impliquer l’exploitation de fichiers entre plusieurs entités dans
      une relation de sous-traitance ou de coresponsabilité de traitement. Tout
      transfert hors Union Européenne intragroupe est encadré par un contrat
      reprenant les clauses contractuelles type (voir infra chapitre 7).
    </p>
    <h4>5.2. A l’extérieur de GROUPE LANG & ASSOCIES</h4>
    <p>
      GROUPE LANG & ASSOCIES est susceptible de transférer les données à
      caractère personnel qu’il collecte à différents tiers comme par exemple :
    </p>
    <ul>
      <li>
        Des clients / partenaires ayant souscrit un service pouvant impliquer le
        recueil de données personnelles des utilisateurs notamment dans le cadre
        d’une demande de mise en relation ou dans le cadre de la constitution
        d’un fichier de prospection ;
      </li>
      <li>
        Des prestataires, sous-traitants et fournisseurs afin de réaliser des
        prestations pour son compte (par exemple : prestations techniques,
        services de paiement, vérification d’identité, fournisseurs de solutions
        analytiques, tchat, prestations) ;
      </li>
      <li>
        D’autres entreprises, des organismes financiers ou des
        organismes/services chargés de faire respecter les lois aux fins de la
        prévention ou de la détection de fraudes, lorsque cette divulgation est
        nécessaire pour préserver les droits de GROUPE LANG & ASSOCIES;
      </li>
      <li>
        Dans les cas où la loi le prévoit ou sur demande formelle d’une autorité
        (notamment dans le cadre d’une procédure judiciaire), des organismes
        publics, parapublics ou privés dans le cadre d’une mission de service
        public ;
      </li>
      <li>
        En cas de fusion, d’acquisition, de dissolution ou de vente de tout ou
        partie de ses actifs. Les personnes concernées seront informées par
        email et/ou par un message bien en évidence sur le ou les sites web de
        CK Consultant de tout changement de propriété ou concernant les
        utilisations de données à caractère personnel et des choix dont elles
        disposeront.
      </li>
    </ul>
    <h4>5.3. Les modalités de travail avec les tiers</h4>
    <p>
      Dans le cas où des données à caractère personnel sont transmises à un
      tiers pour quelque raison (par exemple : une prestation de sous-traitance,
      prestations réalisées pour un client), GROUPE LANG & ASSOCIES applique les
      conditions définies par la législation en vigueur, notamment l’information
      des personnes concernées de ce transfert.
    </p>
    <p>
      GROUPE LANG & ASSOCIES veille à ce que des stipulations contractuelles
      appropriées entre GROUPE LANG & ASSOCIES et le tiers concerné garantissent
      que ce dernier :
    </p>
    <ul>
      <li>
        N’utilisera les données à caractère personnel que dans le but spécifié
        par ses soins et conformément aux objectifs définis dans le cadre de la
        présente charte,
      </li>
      <li>
        Et aura pris les mesures de sécurité appropriées afin de prévenir un
        traitement non autorisé ou illicite des données à caractère personnel,
        une perte ou une destruction accidentelle de ces données à caractère
        personnel, ou des dommages à celles-ci.
      </li>
    </ul>
    <h3>6. A qui s’adresser pour avoir des informations ?</h3>
    <p>
      GROUPE LANG & ASSOCIES a adapté son organisation afin de répondre aux
      exigences du Règlement Européen Protection de Données et d’apporter à
      toute personne toutes informations sur les données à caractère personnel
      les concernant collectées et sur les traitements réalisés sur ces données.
    </p>
    <h4>
      6.1 l’exercice des droits d’accès, d’opposition, de rectification et de
      suppression
    </h4>
    <p>
      Toute demande liée à l’exercice de vos droits doit être envoyée à
      l’adresse dpo@groupelang.fr Afin qu’elle puisse être traitée dans les
      délais requis, elle doit comporter les informations nécessaires à votre
      identification et au dossier d’expertise concerné. Aussi nous vous
      invitons à renseigner vos nom, prénom, adresse électronique et
      l’immatriculation du véhicule objet de l’expertise ou les références du
      dossier de l’expert.
    </p>
    <h4>6.2 l’exercice du droit à l’oubli</h4>
    <p>
      Toute demande concernant une donnée personnelle figurant dans un document
      édité par GROUPE LANG & ASSOCIES doit être envoyée à l’adresse suivante :
      dpo@groupelang.fr. Cette demande doit indiquer les motifs de la requête.
      Une fois la suppression de données traitée, toute demande de
      déréférencement d’un article dans un moteur de recherche doit être
      adressée directement auprès dudit moteur de recherche par la personne
      concernée.
    </p>
    <h4>6.3 la portabilité des données</h4>
    <p>
      Toute demande liée à la portabilité des données doit être envoyée au DPO
      de GROUPE LANG & ASSOCIES qui vous répondra sur la faisabilité d’une telle
      demande.
    </p>
    <h4>
      6.4 La désignation d’un Data Protection Officer (DPO) et le recours à
      l’autorité de contrôle
    </h4>
    <p>
      Afin de compléter ce dispositif, GROUPE LANG & ASSOCIES a nommé un Data
      Protection Officer qui est joignable à l’adresse suivante :
      dpo@groupelang.fr pour toute question ou difficulté touchant aux
      traitements de données personnelles.
    </p>
    <p>
      Toute personne a la possibilité de contacter directement la Commission
      nationale Informatique et liberté (CNIL).
    </p>
    <h3>7. Les données sont-elles transférées hors UE ?</h3>
    <p>
      Si GROUPE LANG & ASSOCIES communique les Données à caractère Personnel à
      une Société de GROUPE LANG & ASSOCIES ou à un tiers implanté en dehors de
      l’Union Européenne, des mesures sont prises visant à s’assurer que
      lesdites données bénéficieront du même niveau de protection que celui
      imposé par l’Union Européenne en matière de protection des données.
    </p>
    <p>
      A ce titre, GROUPE LANG & ASSOCIES s’assurera que le traitement soit
      effectué conformément à la présente charte et qu’il soit encadré par les
      clauses contractuelles types de la Commission Européenne qui permettent de
      garantir un niveau de protection suffisant de la vie privée et des droits
      fondamentaux des personnes.
    </p>
    <h3>8. Existe-t-il des modalités spécifiques de traitement ?</h3>
    <p>
      GROUPE LANG & ASSOCIES est susceptible de combiner des informations
      concernant des entreprises avec des informations confiées par les
      personnes physiques dans les conditions et pour les finalités définies
      dans la présente charte.
    </p>
    <p>
      Les méthodes de profilage utilisées au sein de GROUPE LANG & ASSOCIES
      consistent à réaliser des croisements manuels ou automatisés, entre des
      fichiers d’entreprises et nos bases de données de contacts de GROUPE LANG
      & ASSOCIES (nom, fonction, adresse électronique …), à partir de critères
      objectifs (taille, secteur, équipements informatiques…etc.).
    </p>
    <h3>9. Recrutement</h3>
    <p>
      Dans le cadre de sa politique de recrutement, GROUPE LANG & ASSOCIES
      recueille et stocke des données personnelles portant sur des candidats
      potentiels.
    </p>
    <p>
      GROUPE LANG & ASSOCIES recueille les informations nécessaires à la
      recherche des profils les plus adaptées aux postes à pourvoir dans le
      respect de la loi ainsi que des droits et libertés des personnes. GROUPE
      LANG & ASSOCIES s’interdit de transmettre à un tiers, le CV avec les
      coordonnées d’une personne, sans son accord.
    </p>
    <p>
      Les candidats qui souhaitent modifier ou supprimer leurs données
      personnelles de nos bases peuvent à tout moment adresser un mail à
      dpo@groupelang.fr en indiquant en objet « données personnelles ».
    </p>
    <p>
      Le candidat doit s’assurer de l’accord des personnes données en référence
      pour être contactées par GROUPE LANG & ASSOCIES.
    </p>
    <h3>10. Comment serez-vous informé des mises à jour de cette charte ?</h3>
    <p>
      GROUPE LANG & ASSOCIES peut être amené à modifier ou mettre à jour la
      présente Charte Données Personnelles. Toute mise à jour sera affichée aux
      endroits jugés appropriés, de sorte que tout utilisateur sera averti de la
      date de la dernière mise à jour.
    </p>
    <p>
      Les mises à jour les plus importantes pourront faire l’objet d’un avis sur
      le site web institutionnel de GROUPE LANG & ASSOCIES (www.ixperience.fr)
      au plus tard au moment de l’entrée en vigueur desdites modifications.
    </p>
    <h3>ANNEXE 1 : paramétrage des navigateurs</h3>
    <p>
      Le paramétrage est susceptible de modifier vos conditions d’accès aux
      contenus et services nécessitant l’utilisation de cookies.
    </p>
    <p>
      Si le navigateur est configuré de manière à refuser l’ensemble des
      cookies, l’accès à tout ou partie du site peut être bloqué.
    </p>
    <p>
      Afin de gérer les cookies au plus près des attentes des utilisateurs, le
      navigateur doit être paramétré en tenant compte de la finalité des
      cookies.
    </p>
    <ul>
      <li>
        <a
          style={{ display: "inline-block" }}
          href="https://support.microsoft.com/fr-fr/windows/supprimer-et-g%C3%A9rer-les-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
          target="_blank"
          rel="noreferrer"
        >
          Microsoft Internet Explorer
        </a>
      </li>
      <li>
        <a
          style={{ display: "inline-block" }}
          href="https://support.microsoft.com/fr-fr/windows/microsoft-edge-donn%C3%A9es-de-navigation-et-confidentialit%C3%A9-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd"
          target="_blank"
          rel="noreferrer"
        >
          Microsoft Edge
        </a>
      </li>
      <li>
        <a
          style={{ display: "inline-block" }}
          href="http://docs.info.apple.com/article.html?path=Safari/3.0/fr/9277.html"
          target="_blank"
          rel="noreferrer"
        >
          Apple Safari
        </a>
      </li>
      <li>
        <a
          style={{ display: "inline-block" }}
          href="https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en"
          target="_blank"
          rel="noreferrer"
        >
          Google Chrome
        </a>
      </li>
      <li>
        <a
          style={{ display: "inline-block" }}
          href="https://support.mozilla.org/fr/kb/protection-renforcee-contre-pistage-firefox-ordinateur?redirectslug=activer-desactiver-cookies-preferences&redirectlocale=fr"
          target="_blank"
          rel="noreferrer"
        >
          Mozilla Firefox
        </a>
      </li>
      <li>
        <a
          style={{ display: "inline-block" }}
          href="https://help.opera.com/en/latest/web-preferences/#cookies"
          target="_blank"
          rel="noreferrer"
        >
          Opera
        </a>
      </li>
    </ul>
    <ol>
      <li>
        Considérant (47) du Règlement 2016/679 : Les intérêts légitimes d’un
        responsable du traitement (…) peuvent constituer une base juridique pour
        le traitement, à moins que les intérêts ou les libertés et droits
        fondamentaux de la personne concernée ne prévalent, compte tenu des
        attentes raisonnables des personnes concernées fondées sur leur relation
        avec le responsable du traitement. Un tel intérêt légitime pourrait, par
        exemple, exister lorsqu’il existe une relation pertinente et appropriée
        entre la personne concernée et le responsable du traitement (…). (…) Le
        traitement de données à caractère personnel à des fins de prospection
        peut être considéré comme étant réalisé pour répondre à un intérêt
        légitime.
      </li>
      <li>
        Considérant (48) de Règlement 2016/679 : Les responsables du traitement
        qui font partie d’un groupe d’entreprises ou d’établissements affiliés à
        un organisme central peuvent avoir un intérêt légitime à transmettre des
        données à caractère personnel au sein du groupe d’entreprises à des fins
        administratives internes, y compris le traitement de données à caractère
        personnel relatives à des clients ou des employés.
      </li>
    </ol>
  </>
);

const rgpdAndCookies = (
  <>
    <div>
      <img height={40} alt="groupe lang & associés" src={logoLangResize} />
    </div>
    <h2>Liste des cookies</h2>
    <h3>Authentification et sécurité</h3>
    <p>
      Ces cookies sont nécessaires au fonctionnement du site et ne peuvent pas
      être désactivés. Ils permettent le maintien de votre session, de vous
      connecter à l’espace client et d’assurer la sécurité du site.
    </p>
    <table
      style={{
        borderSpacing: "0 10px",
        backgroundColor: "#f8f8f8",
        fontSize: "14px",
        marginBottom: "16px",
      }}
    >
      <tbody>
        <tr>
          <td className="p-5">Nom</td>
          <td className="p-5">token</td>
        </tr>
        <tr>
          <td className="p-5" style={{ verticalAlign: "top" }}>
            Description
          </td>
          <td className="p-5">
            Cookie d’authentification utilisé par l’outil JWT sur l’espace
            client. Conserve un ID de session nécessaire au maintien de la
            session pour les échanges entre l’application web et le serveur
            durant la visite de l’utilisateur.
          </td>
        </tr>
        <tr>
          <td className="p-5">Durée</td>
          <td className="p-5">Persistant</td>
        </tr>
      </tbody>
    </table>
    <h3>Enregistrement du consentement sur les cookies</h3>
    <p>
      Ces cookies sont nécessaires au fonctionnement du site et ne peuvent pas
      être désactivés. Ils permettent de conserver vos choix sur l’utilisation
      des cookies soumis au consentement.
    </p>
    <table
      style={{
        borderSpacing: "0 10px",
        backgroundColor: "#f8f8f8",
        padding: "8px",
        fontSize: "14px",
        marginBottom: "16px",
      }}
    >
      <tbody>
        <tr>
          <td className="p-5">Nom</td>
          <td className="p-5">ixperience-cookies</td>
        </tr>
        <tr>
          <td className="p-5" style={{ verticalAlign: "top" }}>
            Description
          </td>
          <td className="p-5">
            Cookie qui permet d’afficher le bandeau pour collecter le
            consentement sur l’utilisation des cookies sur ixperience.fr.
            Conserve les finalités de cookies que l’utilisateur du site
            ixperience.fr a accepté.
          </td>
        </tr>
        <tr>
          <td className="p-5">Durée</td>
          <td className="p-5">Persistant</td>
        </tr>
      </tbody>
    </table>
    <h3>Personnalisation essentielle de l’interface</h3>
    <p>
      Ces cookies sont nécessaires au fonctionnement du site et ne peuvent pas
      être désactivés. Ils permettent de conserver le paramétrage des
      formulaires en ligne et de maintenir l’affichage d’éléments de navigation
      personnalisés essentiels.
    </p>
    <table
      style={{
        borderSpacing: "0 10px",
        backgroundColor: "#f8f8f8",
        padding: "8px",
        fontSize: "14px",
        marginBottom: "16px",
      }}
    >
      <tbody>
        <tr>
          <td className="p-5">Nom</td>
          <td className="p-5">ixperience-XXX-notification</td>
        </tr>
        <tr>
          <td className="p-5" style={{ verticalAlign: "top" }}>
            Description
          </td>
          <td className="p-5">
            Cookie interne de session utilisé afin de conserver le paramétrage
            des popups de demande d'action en fonction de l'évolution du
            dossier.
          </td>
        </tr>
        <tr>
          <td className="p-5">Durée</td>
          <td className="p-5">Persistant</td>
        </tr>
      </tbody>
    </table>
    <table
      style={{
        borderSpacing: "0 10px",
        backgroundColor: "#f8f8f8",
        padding: "8px",
        fontSize: "14px",
        marginBottom: "16px",
      }}
    >
      <tbody>
        <tr>
          <td className="p-5">Nom</td>
          <td className="p-5">ixperience-XXX-PRE</td>
        </tr>
        <tr>
          <td className="p-5" style={{ verticalAlign: "top" }}>
            Description
          </td>
          <td className="p-5">
            Cookie interne de session utilisé afin de conserver le paramétrage
            des popups de demande d'action sur le bloc "Pièces de réemploi" du
            dossier.
          </td>
        </tr>
        <tr>
          <td className="p-5">Durée</td>
          <td className="p-5">Persistant</td>
        </tr>
      </tbody>
    </table>
    <h2 style={{ marginTop: "50px" }}>
      Charte des données personnelles de GROUPE LANG & ASSOCIES
    </h2>
    {rgpd}
  </>
);

export { rgpd, rgpdAndCookies };
