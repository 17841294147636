import axiosConfig from "../utils/axiosConfig";

const getNotificationChoice = (callback) => {
  axiosConfig("setting/get-notification-choice")
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const setNotificationChoice = (data, callback) => {
  axiosConfig("setting/set-notification-choice", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const setNotificationChoiceAuth = (data, callback) => {
  axiosConfig("setting/set-notification-choice-auth", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

export {
  getNotificationChoice,
  setNotificationChoice,
  setNotificationChoiceAuth,
};
