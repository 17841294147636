import React from "react";

import {
  Loader,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  ButtonIcon,
  Modal,
  Card,
  Button,
} from "ui-kit-ck-consultant";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col } from "react-flexbox-grid";

import {
  faPaperPlane,
  faEye,
  faRefresh,
} from "@fortawesome/free-solid-svg-icons";

import { getEmailData, resendEmail } from "../../../requests/folder";

import AuthContext from "../../../context/AuthContext";

export default class HistoriqueEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      modalIsOpen: false,
      previewEmailData: {},
      previewEmailHtml: "",
      screenHeigth: "",
      dataEmail: [],
    };
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.getEmailData();
  }

  getEmailData = () => {
    this.setState({ isLoad: true }, () => {
      getEmailData({ folderId: this.context.id }, (result) => {
        if (result.success) {
          this.setState({ isLoad: false, dataEmail: result.data });
        } else {
          this.setState({ isLoad: false });
        }
      });
    });
  };

  resendEmail = (emailId) => {
    this.setState({ isLoad: true }, () => {
      resendEmail({ emailId }, (result) => {
        if (result.success) {
          this.setState({ isLoad: false }, () => {
            window.alert("L'email a bien été renvoyé");
          });
        } else {
          this.setState({ isLoad: false }, () => {
            window.alert("Une erreur est survenue !");
          });
        }
      });
    });
  };

  refreshEmailData = () => {
    this.setState({ isLoad: true }, () => {
      this.getEmailData();
    });
  };

  render() {
    return (
      <>
        <Card
          title="Emails"
          className={"mb-20"}
          action={
            <Row>
              <Col>
                <Button
                  text="Actualiser"
                  onClick={() => this.refreshEmailData()}
                >
                  <FontAwesomeIcon icon={faRefresh} />
                </Button>
              </Col>
            </Row>
          }
        >
          <Table>
            <Thead>
              <Tr>
                <Th className={`${this.props.isSmall ? "pl-10 pr-10" : ""}`}>
                  Date
                </Th>
                <Th className={`${this.props.isSmall ? "pl-10 pr-10" : ""}`}>
                  À
                </Th>
                <Th className={`${this.props.isSmall ? "pl-10 pr-10" : ""}`}>
                  Date de lecture
                </Th>
                <Th className={`${this.props.isSmall ? "pl-10 pr-10" : ""}`}>
                  Sujet
                </Th>
                <Th className={`${this.props.isSmall ? "pl-10 pr-10" : ""}`}>
                  Actions
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {this.state.dataEmail.map((email, index) => (
                <Tr key={email.id} className="cursor-pointer">
                  <Td className="nowrap">{email.dateInsert}</Td>
                  <Td>{email.emailTo}</Td>
                  <Td>{email.readingDate}</Td>
                  <Td>{email.subject}</Td>
                  <Td
                    className={`${
                      this.props.isSmall ? "pl-10 pr-10" : "nowrap"
                    }`}
                  >
                    <div>
                      <ButtonIcon
                        small
                        className="blue"
                        onClick={() => {
                          this.setState({
                            modalIsOpen: true,
                            previewEmailData: email,
                            previewEmailHtml: email.emailData.html,
                          });
                        }}
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </ButtonIcon>
                      <ButtonIcon
                        small
                        className="blue"
                        onClick={() => {
                          if (
                            window.confirm(
                              "Êtes-vous sûr de vouloir renvoyer cet email ?"
                            )
                          )
                            this.resendEmail(email.id);
                        }}
                      >
                        <FontAwesomeIcon icon={faPaperPlane} />
                      </ButtonIcon>
                    </div>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Modal
            large
            show={this.state.modalIsOpen}
            onClose={() => this.setState({ modalIsOpen: false })}
          >
            <h3 className="m-0" style={{ fontSize: "20px" }}>
              {this.state.previewEmailData.subject}
            </h3>
            <h5 className="m-0">
              De :{" "}
              <span style={{ fontWeight: "normal" }}>
                {this.state.previewEmailData.emailFrom}
              </span>
            </h5>
            <h5 className="m-0">
              A :{" "}
              <span style={{ fontWeight: "normal" }}>
                {this.state.previewEmailData.emailTo}
              </span>
            </h5>
            <iframe
              style={{ height: this.state.screenHeigth }}
              srcDoc={this.state.previewEmailHtml}
              onLoad={(el) => {
                this.setState({
                  screenHeigth: el.target.contentWindow.screen.height + "px",
                });
              }}
            ></iframe>
          </Modal>
          <Loader show={this.state.isLoad} />
        </Card>
      </>
    );
  }
}
