import axiosConfig from "../utils/axiosConfig";

const getStatutes = (callback) => {
  axiosConfig("home/get-statutes")
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const getNatureExpertises = (callback) => {
  axiosConfig("home/get-nature-expertises")
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const getCompanies = (callback) => {
  axiosConfig("home/get-companies")
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const getRegions = (callback) => {
  axiosConfig("home/get-regions")
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const getWarningsTotals = (data, callback) => {
  axiosConfig("home/get-warnings-totals", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const getAgencies = (data, callback) => {
  axiosConfig("home/get-agencies", { data })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const getManageEgaLocation = (callback) => {
  axiosConfig("home/get-manage-ega-location")
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

export {
  getStatutes,
  getNatureExpertises,
  getCompanies,
  getRegions,
  getWarningsTotals,
  getAgencies,
  getManageEgaLocation
};
