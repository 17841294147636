import React from "react";

import {
  Loader,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Card,
} from "ui-kit-ck-consultant";

import { getMailevaData } from "../../../requests/folder";

import AuthContext from "../../../context/AuthContext";

export default class HistoriqueEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      modalIsOpen: false,
      previewEmailData: {},
      previewEmailHtml: "",
      screenHeigth: "",
      dataEmail: [],
    };
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.getMailevaData();
  }

  getMailevaData = () => {
    this.setState({ isLoad: true }, () => {
      getMailevaData({ folderId: this.context.id }, (result) => {
        if (result.success) {
          this.setState({ isLoad: false, dataEmail: result.data });
        } else {
          this.setState({ isLoad: false });
        }
      });
    });
  };

  render() {
    return (
      <>
        <Card title="Courriers" className={"mb-20"}>
          <Table>
            <Thead>
              <Tr>
                <Th className={`${this.props.isSmall ? "pl-10 pr-10" : ""}`}>
                  Date d'envoi
                </Th>
                <Th className={`${this.props.isSmall ? "pl-10 pr-10" : ""}`}>
                  Destinataire
                </Th>
                <Th className={`${this.props.isSmall ? "pl-10 pr-10" : ""}`}>
                  Statut
                </Th>
                <Th className={`${this.props.isSmall ? "pl-10 pr-10" : ""}`}>
                  Type d'envoi
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {this.state.dataEmail.map((element, index) => (
                <Tr key={index} className="cursor-pointer">
                  <Td className="nowrap">{element.dateSend}</Td>
                  <Td>
                    {element.name} {element.address1}{" "}
                    {element.address2 ? `${element.address2} ` : ""}
                    {element.zipCode} {element.city}
                  </Td>
                  <Td>{element.statusText}</Td>
                  <Td>{element.type}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Loader show={this.state.isLoad} />
        </Card>
      </>
    );
  }
}
