import React from "react";
import { Modal } from "ui-kit-ck-consultant";

import AuthContext from "../../../../context/AuthContext";

export default class ModalAnswerCatnat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static contextType = AuthContext;

  getCorrectInputAnswer = (name, text, isText) => {
    const data = this.props.dataExpertiseCatnat;

    if (
      data[`${name}_radio`] === 0 ||
      data[`${name}_radio`] === 1 ||
      data[`${name}_text`]
    ) {
      return (
        <>
          <p className="m-0 mt-10">
            {text} :{" "}
            {isText ? (
              <b>{data[`${name}_text`]}</b>
            ) : (
              <b style={data[`${name}_radio`] !== 1 ? { color: "red" } : {}}>
                {data[`${name}_radio`] !== 1
                  ? `Non ${data[`${name}_text`]}`
                  : "Oui"}
              </b>
            )}
          </p>
        </>
      );
    } else {
      return null;
    }
  };

  getRadioAnswer = (name, text, isText = false) => {
    const data = this.props.dataExpertiseCatnat;

    if (
      (isText === false && data[`${name}_radio`] > 0) ||
      data[`${name}_radio`] === 0
    ) {
      return (
        <>
          <p className="m-0 mt-10">
            {text} : <b>{data[`${name}_radio`] === 0 ? "Non" : "Oui"}</b>
          </p>
        </>
      );
    } else if (isText && data[`${name}_radio`]) {
      return (
        <>
          <p className="m-0 mt-10">
            {text} : <b>{data[`${name}_radio`].replace("?", "")}</b>
          </p>
        </>
      );
    } else {
      return null;
    }
  };

  getData = () => {
    let level = "";
    if (this.props.dataExpertiseCatnat.rolling_radio === 0) {
      level = 3;
    } else if (this.props.dataExpertiseCatnat.glass_radio === 1) {
      level = 3;
    } else if (this.props.dataExpertiseCatnat.age_radio === 1) {
      level = 2;
    } else if (this.props.dataExpertiseCatnat.VEI_radio === 1) {
      level = 2;
    } else if (this.props.dataExpertiseCatnat.VEI_radio === 0) {
      level = 1;
    }

    return (
      <>
        {this.getCorrectInputAnswer(
          "cellphone",
          <>
            Votre numéro de téléphone portable est bien le{" "}
            <b>{this.context.client.cellphone}</b> ?
          </>
        )}
        {this.getCorrectInputAnswer(
          "email",
          <>
            Votre adresse e-mail est bien <b>{this.context.client.email}</b> ?
          </>
        )}
        {this.getCorrectInputAnswer(
          "brand",
          <>
            La marque de votre véhicule est bien{" "}
            <b>{this.context.vehicle.brand}</b> ?
          </>
        )}
        {this.getCorrectInputAnswer(
          "model",
          <>
            Le modèle de votre véhicule est bien{" "}
            <b>{this.context.vehicle.model}</b> ?
          </>
        )}
        {this.getCorrectInputAnswer(
          "licensePlate",
          <>
            L'immatriculation de votre véhicule est bien{" "}
            <b>{this.context.vehicle.licensePlate}</b> ?
          </>
        )}
        {this.getCorrectInputAnswer(
          "mileage",
          <>Kilométrage du véhicule </>,
          true
        )}
        <>
          <p className="m-0 mt-10">
            Lieu où se trouve actuellement le véhicule :{" "}
            <b>
              {this.props.dataExpertiseCatnat["location_select"] === "Autre"
                ? this.props.dataExpertiseCatnat["location_text"]
                : this.props.dataExpertiseCatnat["location_select"]}
            </b>
          </p>
        </>
        {this.getRadioAnswer("rolling", "Votre véhicule est-il roulant ?")}
        {this.getRadioAnswer(
          "glass",
          "Est-ce qu'au moins un élément vitré est brisé (Pare-brise, lunette AR, vitres latérales, toit panoramique) ?"
        )}
        {this.getRadioAnswer(
          "light",
          "Les feux arrière et / ou les optiques avant sont-ils brisés ?"
        )}
        {this.getRadioAnswer("age", "Le véhicule a-t-il plus de 5 ans ?")}
        {this.getRadioAnswer(
          "VEI",
          "Selon vous, le véhicule risque-t-il de ne pas être réparable économiquement ?"
        )}
        {this.getRadioAnswer(
          "repair",
          "Si possible, souhaitez-vous faire réparer votre véhicule ?"
        )}
        {this.getRadioAnswer(
          "PRE",
          "Etes-vous d’accord pour l’utilisation de pièces de réemploi ?"
        )}
        {!this.props.show ? (
          <p>
            <b>Niveau d'intensité des dommages : {level}</b>
          </p>
        ) : null}
      </>
    );
  };

  render() {
    return (
      <Modal
        title="Réponses"
        className="m-auto"
        show={this.props.show}
        onClose={this.props.onClose}
        large
      >
        {this.getData()}
      </Modal>
    );
  }
}
