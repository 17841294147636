import React from "react";
import { FullModal } from "ui-kit-ck-consultant";

export default class IframeWindTransfer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.addEventListener(
      "message",
      (event) => {
        if (event.data.name === "send" || event.data.success) {
          if (this.props.show) {
            this.props.onClose(true);
          }
        }
      },
      false
    );
  }

  render() {
    if (this.props.show) {
      return (
        <FullModal show={true} onClose={() => this.props.onClose(false)}>
          <iframe
            title="View WindTransfer"
            src={this.props.url}
            style={{
              border: "none",
              height: "calc(100% - 40px)",
              width: "100%",
            }}
          />
        </FullModal>
      );
    } else {
      return null;
    }
  }
}
