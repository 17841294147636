import React from "react";
import { FullModal } from "ui-kit-ck-consultant";

export default class IframeXpertVisio extends React.Component {
  render() {
    if (this.props.show) {
      return (
        <FullModal show={true} onClose={this.props.onClose}>
          <iframe
            title="View XpertVisio"
            src={this.props.url}
            style={{
              border: "none",
              height: "calc(100% - 40px)",
              width: "100%",
            }}
          />
        </FullModal>
      );
    } else {
      return null;
    }
  }
}
