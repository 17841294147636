import React from "react";
import { Redirect } from "react-router-dom";

export default class AuthLayout extends React.Component {
  render() {
    if (this.props.connected === true) {
        return <Redirect to="/home" />;
    } else {
      return (
        <div className="Auth">
          <div className="Auth-container">
            {this.props.children}
          </div>
        </div>
      );
    }
  }
} 