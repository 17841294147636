import React, { createRef } from "react";
import { Card, AlertElement, Modal, FormInput } from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes, faSpinner } from "@fortawesome/free-solid-svg-icons";
import SignatureCanvas from "react-signature-canvas";

import reemploi from "../../../images/reemploi-2.svg";
import leaf1 from "../../../images/leaf-1.svg";

import Button from "../../other/Button";

import video_generali from "../../../videos/VIDEO_PRE_GENERALI.mp4";

import AuthContext from "../../../context/AuthContext";

export default class PRE extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalInfo: false,
      isModalSignature: false,
      valuePRE: 0,
      name: "",
    };

    this.signatureRef = createRef();
  }

  static contextType = AuthContext;

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  onAddSignature = () => {
    this.setState({ isModalSignature: false });
  };

  onRemoveSignature = () => {
    this.setState({ isModalSignature: false });
  };

  render() {
    return (
      <>
        <Card
          title="Pièces de réemploi"
          className="h-100"
          classNameChildren="d-flex flex-column h-100"
          imgLeft={
            !this.props.isSmall && this.context.principal.companyId !== 16
              ? reemploi
              : null
          }
          customWidthImgLeft={
            this.context.principal.companyId === 16 ||
            this.context.principal.companyId === 3
              ? "480px"
              : "380px"
          }
        >
          <Row>
            {this.context.principal.companyId === 16 ? (
              <Col xs={12} md={12} lg={3}>
                <video
                  style={{ width: "100%", borderRadius: "8px" }}
                  className="mb-10"
                  controls
                >
                  <source src={video_generali} type="video/mp4" />
                </video>
              </Col>
            ) : null}
            {this.context.principal.companyId === 3 ? (
              <iframe
                style={{ width: "100%", maxWidth: 280 }}
                src="https://www.youtube.com/embed/jBOj_zV0TVM?si=0WwuqQAtRzUv7lHt"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            ) : null}
            <Col
              xs={12}
              md={12}
              lg={this.context.principal.companyId === 16 ? 9 : 12}
            >
              {this.context.source === "client" ? (
                <p className="mt-0 mb-10">
                  Soucieux(se) du respect de l'environnement, j'autorise le
                  montage de pièces issues de l'économie circulaire pour la
                  réparation de mon véhicule.
                </p>
              ) : (
                <p className="mt-0 mb-10">
                  Soucieux(se) du respect de l'environnement, le client autorise
                  le montage de pièces issues de l'économie circulaire pour la
                  réparation de son véhicule.
                </p>
              )}
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  if (this.context.principal.companyId === 16) {
                    window
                      .open(
                        "https://www.generali.fr/actu/piec-piece-reemploi-auto-definition/",
                        "_blank"
                      )
                      .focus();
                  } else {
                    this.setState({ isModalInfo: true });
                  }
                }}
                target="_blank"
                rel="noreferrer"
                className="mb-10 text-left"
                style={{ display: "inline-block" }}
              >
                En savoir plus
              </a>
            </Col>
          </Row>
          <Row className="mt-auto">
            {this.context.source === "client" && this.props.dataPRE === null ? (
              <>
                <Col md={8} className="mt-10">
                  <div className="d-flex mb-5" style={{ color: "#4FBC9D" }}>
                    <img height={16} className="mt-auto mb-auto" src={leaf1} />
                    <p
                      className="mt-auto mb-auto ml-10"
                      style={{ fontSize: "14px" }}
                    >
                      Potentielle réduction de <b>20 kg</b> d’émissions de CO2
                      en acceptant la PRE.
                    </p>
                  </div>
                  <Button
                    className="button-carbon"
                    onClick={() =>
                      this.setState({ isModalSignature: true, valuePRE: 1 })
                    }
                    text="Accepter"
                  />
                </Col>
                <Col md={4} className="mt-10 d-flex">
                  <Button
                    className="mt-auto button-outline-red"
                    onClick={() =>
                      this.setState({ isModalSignature: true, valuePRE: 2 })
                    }
                    text="Refuser"
                    outline
                  />
                </Col>
              </>
            ) : (
              <Col xs={12}>
                <AlertElement
                  bold
                  backgroundColor={`bg-${
                    this.props.dataPRE === null
                      ? "orange"
                      : this.props.dataPRE === 1
                      ? "green"
                      : "red"
                  }-light`}
                  textColor={
                    this.props.dataPRE === null
                      ? "orange"
                      : this.props.dataPRE === 1
                      ? "green"
                      : "red"
                  }
                  icon={
                    <FontAwesomeIcon
                      className={this.props.dataPRE === null ? "rotate" : ""}
                      icon={
                        this.props.dataPRE === null
                          ? faSpinner
                          : this.props.dataPRE === 1
                          ? faCheck
                          : faTimes
                      }
                    />
                  }
                >
                  {this.props.dataPRE === null
                    ? "En attente de réponse"
                    : this.props.dataPRE === 1
                    ? "Accepté"
                    : "Refusé"}
                </AlertElement>
              </Col>
            )}
            {this.context.source === "client" && this.props.dataPRE !== null ? (
              <Col xs={12}>
                <a
                  href="/"
                  className="mt-10 align-center"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.updatePRE(null);
                  }}
                  style={{ fontSize: "13px", textAlign: "right" }}
                >
                  Modifier mon choix
                </a>
              </Col>
            ) : null}
          </Row>
        </Card>
        <Modal
          large
          className="m-auto"
          show={this.state.isModalInfo}
          onClose={() => this.setState({ isModalInfo: false })}
          title="Economie circulaire : le réemploi"
        >
          <h2
            style={{
              color: "var(--primary-color)",
              textTransform: "uppercase",
            }}
          >
            Et si on recyclait ?
          </h2>

          <h3
            style={{
              color: "var(--primary-color)",
              textTransform: "uppercase",
            }}
          >
            Le réemploi, qu'est-ce que c'est ?
          </h3>
          <p>
            Une pièce de réemploi (PRE), est un composant issu d’un véhicule
            hors d’usage, provenant d’un centre agréé par l’Etat. Elles sont
            garanties conformes par des professionnels et encadrées par la
            réglementation européenne.
          </p>
          <p>
            La filière automobile fait partie des secteurs les plus en avance
            dans les engagements RSE, avec une profession liée au recyclage des
            carcasses et des pièces détachées automobile. Aujourd'hui, nous
            sommes capables de recycler plus de 95% d'un véhicule. La
            décomposition se fait en 3 étapes :
          </p>
          <ol>
            <li>La dépollution,</li>
            <li>L'expertise et le démontage des pièces,</li>
            <li>Le tri des matières (plastique, métal, tissu).</li>
          </ol>
          <p>
            C'est après ces étapes que la carcasse est broyée. Les PRE
            représentent 5% du marché de la réparation automobile en 2020.
          </p>

          <h3
            style={{
              color: "var(--primary-color)",
              textTransform: "uppercase",
            }}
          >
            Pour quelles pièces ?
          </h3>
          <ul>
            <li>Pièces de carrosserie amovibles</li>
            <li>Pièces de garnissage intérieur et sellerie</li>
            <li>Pièces d’optique</li>
            <li>
              Pièces mécaniques ou électroniques (sauf celles faisant partie des
              éléments de sécurité : trains roulants, freinages...)
            </li>
          </ul>

          <h3
            style={{
              color: "var(--primary-color)",
              textTransform: "uppercase",
            }}
          >
            Pourquoi le favoriser ?
          </h3>
          <h5
            style={{
              color: "var(--secondary-color)",
              textTransform: "uppercase",
              margin: "16px 0",
            }}
          >
            Protéger l'environnement
          </h5>
          <p>
            Choisir une pièce de réemploi, c’est favoriser une économie
            circulaire et ainsi protéger l’environnement.
          </p>
          <h5
            style={{
              color: "var(--secondary-color)",
              textTransform: "uppercase",
              margin: "16px 0",
            }}
          >
            Soutenir l'économie locale
          </h5>
          <p>
            Réutiliser une pièce, c’est soutenir l’emploi local avec une pièce
            provenant uniquement d’un centre Français.
          </p>
          <h5
            style={{
              color: "var(--secondary-color)",
              textTransform: "uppercase",
              margin: "16px 0",
            }}
          >
            Economiser
          </h5>
          <p>
            Opter pour des PRE peut générer jusqu'à 70% d'économies sur le prix
            d'une pièce neuve et participe à réduire les déchets issus de
            l'automobile en favorisant le recyclage, le tout en conservant la
            valeur de son véhicule.
          </p>

          <h3
            style={{
              color: "var(--primary-color)",
              textTransform: "uppercase",
            }}
          >
            Quelles garanties ?
          </h3>
          <p>
            Les pièces proviennent des centres VHU (véhicules hors d'usage)
            agréés et contrôlés. Elles sont reconditionnées conformément aux
            normes du constructeur assurant ainsi une qualité originelle, une
            traçabilité et une sécurité.
          </p>
        </Modal>
        <Modal
          title="Réponse PRE"
          show={this.state.isModalSignature}
          onClose={() => this.setState({ isModalSignature: false })}
        >
          <FormInput
            title="Nom"
            name="name"
            value={this.state.name}
            onChange={this.handleInputChange}
          />
          <b className="mt-10">Signature</b>
          <SignatureCanvas
            ref={this.signatureRef}
            penColor="black"
            canvasProps={{
              style: {
                border: "var(--gray) dashed 2px",
                borderRadius: "15px",
              },
              className: "sigCanvas mt-5 mb-10",
            }}
          />
          <div className="d-flex">
            <Button
              outline
              className="w-auto ml-auto"
              text="Rénitialiser"
              onClick={() => this.signatureRef.current.clear()}
            />
            <Button
              className="w-auto ml-10"
              text="Valider"
              onClick={() => {
                let image = this.signatureRef.current
                  .getTrimmedCanvas()
                  .toDataURL("image/png");

                this.setState({ isModalSignature: false }, () => {
                  this.props.updatePRE(
                    this.state.valuePRE,
                    this.state.name,
                    image
                  );
                });
              }}
            />
          </div>
        </Modal>
      </>
    );
  }
}
