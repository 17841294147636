import React from "react";
import { Redirect } from "react-router-dom";
import { Error404 } from "ui-kit-ck-consultant";

// Layout Types
import AuthLayout from "./layouts/Auth";
import AuthSpecialV2Layout from "./layouts/AuthSpecialV2";
import DashboardLayout from "./layouts/Dashboard";
import GeneralLayout from "./layouts/General";
import OtherLayout from "./layouts/Other";

// Route Views
import Signin from "./views/auth/Signin";
import Forgot from "./views/auth/Forgot";
import Password from "./views/auth/Password";
import NewAccount from "./views/auth/NewAccount";
import Home from "./views/dashboard/Home";
import Setting from "./views/dashboard/Setting";
import Folder from "./views/dashboard/Folder";
import Reset from "./views/general/Reset";
import ExternalElectronicSignature from "./views/other/ExternalElectronicSignature";
import ElectronicSignature from "./views/other/ElectronicSignature";
import SigninAxa from "./views/auth/SigninAxa";
import FolderAXA from "./views/dashboard/FolderAXA";

const routes = [
  {
    path: "/",
    exact: true,
    layout: DashboardLayout,
    component: () => <Redirect to="/home" />,
  },
  {
    path: "/setting",
    exact: true,
    layout: DashboardLayout,
    component: Setting,
  },
  {
    path: "/home",
    exact: true,
    layout: DashboardLayout,
    component: Home,
  },
  {
    path: "/folder/:token",
    exact: true,
    layout: DashboardLayout,
    component: Folder,
  },
  {
    path: "/folder/:token/:message",
    exact: true,
    layout: DashboardLayout,
    component: Folder,
  },
  {
    path: "/signin",
    exact: true,
    layout: AuthSpecialV2Layout,
    component: Signin,
  },
  {
    path: "/signin/:redirection",
    exact: true,
    layout: AuthSpecialV2Layout,
    component: Signin,
  },
  {
    path: "/signin/:redirection/:message",
    exact: true,
    layout: AuthSpecialV2Layout,
    component: Signin,
  },
  {
    path: "/forgot",
    exact: true,
    layout: AuthLayout,
    component: Forgot,
  },
  {
    path: "/password/:token",
    exact: true,
    layout: AuthLayout,
    component: Password,
  },
  {
    path: "/new-account/:type/:token",
    exact: true,
    layout: AuthSpecialV2Layout,
    component: NewAccount,
  },
  {
    path: "/new-account/:type/:token/:redirection",
    exact: true,
    layout: AuthSpecialV2Layout,
    component: NewAccount,
  },
  {
    path: "/new-account/:type/:token/:redirection/:message",
    exact: true,
    layout: AuthSpecialV2Layout,
    component: NewAccount,
  },
  {
    path: "/reset/:token",
    exact: true,
    layout: GeneralLayout,
    component: Reset,
  },
  {
    path: "/electronic-signature/:token",
    exact: true,
    layout: OtherLayout,
    component: ExternalElectronicSignature,
  },
  {
    path: "/electronic-signature/:token/:id",
    exact: true,
    layout: OtherLayout,
    component: ElectronicSignature,
  },
  {
    path: "/axa",
    exact: true,
    layout: AuthSpecialV2Layout,
    component: SigninAxa,
  },
  {
    path: "/axa/:reference/:licensePlate",
    exact: true,
    layout: DashboardLayout,
    component: FolderAXA,
  },
  {
    layout: Error404,
    component: null,
  },
];

export default routes;
