import React from "react";
import moment from "moment";
import {
  Card,
  Table,
  Tbody,
  Thead,
  Tr,
  Th,
  Td,
  ButtonIcon,
  Modal,
  Pagination,
  Loader,
  Status,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPlus,
  faBell,
  faReply,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
  faTrash,
  faDownload,
  faCamera,
} from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "react-flexbox-grid";

import { getMessage, disableMessage } from "../../../requests/folder";

import { getFileUrl, getIconFile } from "../../../utils/general";

import Button from "../../other/Button";

import AuthContext from "../../../context/AuthContext";

export default class Message extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      isModalMessage: false,
      messageSubject: null,
      messageFromType: null,
      messageFrom: null,
      messageTo: null,
      messageContent: null,
      messageToken: "",
      activePageMessage: 1,
      activePageDocument: 1,
      pageLength: 10,
    };
  }

  static contextType = AuthContext;

  componentDidMount() {
    if (
      this.props &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.message
    ) {
      this.setState({
        isLoad: true,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.dataMessages.length &&
      JSON.stringify(prevProps.dataMessages) !==
        JSON.stringify(this.props.dataMessages) &&
      this.props.match.params.message
    ) {
      this.props.dataMessages.forEach((element, idx) => {
        if (element.token === this.props.match.params.message) {
          if (
            element.isCATNAT &&
            this.props.dataExpertiseCatnatPage &&
            !this.props.dataExpertiseCatnatPage.includes(10)
          ) {
            this.setState(
              {
                isLoad: false,
              },
              () => {
                this.props.openModalCatnat();
              }
            );
          } else {
            this.getMessage(idx);
          }
        }
      });
    }
  }

  getMessage(idx) {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        getMessage(
          {
            folderId: this.context.id,
            messageId: this.props.dataMessages[idx].id,
          },
          (result) => {
            if (result.success) {
              if (["client", "garage"].includes(this.context.source)) {
                this.props.readMessage(idx);
              }

              this.setState({
                isLoad: false,
                isModalMessage: true,
                messageSubject: this.props.dataMessages[idx].typeName
                  ? this.props.dataMessages[idx].typeName
                  : this.props.dataMessages[idx].name,
                messageFromType: this.props.dataMessages[idx].fromType,
                messageFrom: this.props.dataMessages[idx].fromName,
                messageTo: this.props.dataMessages[idx].toName,
                messageContent: result.data.content,
                messageToken: result.data.token,
              });
            } else {
              this.setState(
                {
                  isLoad: false,
                },
                () => {
                  alert("Une erreur est survenue");
                }
              );
            }
          }
        );
      }
    );
  }

  disableMessage(id) {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce message ?")) {
      disableMessage({ folderId: this.context.id, messageId: id }, (result) => {
        if (result.success) {
          this.props.getMessages();
          this.props.getFiles();
        } else {
          alert("Une erreur est survenue");
        }
      });
    }
  }

  checkRead(data) {
    if (this.context.source === "client" && data.readClient === 0) {
      return "bg-red-light";
    } else if (this.context.source === "garage" && data.readGarage === 0) {
      return "bg-red-light";
    } else {
      return "";
    }
  }

  getComponentDocument = () => {
    return (
      <>
        <Table
          empty={!this.context.dataDocuments.length ? "Aucun document" : ""}
        >
          <Thead>
            <Tr>
              {!this.props.isSmall ? <Th></Th> : null}
              <Th className={`${this.props.isSmall ? "pl-10 pr-10" : ""}`}>
                Date
              </Th>
              <Th className={`${this.props.isSmall ? "pl-10 pr-10" : ""}`}>
                Nom
              </Th>
              <Th className={`${this.props.isSmall ? "pl-10 pr-10" : ""}`}>
                Actions
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {this.context.dataDocuments
              .filter(
                (document) =>
                  !document.filename.startsWith("pixtransfer-") &&
                  !document.filename.startsWith("État descriptif") &&
                  !document.filename.startsWith("Étude de marché") &&
                  !document.filename.startsWith("Synthèse") &&
                  !document.filename.startsWith("Courrier_maileva") &&
                  !document.filename.startsWith("preuve_de") &&
                  !document.filename.startsWith("RAPPORT-D_INFORMATION") &&
                  !document.filename.startsWith("PV-D_EXPERTISE")
              )
              .slice(
                (this.state.activePageDocument - 1) * this.state.pageLength,
                this.state.activePageDocument * this.state.pageLength
              )
              .map((document, idx) => (
                <Tr key={idx}>
                  {!this.props.isSmall ? (
                    <Td className="nowrap">
                      <FontAwesomeIcon
                        className={getIconFile(document.extension)[1]}
                        icon={getIconFile(document.extension)[0]}
                      />
                    </Td>
                  ) : null}
                  <Td
                    className={`${
                      this.props.isSmall ? "pl-10 pr-10" : ""
                    } nowrap`}
                  >
                    {document.date
                      ? moment(document.date).format("DD/MM/YYYY")
                      : ""}
                  </Td>
                  <Td
                    className={`${
                      this.props.isSmall ? "pl-10 pr-10" : ""
                    } w-100`}
                  >
                    {document.filename}
                  </Td>
                  <Td
                    className={`${
                      this.props.isSmall ? "pl-10 pr-10" : "nowrap"
                    }`}
                  >
                    <div className="d-flex">
                      <ButtonIcon
                        small
                        className="blue"
                        onClick={() =>
                          window.open(
                            getFileUrl(
                              document.folderId,
                              document.token,
                              document.source
                            ),
                            "_blank"
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </ButtonIcon>
                      <ButtonIcon
                        small
                        className="blue"
                        onClick={() => {
                          if (
                            ["client", "garage"].includes(this.context.source)
                          ) {
                            this.props.readDocument(idx);
                          }

                          const a = window.document.createElement("a");
                          a.href = getFileUrl(
                            document.folderId,
                            document.token,
                            document.source
                          );
                          a.download = `${document.filename}.${document.extension}`;
                          window.document.body.appendChild(a);
                          a.click();
                          window.document.body.removeChild(a);
                        }}
                      >
                        <FontAwesomeIcon icon={faDownload} />
                      </ButtonIcon>
                      {this.context.source === "agency" ? (
                        <ButtonIcon
                          small
                          className="red"
                          onClick={() =>
                            window.confirm(
                              "Êtes-vous sûr de vouloir supprimer ce document ?"
                            )
                              ? this.context.hideFile(document.token)
                              : null
                          }
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </ButtonIcon>
                      ) : null}
                    </div>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
        {this.context.dataDocuments.length > this.state.pageLength ? (
          <Pagination
            activePage={this.state.activePageDocument}
            itemsCountPerPage={this.state.pageLength}
            totalItemsCount={this.context.dataDocuments.length}
            pageRangeDisplayed={4}
            onChange={(activePageDocument) => {
              this.setState({ activePageDocument: activePageDocument });
            }}
            firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
            lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
            prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
            nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
            hideNavigation={true}
            hideFirstLastPages={true}
          />
        ) : null}
      </>
    );
  };

  getComponentWindtransfer = (isTitle = true) => {
    const getStatusWindtransfer = (status) => {
      if (status === 0) {
        return <Status text="Envoyé" className="m-auto bg-blue-light blue" />;
      } else if (status === 1) {
        return (
          <Status text="Ouvert" className="m-auto bg-orange-light orange" />
        );
      } else if (status === 2) {
        return <Status text="Retourné" className="m-auto bg-red-light red" />;
      } else if (status === 3) {
        return (
          <Status text="Réintégré" className="m-auto bg-green-light green" />
        );
      } else {
        return "";
      }
    };

    return (
      <>
        {isTitle ? <h3>Mes dossiers WindTransfer</h3> : null}
        <Table>
          <Thead>
            <Tr>
              <Th className={`${this.props.isSmall ? "pl-10 pr-10" : ""}`}>
                Nom
              </Th>
              <Th className={`${this.props.isSmall ? "pl-10 pr-10" : ""}`}>
                Date envoi
              </Th>
              <Th className={`${this.props.isSmall ? "pl-10 pr-10" : ""}`}>
                Date réception
              </Th>
              <Th className={`${this.props.isSmall ? "pl-10 pr-10" : ""}`}>
                État
              </Th>
              <Th className={`${this.props.isSmall ? "pl-10 pr-10" : ""}`}>
                Actions
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {this.context.windtransfer.map((line, idx) => (
              <Tr key={idx}>
                <Td
                  className={`${
                    this.props.isSmall ? "pl-10 pr-10" : ""
                  } nowrap`}
                >
                  {line.name}
                </Td>
                <Td
                  className={`${
                    this.props.isSmall ? "pl-10 pr-10" : ""
                  } nowrap`}
                >
                  {line.sending_date
                    ? moment(line.sending_date).format("DD/MM/YYYY HH:mm")
                    : ""}
                </Td>
                <Td
                  className={`${
                    this.props.isSmall ? "pl-10 pr-10" : ""
                  } nowrap`}
                >
                  {line.receipt_date
                    ? moment(line.receipt_date).format("DD/MM/YYYY HH:mm")
                    : ""}
                </Td>
                <Td
                  className={`text-center ${
                    this.props.isSmall ? "pl-10 pr-10" : ""
                  } nowrap`}
                >
                  {getStatusWindtransfer(line.status)}
                </Td>
                <Td
                  className={`text-center ${
                    this.props.isSmall ? "pl-10 pr-10" : ""
                  } nowrap`}
                >
                  {line.status < 2 ? (
                    <Button
                      small
                      text="Ouvrir le dossier"
                      onClick={() => {
                        window.open(
                          `https://app.windtransfer.net/?ID=${line["id"]}`,
                          "_blank"
                        );
                      }}
                    />
                  ) : (
                    "Nous avons bien réceptionné le dossier"
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </>
    );
  };

  render() {
    const dataMessages = this.props.dataMessages
      .filter(
        (message) =>
          message.name !== "Document Centralisé" ||
          this.context.dataDocuments
            .filter(
              (document) =>
                !document.filename.startsWith("pixtransfer-") &&
                !document.filename.startsWith("État descriptif") &&
                !document.filename.startsWith("Étude de marché") &&
                !document.filename.startsWith("Synthèse") &&
                !document.filename.startsWith("Courrier_maileva") &&
                !document.filename.startsWith("preuve_de") &&
                !document.filename.startsWith("RAPPORT-D_INFORMATION") &&
                !document.filename.startsWith("PV-D_EXPERTISE")
            )
            .filter((document) => {
              if (!message.token) {
                return false;
              }

              return (
                message.token
                  .split(",")
                  .includes((document.externalId || "").toString()) ||
                message.token
                  .split(",")
                  .includes((document.externalIdLacour || "").toString()) ||
                message.token.split(",").includes(document.token)
              );
            }).length
      )
      .map((message) => {
        if (message.name === "Document Centralisé") {
          let files = [];

          this.context.dataDocuments
            .filter(
              (document) =>
                !document.filename.startsWith("pixtransfer-") &&
                !document.filename.startsWith("État descriptif") &&
                !document.filename.startsWith("Étude de marché") &&
                !document.filename.startsWith("Synthèse") &&
                !document.filename.startsWith("Courrier_maileva") &&
                !document.filename.startsWith("preuve_de") &&
                !document.filename.startsWith("RAPPORT-D_INFORMATION") &&
                !document.filename.startsWith("PV-D_EXPERTISE")
            )
            .forEach((document) => {
              if (!message.token) {
                return false;
              }

              if (
                message.token
                  .split(",")
                  .includes((document.externalId || "").toString()) ||
                message.token
                  .split(",")
                  .includes((document.externalIdLacour || "").toString()) ||
                message.token.split(",").includes(document.token)
              ) {
                files.push(document.filename);
              }
            });

          message.name = `Document Centralisé - ${files.join(" / ")}`;
        }

        return message;
      });

    return (
      <>
        <Card
          title="Messages"
          classNameChildren="overflow-x-auto"
          disableStyleAction={true}
          isLoad={
            this.props.isLoadMessage ||
            (!this.props.isSmall && this.props.isLoadDocument)
          }
          action={
            !this.context.isLimit ? (
              !this.props.isSmall ? (
                <Row>
                  <Col
                    xs={12}
                    md={this.context.source !== "garage" ? 6 : 12}
                    className="px-5"
                  >
                    <Button
                      text="Nouveau"
                      onClick={this.context.handleOpenModalMessage}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </Col>
                  {this.context.source !== "garage" ? (
                    <Col xs={12} md={6} className="px-5">
                      <Button
                        text="Réclamation"
                        outline
                        onClick={() =>
                          this.context.handleOpenModalMessage("claim", "agency")
                        }
                      >
                        <FontAwesomeIcon icon={faBell} />
                      </Button>
                    </Col>
                  ) : null}
                </Row>
              ) : null
            ) : null
          }
        >
          {this.props.isSmall ? (
            <div className="d-flex">
              <Button
                className="w-auto ml-auto mb-20"
                text="Nouveau"
                onClick={this.context.handleOpenModalMessage}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
              {this.context.source !== "garage" ? (
                <Button
                  className="w-auto ml-10 mb-20"
                  text="Réclamation"
                  outline
                  onClick={() =>
                    this.context.handleOpenModalMessage("claim", "agency")
                  }
                >
                  <FontAwesomeIcon icon={faBell} />
                </Button>
              ) : null}
            </div>
          ) : null}
          {!this.context.dateClosing &&
          !this.context.dataDocuments.filter(
            (document) =>
              document.filename &&
              (document.filename.includes("PV-") ||
                document.filename.includes("RAPPORT EXPERTISE") ||
                document.filename.includes("PROCES VERBAL"))
          ).length ? (
            <>
              {"client" === this.context.source ? (
                <div className="d-flex">
                  <Button
                    className="w-auto mb-10 ml-auto"
                    text="PHOTOS DES DOMMAGES"
                    onClick={() => {
                      this.props.alertRef.getSelfcareWindtransfer("message");
                    }}
                  >
                    <FontAwesomeIcon icon={faCamera} />
                  </Button>
                </div>
              ) : null}
              {"garage" === this.context.source &&
              this.context.principal.companyId !== 3 ? (
                <div className="d-flex">
                  <Button
                    className="w-auto mb-10 ml-auto"
                    text="PHOTOS DES DOMMAGES"
                    onClick={() => {
                      this.props.alertRef.getSelfcareWindtransfer("message");
                    }}
                  >
                    <FontAwesomeIcon icon={faCamera} />
                  </Button>
                </div>
              ) : null}
            </>
          ) : null}
          {this.context.source === "garage" ? (
            <div className="d-flex">
              <Button
                className="w-auto mb-10 ml-auto"
                text="Dommages Supplémentaires"
                onClick={() =>
                  this.context.handleOpenModalMessage("more", "agency")
                }
              >
                <FontAwesomeIcon icon={faBell} />
              </Button>
            </div>
          ) : null}
          <Table empty={!this.props.dataMessages.length ? "Aucun message" : ""}>
            <Thead>
              <Tr>
                <Th className={`${this.props.isSmall ? "pl-10 pr-10" : ""}`}>
                  Date
                </Th>
                {!this.props.isSmall ? <Th>Émetteur</Th> : null}
                {!this.props.isSmall ? <Th>Destinataire(s)</Th> : null}
                {this.props.isSmall ? (
                  <Th className={`${this.props.isSmall ? "pl-10 pr-10" : ""}`}>
                    Objet
                  </Th>
                ) : null}
                {!this.props.isSmall ? <Th>Fichier(s)</Th> : null}
                <Th className={`${this.props.isSmall ? "pl-10 pr-10" : ""}`}>
                  Actions
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {dataMessages
                .slice(
                  (this.state.activePageMessage - 1) * this.state.pageLength,
                  this.state.activePageMessage * this.state.pageLength
                )

                .map((message, idx) => (
                  <Tr key={idx}>
                    <Td
                      className={`${
                        this.props.isSmall ? "pl-10 pr-10" : ""
                      } nowrap ${this.checkRead(message)}`}
                    >
                      {message.date}
                    </Td>
                    {!this.props.isSmall ? (
                      <Td className={`nowrap ${this.checkRead(message)}`}>
                        {message.fromName ? message.fromName : "N.C."}
                      </Td>
                    ) : null}
                    {!this.props.isSmall ? (
                      <Td className={`nowrap ${this.checkRead(message)}`}>
                        {message.toName ? message.toName : "N.C."}
                      </Td>
                    ) : null}
                    <Td
                      className={`${
                        this.props.isSmall ? "pl-10 pr-10" : ""
                      } w-100 ${this.checkRead(message)}`}
                    >
                      {message.typeName ? message.typeName : message.name}
                    </Td>
                    {/* {!this.props.isSmall ? (
                      <Td className={`${this.checkRead(message)}`}>
                        {message.countDocument}
                      </Td>
                    ) : null} */}
                    <Td
                      className={`${
                        this.props.isSmall ? "pl-10 pr-10" : "nowrap"
                      } ${this.checkRead(message)}`}
                    >
                      <div className="d-flex">
                        <ButtonIcon
                          small
                          className="blue"
                          onClick={() =>
                            this.getMessage(
                              (this.state.activePageMessage - 1) *
                                this.state.pageLength +
                                idx
                            )
                          }
                        >
                          <FontAwesomeIcon icon={faEye} />
                        </ButtonIcon>
                        {this.context.source === "agency" ? (
                          <ButtonIcon
                            small
                            className="red"
                            onClick={() => this.disableMessage(message.id)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </ButtonIcon>
                        ) : null}
                      </div>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
          {this.props.dataMessages.length > this.state.pageLength ? (
            <Pagination
              activePage={this.state.activePageMessage}
              itemsCountPerPage={this.state.pageLength}
              totalItemsCount={this.props.dataMessages.length}
              pageRangeDisplayed={4}
              onChange={(activePageMessage) => {
                this.setState({ activePageMessage: activePageMessage });
              }}
              firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
              lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
              prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
              nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
              hideNavigation={true}
              hideFirstLastPages={true}
            />
          ) : null}
          <div className="w-100 mt-20 mb-20" />
          {!this.props.isSmall ? (
            <>
              <h3>Documents</h3>
              {this.getComponentDocument()}
              {this.context.source === "client" &&
              this.context.windtransfer.length
                ? this.getComponentWindtransfer()
                : null}
            </>
          ) : null}
        </Card>
        {this.props.isSmall ? (
          <Card
            className="mt-20"
            title="Documents"
            classNameChildren="overflow-x-auto"
            isLoad={this.props.isLoadDocument}
          >
            {this.getComponentDocument()}
          </Card>
        ) : null}
        {this.props.isSmall &&
        this.context.source === "client" &&
        this.context.windtransfer.length ? (
          <Card
            className="mt-20"
            title="Mes dossiers WindTransfer"
            classNameChildren="overflow-x-auto"
          >
            {this.getComponentWindtransfer(false)}
          </Card>
        ) : null}
        <Modal
          show={this.state.isModalMessage}
          onClose={() =>
            this.setState({
              isModalMessage: false,
              messageSubject: null,
              messageFrom: null,
              messageTo: null,
              messageContent: null,
              messageToken: "",
            })
          }
        >
          <h3 className="m-0" style={{ fontSize: "20px" }}>
            {this.state.messageSubject}
          </h3>
          <h5 className="m-0">
            De :{" "}
            <span style={{ fontWeight: "normal" }}>
              {this.state.messageFrom}
            </span>
          </h5>
          <h5 className="m-0">
            A :{" "}
            <span style={{ fontWeight: "normal" }}>{this.state.messageTo}</span>
          </h5>
          <div
            dangerouslySetInnerHTML={{ __html: this.state.messageContent }}
            className="mt-30 mb-10"
            style={{
              color: "var(--black)",
              fontSize: "15px",
              paddingLeft: "10px",
              borderLeft: "solid 2px var(--primary-color)",
            }}
          />
          <div className="d-flex flex-column">
            {this.context.dataDocuments
              .filter(
                (document) =>
                  !document.filename.startsWith("pixtransfer-") &&
                  !document.filename.startsWith("État descriptif") &&
                  !document.filename.startsWith("Étude de marché") &&
                  !document.filename.startsWith("Synthèse") &&
                  !document.filename.startsWith("Courrier_maileva") &&
                  !document.filename.startsWith("preuve_de") &&
                  !document.filename.startsWith("RAPPORT-D_INFORMATION") &&
                  !document.filename.startsWith("PV-D_EXPERTISE")
              )
              .filter((document) => {
                if (!this.state.messageToken) {
                  return false;
                }

                return (
                  this.state.messageToken
                    .split(",")
                    .includes((document.externalId || "").toString()) ||
                  this.state.messageToken
                    .split(",")
                    .includes((document.externalIdLacour || "").toString()) ||
                  this.state.messageToken.split(",").includes(document.token)
                );
              })
              .map((document, idx) => (
                <Button
                  key={idx}
                  text={document.filename}
                  className="mt-10 w-auto mr-auto"
                  onClick={() =>
                    window.open(
                      getFileUrl(
                        document.folderId,
                        document.token,
                        document.source
                      ),
                      "_blank"
                    )
                  }
                >
                  <FontAwesomeIcon
                    className={getIconFile(document.extension)[1]}
                    icon={getIconFile(document.extension)[0]}
                  />
                </Button>
              ))}
            {this.state.messageFromType === this.context.source ||
            (this.state.messageFromType === "agency" &&
              this.context.source === "agency") ? null : (
              <Button
                className="mt-10 ml-auto w-auto"
                outline
                text="Répondre"
                onClick={() =>
                  this.setState({ isModalMessage: false }, () => {
                    if (!this.props.isAlert) {
                      this.context.handleOpenModalMessage(
                        "",
                        this.state.messageFromType
                      );
                    }
                  })
                }
              >
                <FontAwesomeIcon icon={faReply} />
              </Button>
            )}
          </div>
        </Modal>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
