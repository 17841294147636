import React from "react";
import { FormInput, Loader, FormSelect } from "ui-kit-ck-consultant";
import { Link } from "react-router-dom";

import logo from "../../images/sigle-ixperience.svg";

import Button from "../../components/other/Button";

import { forgot } from "../../requests/auth";

export default class Forgot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      type: "",
      success: false,
      isError: false,
      isLoad: false,
      isMultiple: false,
    };
  }

  handleForgotClick = (event) => {
    event.preventDefault();
    if (this.state.email) {
      this.setState({ isLoad: true }, () => {
        forgot({ email: this.state.email, type: this.state.type }, (result) => {
          if (result.success) {
            this.setState({ success: true, url: result.url, isLoad: false });
          } else {
            this.setState({
              isError: true,
              isMultiple: this.state.isMultiple || result.isMultiple,
              isLoad: false,
            });
          }
        });
      });
    } else {
      this.setState({ isError: true });
    }
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      isError: false,
    });
  };

  render() {
    return (
      <>
        <div>
          <img
            alt="logo"
            className="d-block ml-auto mr-auto mb-20"
            style={{ maxWidth: "100%" }}
            height="60"
            src={logo}
          />

          <h4 className="pt-20 text-center">Mot de passe oublié</h4>

          {!this.state.success ? (
            <>
              <FormInput
                className="mb-20 pt-20"
                title="Adresse email"
                placeholder="exemple@mail.com"
                name="email"
                type="text"
                value={this.state.email}
                onChange={this.handleInputChange}
                required
                error={
                  this.state.isError && !this.state.isMultiple
                    ? "Adresse email incorrecte"
                    : ""
                }
              />
              {this.state.isMultiple ? (
                <FormSelect
                  className="mb-10"
                  name={"type"}
                  value={this.state.type}
                  onChange={this.handleInputChange}
                  title={"Type de compte"}
                  required
                  error={
                    "Veuillez sélectionner le type de compte que vous souhaitez utiliser"
                  }
                  options={[
                    {
                      value: "DEFAULT",
                      text: "Veuillez sélectionner un type de compte",
                      disabled: true,
                    },
                    {
                      value: "client",
                      text: "Assuré",
                    },
                    {
                      value: "garage",
                      text: "Réparateur",
                    },
                    {
                      value: "agency",
                      text: "Expert",
                    },
                    {
                      value: "company",
                      text: "Assureur",
                    },
                    {
                      value: "principal",
                      text: "Mandant",
                    },
                  ]}
                />
              ) : null}

              <Button
                className="mb-20"
                text="Créer un nouveau mot de passe"
                onClick={this.handleForgotClick}
              />
            </>
          ) : (
            <>
              <p className="my-20 text-center">
                Un lien de réinitialisation a été envoyé à votre adresse email.
              </p>
              <p style={{ overflowWrap: "break-word" }}>{this.state.url}</p>
            </>
          )}

          <Link
            to={{ pathname: "/signin" }}
            style={{ color: "var(--primary-color)" }}
          >
            Connexion
          </Link>
        </div>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
