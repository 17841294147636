import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import AuthContext from "../../context/AuthContext";

export default class ContactLink extends React.Component {
  static contextType = AuthContext;

  onClick = (e) => {
    e.preventDefault();
    if (this.props.toId && this.props.toSource !== this.context.source) {
      this.context.handleOpenModalMessage(
        this.props.type ? this.props.type : "contact",
        this.props.toSource,
        this.props.callback ? this.props.callback : () => {}
      );
    }
  };

  render() {
    return (
      <a
        href="/"
        className={
          !this.props.toId || this.props.toSource === this.context.source
            ? "gray"
            : ""
        }
        style={{
          display: "inline-block",
          textAlign: "left",
          cursor:
            !this.props.toId || this.props.toSource === this.context.source
              ? "not-allowed"
              : "",
        }}
        onClick={this.onClick}
      >
        <FontAwesomeIcon icon={faEnvelope} />{" "}
        {this.props.text ? this.props.text : "Contacter"}
      </a>
    );
  }
}
