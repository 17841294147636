import React from "react";
import { Nav, Loader } from "ui-kit-ck-consultant";

import Monitoring from "../../components/folder/monitoring/Monitoring";

import logoAXA from "../../images/logo-axa.svg";

import AuthContext from "../../context/AuthContext";

import {
  getTimeLine,
  getFiles,
  hideFile,
  getPRE,
  getDamageAnalysis,
  getMessages,
} from "../../requests/folder";

export default class FolderAXA extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Tabs
      tabsMenu: ["Suivi du dossier", "Informations administratives"],
      tabsIndex: 0,
      // Data
      dataTimeLine: [],
      dataExpertiseForm: {},
      dataExpertiseFormPage: [],
      dataExpertiseCatnat: {},
      dataExpertiseCatnatPage: [],
      dataPictures: [],
      dataMessages: [],
      dataDocuments: [],
      dataPRE: null,
      dataDamageAnalysis: {},
      // Data folder
      sinister: {},
      countFolder: 0,
      // Modal message
      isModalMessage: false,
      modalMessageType: "",
      modalMessageTo: "",
      modalMessageCallback: null,
      // Loader
      isLoad: false,
      isSmall: false,
      isAlert: false,
      isLoadMessage: false,
      isLoadDocument: false,
      token: null,
      projectE: null,
      windtransfer: [],
    };
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.getTimeLine();
    this.getPRE();
    this.getDamageAnalysis();
    this.getMessages();
    this.getFiles();

    this.getSize();
    this.resizeEvent = window.addEventListener("resize", () => {
      this.getSize();
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeEvent);
  }

  getSize = () => {
    let isSmall = window.innerWidth < 1000 ? true : false;
    this.setState({ isSmall });
  };

  getTimeLine = () => {
    getTimeLine({ folderId: this.context.id }, (result) => {
      if (result.success) {
        this.setState({ dataTimeLine: result.data });
      } else {
        this.setState({ dataTimeLine: [] });
      }
    });
  };

  getFiles = () => {
    getFiles({ folderId: this.context.id }, (result) => {
      if (result.success) {
        this.setState({
          dataDocuments: result.data,
          dataPictures: result.data.filter(
            (picture) =>
              (["principal", "company", "agency"].includes(
                this.context.source
              ) ||
                (picture.source !== "pixtransfer" &&
                  picture.source !== "lacour")) &&
              ["png", "jpg", "jpeg"].includes(picture.path.split(".").pop())
          ),
        });
      }
    });
  };

  hideFile = (token) => {
    hideFile({ folderId: this.context.id, token }, (result) => {
      if (result.success) {
        const dataDocuments = this.state.dataDocuments.filter(
          (element) => element.token !== token
        );
        const dataPictures = this.state.dataPictures.filter(
          (element) => element.token !== token
        );

        this.setState({ dataDocuments, dataPictures });
      } else {
        alert("Une erreur est survenue");
      }
    });
  };

  getPRE = () => {
    getPRE({ folderId: this.context.id }, (result) => {
      if (result.success) {
        this.setState({ dataPRE: result.data });
      } else {
        this.setState({ dataPRE: null });
      }
    });
  };

  getDamageAnalysis = () => {
    getDamageAnalysis({ folderId: this.context.id }, (result) => {
      if (result.success) {
        this.setState({ dataDamageAnalysis: result.data });
      } else {
        this.setState({ dataDamageAnalysis: {} });
      }
    });
  };

  getMessages = () => {
    this.setState({ isLoadMessage: true }, () => {
      getMessages({ folderId: this.context.id }, (result) => {
        if (result.success) {
          this.setState({ dataMessages: result.data, isLoadMessage: false });
        } else {
          this.setState({ dataMessages: [], isLoadMessage: false });
        }
      });
    });
  };

  render() {
    return (
      <AuthContext.Provider
        value={{
          ...this.context,
          isLimit: true,
          projectE: this.state.projectE,
          windtransfer: this.state.windtransfer,
          dataDocuments: this.state.dataDocuments,
          hideFile: this.hideFile,
        }}
      >
        <Nav
          title={"Bienvenue sur le suivi du dossier"}
          subtitle={`${this.props.match.params.reference} - ${this.props.match.params.licensePlate}`}
          back={false}
          onBack={() => {}}
          className="mb-10 mb-20-xl"
        >
          <img src={logoAXA} height={64} />
        </Nav>
        <Monitoring
          onCloseAlert={() => this.setState({ isAlert: false })}
          isAlert={this.state.isAlert}
          match={this.props.match}
          ref={(ref) => {
            this.monitoringRef = ref;
          }}
          sinister={this.state.sinister}
          getMessages={this.getMessages}
          getFiles={this.getFiles}
          getFolder={this.getFolder}
          dataTimeLine={this.state.dataTimeLine}
          dataExpertiseForm={this.state.dataExpertiseForm}
          dataExpertiseFormPage={this.state.dataExpertiseFormPage}
          dataExpertiseCatnat={this.state.dataExpertiseCatnat}
          dataExpertiseCatnatPage={this.state.dataExpertiseCatnatPage}
          dataPictures={this.state.dataPictures}
          dataPRE={this.state.dataPRE}
          dataDamageAnalysis={this.state.dataDamageAnalysis}
          dataMessages={this.state.dataMessages}
          updateExpertiseSolution={this.updateExpertiseSolution}
          updateCatnat={this.updateCatnat}
          updatePRE={this.updatePRE}
          insertSituationIxperience={this.insertSituationIxperience}
          isSmall={this.state.isSmall}
          readMessage={this.readMessage}
          readDocument={this.readDocument}
          isLoadMessage={this.state.isLoadMessage}
          isLoadDocument={this.state.isLoadDocument}
          alertRef={this.alertRef}
        />
        <Loader isLoad={true} />
      </AuthContext.Provider>
    );
  }
}
