import React, { Fragment } from "react";
import moment from "moment";
import {
  Modal,
  FormRadio,
  FormInput,
  DropzoneMultiple,
} from "ui-kit-ck-consultant";

import Button from "../../other/Button";

import AuthContext from "../../../context/AuthContext";

export default class FormEngine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      history: [0],
      files: [],
    };
  }

  static contextType = AuthContext;

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show && this.props.show) {
      this.setState({
        currentPage: 0,
        history: [0],
        files: [],
      });
    }
  }

  getRadioInput = (name, text) => {
    return (
      <Fragment key={name}>
        <p>
          {text}
          <span className="red"> *</span>
        </p>
        <FormRadio
          text="Oui"
          value={1}
          className="mb-10"
          checked={this.state[name] === 1}
          onChange={({ target }) => {
            this.setState({ [name]: parseInt(target.value) });
          }}
        />
        <FormRadio
          text="Non"
          value={0}
          className="mb-10"
          checked={this.state[name] === 0}
          onChange={({ target }) => {
            this.setState({ [name]: parseInt(target.value) });
          }}
        />
      </Fragment>
    );
  };

  getItem = () => {
    const page = this.state.currentPage;
    const item = this.props.data[page];

    if (item.type === "radio") {
      return this.getRadioInput(`radio-${page}`, item.text);
    } else if (item.type === "text") {
      return <p key={`text-${page}`}>{item.text}</p>;
    } else if (item.type === "document") {
      return (
        <>
          <p key={`text-${page}`}>{item.text}</p>
          <DropzoneMultiple
            ref={(ref) => {
              this.refDropzoneMultiple = ref;
            }}
            className="mb-20"
            extensions={[
              "jpeg",
              "jpg",
              "png",
              "pdf",
              "doc",
              "docx",
              "xls",
              "xlsx",
              "JPEG",
              "JPG",
              "PNG",
              "PDF",
              "DOC",
              "DOCX",
              "XLS",
              "XLSX",
            ]}
            maxFileSize={6}
            maxImageSize={1600}
            onDrop={(files) => this.setState({ files })}
          />
        </>
      );
    } else if (item.type === "date") {
      return (
        <FormInput
          className="w-auto"
          key={`date-${page}`}
          type="date"
          title={item.text}
          value={this.state[`date-${page}`]}
          onChange={({ target }) =>
            this.setState({ [`date-${page}`]: target.value })
          }
        />
      );
    } else {
      return null;
    }
  };

  checkItem = () => {
    const page = this.state.currentPage;
    const item = this.props.data[page];

    if (item.type === "radio") {
      if ([0, 1].includes(this.state[`radio-${page}`])) {
        return item[this.state[`radio-${page}`]];
      } else {
        return false;
      }
    } else if (item.type === "document") {
      return !!this.state.files.length;
    } else if (item.type === "date") {
      if (this.state[`date-${page}`]) {
        return this.state.currentPage + 1;
      } else {
        return false;
      }
    } else if (item.type === "text") {
      return true;
    } else {
      return this.state.currentPage + 1;
    }
  };

  render() {
    if (!this.props.data || !this.props.data.length) {
      return null;
    }

    return (
      <Modal
        title={this.props.title}
        show={this.props.show}
        onClose={this.props.onClose}
      >
        {this.getItem()}
        <div className="d-flex">
          {this.state.currentPage > 0 ? (
            <Button
              className="w-auto ml-auto mt-10 mr-10"
              text="Retour"
              onClick={() => {
                let history = this.state.history;
                history = history.slice(0, -1);
                let currentPage = history[history.length - 1];

                this.setState({
                  [`${this.props.data[this.state.currentPage].type}-${
                    this.state.currentPage
                  }`]: undefined,
                  currentPage,
                  history,
                });
              }}
            />
          ) : null}
          {!["text", "document"].includes(
            this.props.data[this.state.currentPage].type
          ) && this.props.data.length - 1 > this.state.currentPage ? (
            <Button
              className="w-auto ml-auto mt-10 mr-10"
              text="Suivant"
              onClick={() => {
                const result = this.checkItem();
                if (result !== false) {
                  let history = this.state.history;
                  history.push(result);
                  this.setState({ currentPage: result, history });
                } else {
                  alert("Veuillez remplir tous les champs");
                }
              }}
            />
          ) : null}
          {["text", "document"].includes(
            this.props.data[this.state.currentPage].type
          ) || this.props.data.length - 1 === this.state.currentPage ? (
            <Button
              className="w-auto ml-auto mt-10 mr-10"
              text="Terminer"
              onClick={() => {
                const result = this.checkItem();
                if (result !== false) {
                  let dataHTML = "";
                  const files = this.state.files;

                  this.props.data.forEach((element, idx) => {
                    if (
                      element.type === "date" &&
                      this.state[`date-${idx}`] !== undefined
                    ) {
                      dataHTML += `<p>${element.text} ${moment(
                        this.state[`date-${idx}`]
                      ).format("DD/MM/YYYY")}</p>`;
                    } else if (
                      element.type === "radio" &&
                      this.state[`radio-${idx}`] !== undefined
                    ) {
                      dataHTML += `<p>${element.text} ${
                        this.state[`radio-${idx}`] ? "OUI" : "NON"
                      }</p>`;
                    }
                  });

                  this.props.onValid({
                    success: true,
                    files,
                    dataHTML,
                    data: this.state,
                  });
                } else {
                  alert("Veuillez remplir tous les champs");
                }
              }}
            />
          ) : null}
        </div>
      </Modal>
    );
  }
}
