import React from "react";
import moment from "moment";
import { Card, Button } from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import ModalExpertiseSolution from "./expertise-solution/ModalExpertiseSolution";
import ModalCatnat from "./expertise-solution/ModalCatnat";
import ModalAnswerExpertiseSolution from "./expertise-solution/ModalAnswerExpertiseSolution";
import ModalAnswerCatnat from "./expertise-solution/ModalAnswerCatnat";
import IframeXpertVisio from "../../general/IframeXpertVisio";
import IframeWindTransfer from "../../general/IframeWindTransfer";

import solution from "../../../images/solution.svg";
import visioexpertise from "../../../images/visioexpertise.svg";
import terrain from "../../../images/terrain.svg";
import selfcare from "../../../images/selfcare.svg";
import ead from "../../../images/ead.svg";

import { getSelfcareWindtransferExpertiseSolution } from "../../../requests/folder";

import AuthContext from "../../../context/AuthContext";
export default class ExpertiseSolution extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModal: false,
      isModalCatnat: false,
      isModalAnswer: false,
      isModalAnswerCatnat: false,
      isModalXpertVisio: false,
      isModalWindTransfer: false,
    };

    this.modalAnswerExpertiseSolutionRef = null;
    this.modalAnswerCatnatRef = null;
    this.selectedSolutionImg = null;
    this.text = "";
  }

  static contextType = AuthContext;

  openExpertiseSolution = () => {
    this.setState({ isModal: true });
  };

  getTypeExpertise = (data, history) => {
    this.selectedSolutionImg = solution;

    let type = "N.C.";
    let isVisio = false;

    if (history.length) {
      if (data.blockedGarage_radio) {
        this.selectedSolutionImg = terrain;
        type = `Expertise chez votre réparateur`;
      } else if (history.includes(10)) {
        this.selectedSolutionImg = selfcare;
        type = "Selfcare";
      } else if (history.includes(11)) {
        this.selectedSolutionImg = visioexpertise;
        type = `Visioconférence le ${moment(data.dateCalendarVisio).format(
          "DD/MM/YYYY"
        )} à ${data.timeCalendarVisio}`;
        isVisio = true;
      } else if (history.includes(12)) {
        this.selectedSolutionImg = ead;
        type = "EAD réparateur";
      } else if (history.includes(9) && data.expertiseLocation_radio) {
        this.selectedSolutionImg = terrain;
        if (
          [
            "À votre domicile ?",
            "Sur votre lieu de travail ?",
            // "Chez votre réparateur ?",
            "Autre lieu ?",
          ].includes(data.expertiseLocation_radio)
        ) {
          type = `Expertise ${data.expertiseLocation_radio
            .toLowerCase()
            .replace("?", "")}${
            data.dateCalendarAppointment
              ? " le " +
                moment(data.dateCalendarAppointment).format("DD/MM/YYYY")
              : ""
          }`;
        } else {
          type = `Expertise ${data.expertiseLocation_radio
            .toLowerCase()
            .replace("?", "")}${
            data.dateCalendarAppointment
              ? " le " +
                moment(data.dateCalendarAppointment).format("DD/MM/YYYY")
              : ""
          }${
            data.timeCalendarAppointment
              ? " à " + data.timeCalendarAppointment
              : ""
          }`;
        }
      }
    }

    return { type, isVisio };
  };

  getSelfcareWindtransfer = () => {
    if (
      !this.props.dataExpertiseForm.urlWindTransfer ||
      this.props.dataExpertiseForm.statusWindTransfer
    ) {
      getSelfcareWindtransferExpertiseSolution(
        {
          clientName: this.context.client.name,
          reference: this.context.sinister.reference,
          agencyMail: this.context.agency.email,
          licensePlate: this.context.vehicle.licensePlate,
          companyId: this.context.principal.companyId,
        },
        (result) => {
          if (result.success) {
            let data = this.props.dataExpertiseForm;

            data.urlWindTransfer = result.url;
            data.idWindTransfer = result.id;
            data.statusWindTransfer = false;

            this.setState({ isModalWindTransfer: true }, () => {
              this.props.updateExpertiseSolution(
                data,
                this.props.dataExpertiseFormPage
              );
            });
          } else {
            alert("Une erreur est survenue");
          }
        }
      );
    } else {
      this.setState({ isModalWindTransfer: true });
    }
  };

  getData = () => {
    let status = 0;
    let text = "La solution d'expertise n'a pas encore été définie";
    let special = null;

    let currentPage = 0;
    const maxPage = 13;

    const resultTypeExpertise = this.getTypeExpertise(
      this.props.dataExpertiseForm,
      this.props.dataExpertiseFormPage
    );

    const type = resultTypeExpertise.type;
    const isVisio = resultTypeExpertise.isVisio;

    if (this.props.dataExpertiseFormPage.length) {
      currentPage =
        this.props.dataExpertiseFormPage[
          this.props.dataExpertiseFormPage.length - 1
        ];
      if (currentPage === maxPage) {
        status = 2;
      } else {
        status = 1;
      }
    }

    if (
      status !== 2 &&
      this.props.dataTimeLine &&
      this.props.dataTimeLine.length &&
      (![2, 61, 5].includes(this.context.sinister.statusId) ||
        this.context.sinister.dateAppointment ||
        this.context.sinister.dateVisit)
    ) {
      status = 3;
      if (["2", "A"].includes(this.context.sinister.natureEventCode)) {
        status = 4;
      } else if ([4].includes(this.context.sinister.statusId)) {
        this.selectedSolutionImg = selfcare;
        status = 5;
        text =
          "Solution d'expertise choisie : EXPERTISE SELFCARE - Nous sommes dans l'attente du rapport WEPROOV";
      } else if ([8].includes(this.context.sinister.statusId)) {
        this.selectedSolutionImg = selfcare;
        status = 5;
        if (this.context.source === "client") {
          text =
            "Solution d'expertise choisie : EXPERTISE SELFCARE - Merci de prendre en photo les dommages de votre véhicule";
        } else {
          text =
            "Solution d'expertise choisie : EXPERTISE SELFCARE - L'assuré va prendre en photo les dommages du véhicule";
        }
        if (this.context.source === "client") {
          special = (
            <Button
              className="w-auto mt-10"
              text="Prendre les photos"
              onClick={this.getSelfcareWindtransfer}
            />
          );
        }
      } else if ([7].includes(this.context.sinister.statusId)) {
        this.selectedSolutionImg = ead;
        status = 5;
        if (this.context.source === "garage") {
          text =
            "Solution d'expertise choisie : EXPERTISE EAD - Le réparateur va se charger de prendre les photos de votre véhicule et nous les faire parvenir";
        } else {
          text =
            "Solution d'expertise choisie : EXPERTISE EAD - Merci de prendre les photos du véhicule et nous les faire parvenir";
        }
      } else if (["J"].includes(this.context.sinister.natureExpertiseCode)) {
        status = 5;
        if (this.context.sinister.EADSoc) {
          this.selectedSolutionImg = selfcare;
          text = "Solution d'expertise choisie : EXPERTISE SELFCARE";
        } else if (
          this.props.dataTimeLine.filter(
            (element) => element.situationCodeId === 9
          ) &&
          this.context.sinister.xpertVisioId
        ) {
          this.selectedSolutionImg = visioexpertise;
          text = "Solution d'expertise choisie : EXPERTISE VISIO";
        } else {
          this.selectedSolutionImg = ead;
          text = "Solution d'expertise choisie : EXPERTISE EAD";
        }
      } else if (
        this.context.sinister.dateAppointment &&
        moment(
          `${this.context.sinister.dateAppointment} 12:00:00`,
          "DD/MM/YYYY HH:mm:ss"
        ).isAfter(moment())
      ) {
        const isExpertiseOk = !this.props.dataTimeLine.filter(
          (element) => element.situationCodeId === 62
        ).length;

        status = 5;
        if (
          this.context.sinister.statusId &&
          [9].includes(this.context.sinister.statusId)
        ) {
          this.selectedSolutionImg = visioexpertise;
          text = `Solution d'expertise choisie : EXPERTISE VISIO ${
            isExpertiseOk
              ? `- Merci de vous rendre disponible le ${this.context.sinister.dateAppointment} à ${this.context.sinister.hourAppointment} pour la visioconférence comme convenu`
              : ""
          }`;
        } else if (
          this.context.sinister.locationCode === "1" ||
          this.context.sinister.locationCode === "9"
        ) {
          this.selectedSolutionImg = terrain;
          text = `Solution d'expertise choisie : EXPERTISE TERRAIN ${
            isExpertiseOk
              ? "- Merci de vous rendre disponible dès 8h00. L'expert passera dans la journée."
              : ""
          }`;
        } else if (this.context.sinister.locationCode === "3") {
          this.selectedSolutionImg = terrain;
          text = `Solution d'expertise choisie : EXPERTISE AU CABINET ${
            isExpertiseOk
              ? `- Merci de vous rendre à notre agence située ${
                  this.context.agency.name
                }, ${this.context.agency.address1}, ${
                  this.context.agency.zipCode
                } ${this.context.agency.city} le ${
                  this.context.sinister.dateAppointment
                }${
                  this.context.sinister.hourAppointment &&
                  this.context.sinister.hourAppointment !== "Invalid date"
                    ? ` à ${this.context.sinister.hourAppointment}`
                    : ""
                } comme convenu`
              : ""
          }`;
        } else if (this.context.sinister.locationCode === "2") {
          this.selectedSolutionImg = terrain;
          text = `Solution d'expertise choisie : EXPERTISE TERRAIN ${
            isExpertiseOk
              ? "- Merci de déposer votre véhicule dès l'ouverture du garage. L'expert passera dans la journée."
              : ""
          }`;
        }
      } else if (
        (this.context.sinister.dateVisit &&
          !this.context.sinister.dateAppointment) ||
        (this.context.sinister.dateAppointment &&
          moment(
            `${this.context.sinister.dateAppointment} 23:59:59`,
            "DD/MM/YYYY HH:mm:ss"
          ).isBefore(moment()))
      ) {
        status = 5;
        if (
          this.context.sinister.locationCode === "1" ||
          this.context.sinister.locationCode === "9"
        ) {
          this.selectedSolutionImg = terrain;
          text = `Solution d'expertise choisie : EXPERTISE TERRAIN`;
        } else if (this.context.sinister.locationCode === "3") {
          this.selectedSolutionImg = terrain;
          text = `Solution d'expertise choisie : EXPERTISE AU CABINET`;
        } else if (this.context.sinister.locationCode === "2") {
          this.selectedSolutionImg = terrain;
          text = `Solution d'expertise choisie : EXPERTISE TERRAIN`;
        }
      }
    }

    let extra = "";
    if (status === 2 && this.context.sinister.statusId === 83) {
      extra =
        "Nous avons bien pris en compte votre choix. Après analyse, notre équipe administrative reviendra vers vous si nécessaire.";
    }

    this.text = text;
    if (
      this.context.sinister.managingId ||
      this.context.sinister.statusId === 84 ||
      this.context.sinister.natureEventType === 14 ||
      (this.props.dataExpertiseCatnatPage &&
        this.props.dataExpertiseCatnatPage.includes(10))
    ) {
      status = 6;
    }

    switch (status) {
      case 0:
        if (this.context.source === "client") {
          return (
            <>
              <p>
                Nous vous invitons à cliquer sur le bouton ci-dessous afin
                choisir la solution d'expertise adaptée à vos besoins.
              </p>
              <Button
                className="w-auto mt-10"
                style={{ backgroundColor: "#db2828", borderColor: "#db2828" }}
                text="Choisir ma solution d'expertise"
                onClick={() => this.setState({ isModal: true })}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </>
          );
        } else {
          return <p>La solution d'expertise n'a pas encore été choisie</p>;
        }
      case 1:
        return (
          <>
            <p>
              En cours{" "}
              {currentPage ? Math.round((currentPage * 100) / maxPage) : 0}%
            </p>
            {this.context.source === "client" ? (
              <Button
                className="w-auto mt-10"
                style={{ backgroundColor: "#db2828", borderColor: "#db2828" }}
                text="Reprendre le questionnaire"
                onClick={() => this.setState({ isModal: true })}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            ) : null}
          </>
        );
      case 2:
        this.text = `Solution d'expertise choisie : ${type}`;
        return (
          <>
            <p>
              Solution d'expertise choisie : <b>{type}</b> {extra}
            </p>
            {isVisio &&
            this.context.source === "client" &&
            this.props.dataExpertiseForm.dateCalendarVisio ===
              moment().format("YYYY-MM-DD") &&
            moment().isAfter(
              moment(
                `${this.props.dataExpertiseForm.dateCalendarVisio} ${this.props.dataExpertiseForm.timeCalendarVisio}`,
                "YYYY-MM-DD HH:mm"
              )
            ) ? (
              <Button
                className="w-auto mt-10"
                text="Lancer la visioconférence"
                onClick={() => this.setState({ isModalXpertVisio: true })}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            ) : null}
            <Button
              className="w-auto mt-10"
              text="Voir les réponses"
              onClick={() => this.setState({ isModalAnswer: true })}
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </>
        );
      case 3:
        return (
          <>
            <p>La solution d'expertise n'a pas encore été définie</p>
          </>
        );
      case 4:
        return (
          <>
            <p>Le dossier n'est pas compatible</p>
          </>
        );
      case 5:
        return this.context.source === "client" ? (
          <>
            <p>{text}</p>
            {special}
          </>
        ) : (
          <p>{text}</p>
        );
      case 6:
        if (
          this.props.dataExpertiseCatnatPage &&
          this.props.dataExpertiseCatnatPage.includes(10)
        ) {
          return (
            <>
              {this.context.sinister.dateVisit ||
              (this.context.sinister.dateAppointment &&
                moment(this.context.sinister.dateAppointment).isAfter(
                  moment().subtract(1, "years")
                )) ? (
                <p>Solution d'expertise choisie : EXPERTISE TERRAIN</p>
              ) : null}
              <Button
                className="w-auto mt-10"
                text="Voir les réponses"
                onClick={() => this.setState({ isModalAnswerCatnat: true })}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </>
          );
        }

        if (
          this.context.sinister.dateVisit ||
          (this.context.sinister.dateAppointment &&
            moment(this.context.sinister.dateAppointment).isAfter(
              moment().subtract(1, "years")
            ))
        ) {
          return <p>Solution d'expertise choisie : EXPERTISE TERRAIN</p>;
        }

        return this.context.source === "client" ? (
          <>
            <p>La solution d'expertise n'a pas encore été définie</p>
            <p>
              Un événement climatique d'ampleur a provoqué de nombreux dommages.
            </p>
            <p>
              Nous vous invitons à cliquer sur le bouton ci-dessous afin de nous
              renseigner sur l’état du véhicule pour que nous puissions vous
              recontacter au plus vite.
            </p>
            <Button
              className="w-auto mt-10"
              style={{ backgroundColor: "#db2828", borderColor: "#db2828" }}
              text="Renseigner l’état de mon véhicule"
              onClick={() => this.setState({ isModalCatnat: true })}
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </>
        ) : (
          <p>La solution d'expertise n'a pas encore été définie</p>
        );

      default:
        return null;
    }
  };

  openModalCatnat = () => {
    this.setState({
      isModalCatnat: true,
    });
  };

  render() {
    const data = this.getData();

    return (
      <>
        <Card
          title="Solution d'expertise"
          className="h-100"
          classNameChildren="mt-auto mb-auto"
          imgRight={!this.props.isSmall ? this.selectedSolutionImg : null}
          customWidthImgRight={"660px"}
          forceRight
        >
          {data}
        </Card>
        <ModalExpertiseSolution
          show={this.state.isModal}
          dataExpertiseForm={this.props.dataExpertiseForm}
          dataExpertiseFormPage={this.props.dataExpertiseFormPage}
          getTypeExpertise={this.getTypeExpertise}
          onClose={(
            dataExpertiseForm,
            dataExpertiseFormPage,
            callback = null,
            isEnd = false
          ) =>
            this.setState({ isModal: false }, () => {
              this.props.onCloseAlert();
              if (callback) {
                callback();
              } else {
                if (dataExpertiseFormPage.includes(13) && !isEnd) {
                  dataExpertiseFormPage.pop();
                }

                this.props.updateExpertiseSolution(
                  dataExpertiseForm,
                  dataExpertiseFormPage,
                  this.modalAnswerExpertiseSolutionRef
                );
              }
            })
          }
          updateExpertiseSolution={(
            dataExpertiseForm,
            dataExpertiseFormPage
          ) => {
            this.props.updateExpertiseSolution(
              dataExpertiseForm,
              dataExpertiseFormPage
            );
          }}
        />
        <ModalCatnat
          show={this.state.isModalCatnat}
          dataPRE={this.props.dataPRE}
          dataExpertiseCatnat={this.props.dataExpertiseCatnat}
          dataExpertiseCatnatPage={this.props.dataExpertiseCatnatPage}
          onClose={(dataExpertiseForm, dataExpertiseFormPage) => {
            this.setState({ isModalCatnat: false }, () => {
              this.props.onCloseAlert();
              this.props.updateCatnat(
                dataExpertiseForm,
                dataExpertiseFormPage,
                this.modalAnswerCatnatRef
              );
            });
          }}
        />
        <ModalAnswerExpertiseSolution
          ref={(ref) => {
            this.modalAnswerExpertiseSolutionRef = ref;
          }}
          show={this.state.isModalAnswer}
          dataExpertiseForm={this.props.dataExpertiseForm}
          dataExpertiseFormPage={this.props.dataExpertiseFormPage}
          text={this.text}
          onClose={() => this.setState({ isModalAnswer: false })}
        />
        <ModalAnswerCatnat
          ref={(ref) => {
            this.modalAnswerCatnatRef = ref;
          }}
          show={this.state.isModalAnswerCatnat}
          dataExpertiseCatnat={this.props.dataExpertiseCatnat}
          dataExpertiseCatnatPage={this.props.dataExpertiseCatnatPage}
          text={this.text}
          onClose={() => this.setState({ isModalAnswerCatnat: false })}
        />
        <IframeXpertVisio
          onClose={() => this.setState({ isModalXpertVisio: false })}
          show={this.state.isModalXpertVisio}
          url={`https://xpertvisio.fr/client/${this.props.dataExpertiseForm.tokenVisio}`}
        />
        <IframeWindTransfer
          show={this.state.isModalWindTransfer}
          url={this.props.dataExpertiseForm.urlWindTransfer}
          onClose={(status) => {
            let data = this.props.dataExpertiseForm;

            if (status) {
              data.statusWindTransfer = true;
            }

            this.setState(
              {
                isModalWindTransfer: false,
              },
              () => {
                this.props.updateExpertiseSolution(
                  data,
                  this.props.dataExpertiseFormPage
                );
              }
            );
          }}
        />
      </>
    );
  }
}
