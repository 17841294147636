import React from "react";
import { Status } from "ui-kit-ck-consultant";

export default class FolderStatus extends React.Component {
  render() {
    return (
      <>
        {this.props.status ? (
          <Status
            text={`${this.props.status.replace(
              "<DATERDV>",
              this.props.dateAppointment
            )}`}
            className={this.props.className}
            style={{
              padding: "15px",
              fontSize: "14px",
            }}
          />
        ) : (
          <Status
            className="m-auto bg-blue"
            text="Aucun état enregistré"
            style={{ padding: "15px", fontSize: "14px" }}
          />
        )}
      </>
    );
  }
}
