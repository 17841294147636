const handleSortClick = (sortRef, desc, column) => {
  sortRef.forEach((elem, idx) => {
    if (elem && elem.current && idx !== column) {
      sortRef[idx].current.setState({ desc: false, sort: false });
    }
  });

  let order = { name: sortRef[column].current.props.nameSort, desc };
  return order;
};

export default handleSortClick;