import React from "react";
import { Loader, Button } from "ui-kit-ck-consultant";

import logo from "../../images/sigle-ixperience.svg";
import logoLangResize from "../../images/logo-lang-resize.png";

import { setNotificationChoiceAuth } from "../../requests/setting";

export default class Reset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      success: null,
    };
  }

  setNotificationChoiceAuth() {
    const token = this.props.match.params.token;
    this.setState({ isLoad: true }, () => {
      setNotificationChoiceAuth(
        {
          token: token,
          notification: false,
        },
        (result) => {
          if (result.success) {
            this.setState({ success: true, isLoad: false });
          } else {
            this.setState({ success: false, isLoad: false });
          }
        }
      );
    });
  }

  render() {
    return (
      <div>
        <img
          alt="logo"
          className="d-block ml-auto mr-auto mb-20"
          style={{ maxWidth: "100%" }}
          height="60"
          src={logo}
        />
        <h4 className="text-center">
          Bienvenue sur le portail dédié au suivi de votre dossier de sinistre.
        </h4>
        <img height={60} alt="groupe lang & associés" src={logoLangResize} />
        <h4 className="pt-20 text-center">Gestion des notifications</h4>

        {this.state.success !== null ? (
          <>
            <p className="mb-20 text-center">
              {this.state.success
                ? "Les notifications par email ont bien été désactivées."
                : "Une erreur est survenue lors de la sauvegarde de vos préférences."}
            </p>
            <Button
              className="mb-20"
              text="Retour vers le site"
              onClick={() => this.props.history.push("/home")}
            />
          </>
        ) : (
          <Button
            onClick={() => this.setNotificationChoiceAuth()}
            red
            text="Je ne souhaite plus recevoir de notfications"
          />
        )}

        <Loader show={this.state.isLoad} />
      </div>
    );
  }
}
