import React, { createRef } from "react";
import {
  Modal,
  Button,
  FormRadio,
  FormInput,
  ButtonIcon,
  FormSelect,
} from "ui-kit-ck-consultant";
import SignatureCanvas from "react-signature-canvas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faArrowLeft,
  faCamera,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { RCamera } from "react-camera-media-stream";

import carAVD from "../../../../images/mask/car-01-AVD.png";
import carARD from "../../../../images/mask/car-01-ARD.png";
import carARG from "../../../../images/mask/car-01-ARG.png";
import carAVG from "../../../../images/mask/car-01-AVG.png";

import AuthContext from "../../../../context/AuthContext";

export default class ModalCatnat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      history: [0],
      data: {},
      isCamera: false,
      cameraLocation: null,
    };

    this.signatureRef = createRef();
  }

  static contextType = AuthContext;

  componentDidUpdate(prevProps, prevState) {
    if (this.props.show && this.props.show !== prevProps.show) {
      this.setState({
        data: this.props.dataExpertiseCatnat,
        history: this.props.dataExpertiseCatnatPage.length
          ? this.props.dataExpertiseCatnatPage
          : [0],
        currentPage: this.props.dataExpertiseCatnatPage.length
          ? this.props.dataExpertiseCatnatPage[
              this.props.dataExpertiseCatnatPage.length - 1
            ]
          : 0,
      });
    }
  }

  updateData = (name, value, callback = () => {}) => {
    let data = JSON.parse(JSON.stringify(this.state.data));
    data[name] = value;

    this.setState(
      {
        data,
      },
      callback
    );
  };

  backPage = () => {
    let tmpHistory = this.state.history.slice(0, -1);

    this.setState({
      currentPage: this.state.history[this.state.history.length - 2],
      history: tmpHistory,
    });
  };

  nextPage = (page = null) => {
    let tmpHistory = this.state.history;
    let tmpPage = page !== null ? page : this.state.currentPage + 1;

    tmpHistory.push(tmpPage);

    this.setState({
      currentPage: tmpPage,
      history: tmpHistory,
    });
  };

  getButtonBack = () => {
    if (this.state.history.length) {
      return (
        <Button
          text="Retour"
          className="m-auto mr-5 w-auto"
          onClick={() => this.backPage()}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
      );
    }
  };

  getButtonNext = (onClick = () => this.nextPage(), isMarginLeft = true) => {
    return (
      <Button
        text="Continuer"
        className={`m-auto ${isMarginLeft ? "ml-5" : ""} w-auto`}
        onClick={onClick}
      >
        <FontAwesomeIcon icon={faArrowRight} />
      </Button>
    );
  };

  getCorrectInput = (isExist, name, text, required = true) => {
    const data = this.state.data;

    if (isExist) {
      return (
        <>
          <p>
            {text}
            {required ? <span className="red"> *</span> : null}
          </p>
          <FormRadio
            text="Oui"
            value={1}
            className="mb-10"
            checked={data[`${name}_radio`] === 1}
            onChange={() => this.updateData(`${name}_radio`, 1)}
          />
          <FormRadio
            text="Non"
            value={0}
            className="mb-10"
            checked={data[`${name}_radio`] === 0}
            onChange={() => this.updateData(`${name}_radio`, 0)}
          />
          {data[`${name}_radio`] === 0 ? (
            <FormInput
              title="Correction"
              value={data[`${name}_text`]}
              onChange={({ target }) =>
                this.updateData(`${name}_text`, target.value)
              }
            />
          ) : null}
        </>
      );
    } else {
      return (
        <>
          <p>
            {text}
            {required ? <span className="red"> *</span> : null}
          </p>
          <FormInput
            value={data[`${name}_text`] ? data[`${name}_text`] : ""}
            onChange={({ target }) => {
              this.updateData(`${name}_radio`, 0);
              this.updateData(`${name}_text`, target.value);
            }}
          />
        </>
      );
    }
  };

  getRadio = (name, text, required = true, values = []) => {
    const data = this.state.data;

    return (
      <>
        <p>
          {text}
          {required ? <span className="red"> *</span> : null}
        </p>
        {values.length ? (
          <>
            {values.map((value, idx) => (
              <FormRadio
                key={idx}
                text={value}
                value={value}
                className="mb-10"
                checked={data[`${name}_radio`] === value}
                onChange={() => this.updateData(`${name}_radio`, value)}
              />
            ))}
          </>
        ) : (
          <>
            <FormRadio
              text="Oui"
              value={1}
              className="mb-10"
              checked={data[`${name}_radio`] === 1}
              onChange={() => this.updateData(`${name}_radio`, 1)}
            />
            <FormRadio
              text="Non"
              value={0}
              className="mb-10"
              checked={data[`${name}_radio`] === 0}
              onChange={() => this.updateData(`${name}_radio`, 0)}
            />
          </>
        )}
      </>
    );
  };

  getPage = () => {
    switch (this.state.currentPage) {
      case 0:
        return (
          <>
            <h3 className="text-center">
              DÉFINISSONS ENSEMBLE VOTRE SOLUTION D'EXPERTISE
            </h3>
            <p>
              En répondant à ce questionnaire intéractif, vous nous aidez à
              adapter votre parcours afin qu'il corresponde parfaitement à vos
              attentes.
            </p>
            <div className="d-flex mt-10">
              {this.getButtonNext(undefined, false)}
            </div>
          </>
        );
      case 1:
        return (
          <>
            {this.getCorrectInput(
              !!this.context.client.cellphone,
              "cellphone",
              !!this.context.client.cellphone ? (
                <>
                  Votre numéro de téléphone portable est bien le{" "}
                  <b>{this.context.client.cellphone}</b> ?
                </>
              ) : (
                <>Merci de renseigner votre numéro de téléphone portable</>
              )
            )}
            {this.getCorrectInput(
              !!this.context.client.email,
              "email",
              !!this.context.client.email ? (
                <>
                  Votre adresse e-mail est bien{" "}
                  <b>{this.context.client.email}</b> ?
                </>
              ) : (
                <>Merci de renseigner votre adresse e-mail</>
              )
            )}
            {this.getCorrectInput(
              !!this.context.vehicle.brand,
              "brand",
              !!this.context.vehicle.brand ? (
                <>
                  La marque de votre véhicule est bien{" "}
                  <b>{this.context.vehicle.brand}</b> ?
                </>
              ) : (
                <>Merci de renseigner votre la marque de votre véhicule</>
              )
            )}
            {this.getCorrectInput(
              !!this.context.vehicle.model,
              "model",
              !!this.context.vehicle.model ? (
                <>
                  Le modèle de votre véhicule est bien{" "}
                  <b>{this.context.vehicle.model}</b> ?
                </>
              ) : (
                <>Merci de renseigner le modèle de votre véhicule</>
              )
            )}
            {this.getCorrectInput(
              !!this.context.vehicle.licensePlate,
              "licensePlate",
              !!this.context.vehicle.licensePlate ? (
                <>
                  L'immatriculation de votre véhicule est bien{" "}
                  <b>{this.context.vehicle.licensePlate}</b> ?
                </>
              ) : (
                <>
                  Merci de renseigner votre l'immatriculation de votre véhicule
                </>
              )
            )}
            {this.getCorrectInput(
              false,
              "mileage",
              <>Merci de renseigner votre le kilométrage de votre véhicule</>
            )}
            <>
              <p>Lieu où se trouve actuellement le véhicule : </p>
              <FormSelect
                value={this.state.data.location_select}
                ignore={true}
                options={[
                  {
                    value: "DEFAULT",
                    text: "Veuillez sélectionner un lieu",
                    disabled: true,
                  },
                  {
                    value: "Domicile",
                    text: "Domicile",
                  },
                  {
                    value: "Réparateur",
                    text: "Réparateur",
                  },
                  {
                    value: "Dépanneur",
                    text: "Dépanneur",
                  },
                  {
                    value: "Autre",
                    text: "Autre",
                  },
                ]}
                onChange={({ target }) =>
                  this.updateData(`location_select`, target.value)
                }
              />
              {this.state.data.location_select === "Autre" ? (
                <FormInput
                  placeholder={"Autre lieu"}
                  className="mt-10"
                  onChange={({ target }) =>
                    this.updateData(`location_text`, target.value)
                  }
                />
              ) : null}
            </>
            <div className="d-flex mt-10">
              {this.getButtonBack()}
              {this.getButtonNext(() => {
                if (
                  (this.state.data.cellphone_radio ||
                    this.state.data.cellphone_text) &&
                  (this.state.data.email_radio || this.state.data.email_text) &&
                  (this.state.data.brand_radio || this.state.data.brand_text) &&
                  (this.state.data.model_radio || this.state.data.model_text) &&
                  (this.state.data.licensePlate_radio ||
                    this.state.data.licensePlate_text) &&
                  this.state.data.mileage_text
                ) {
                  this.nextPage();
                } else {
                  alert("Veuillez remplir tous les champs");
                }
              })}
            </div>
          </>
        );
      case 2:
        return (
          <>
            {this.getRadio("rolling", "Votre véhicule est-il roulant ?")}
            <div className="d-flex mt-10">
              {this.getButtonBack()}
              {this.getButtonNext(() => {
                if (this.state.data["rolling_radio"] === 1) {
                  this.nextPage();
                } else if (this.state.data["rolling_radio"] === 0) {
                  this.nextPage(7);
                } else {
                  alert("Veuillez cocher une option");
                }
              })}
            </div>
          </>
        );
      case 3:
        return (
          <>
            {this.getRadio(
              "glass",
              "Est-ce qu'au moins un élément vitré est brisé (Pare-brise, lunette AR, vitres latérales, toit panoramique) ?"
            )}
            <div className="d-flex mt-10">
              {this.getButtonBack()}
              {this.getButtonNext(() => {
                if (this.state.data["glass_radio"] === 1) {
                  this.nextPage(7);
                } else if (this.state.data["glass_radio"] === 0) {
                  this.nextPage();
                } else {
                  alert("Veuillez cocher une option");
                }
              })}
            </div>
          </>
        );
      case 4:
        return (
          <>
            {this.getRadio(
              "light",
              "Les feux arrière et / ou les optiques avant sont-ils brisés ?"
            )}
            <div className="d-flex mt-10">
              {this.getButtonBack()}
              {this.getButtonNext(() => {
                if (this.state.data["light_radio"] === 1) {
                  this.nextPage(6);
                } else if (this.state.data["light_radio"] === 0) {
                  this.nextPage();
                } else {
                  alert("Veuillez cocher une option");
                }
              })}
            </div>
          </>
        );
      case 5:
        return (
          <>
            {this.getRadio("age", "Le véhicule a-t-il plus de 5 ans ?")}
            <div className="d-flex mt-10">
              {this.getButtonBack()}
              {this.getButtonNext(() => {
                if (this.state.data["age_radio"] === 1) {
                  this.nextPage(7);
                } else if (this.state.data["age_radio"] === 0) {
                  this.nextPage();
                } else {
                  alert("Veuillez cocher une option");
                }
              })}
            </div>
          </>
        );
      case 6:
        return (
          <>
            {this.getRadio(
              "VEI",
              "Selon vous, le véhicule risque-t-il de ne pas être réparable économiquement ?"
            )}
            <div className="d-flex mt-10">
              {this.getButtonBack()}
              {this.getButtonNext(() => {
                if (this.state.data["VEI_radio"] === 1) {
                  this.nextPage(7);
                } else if (this.state.data["VEI_radio"] === 0) {
                  this.nextPage(7);
                } else {
                  alert("Veuillez cocher une option");
                }
              })}
            </div>
          </>
        );
      case 7:
        return (
          <>
            {this.getRadio(
              "repair",
              "Si possible, souhaitez-vous faire réparer votre véhicule ?"
            )}
            <div className="d-flex mt-10">
              {this.getButtonBack()}
              {this.getButtonNext(() => {
                if (this.state.data["repair_radio"] === 1) {
                  if (!this.props.dataPRE) {
                    this.nextPage();
                  } else {
                    this.nextPage(9);
                  }
                } else if (this.state.data["repair_radio"] === 0) {
                  if (!this.props.dataPRE) {
                    this.nextPage();
                  } else {
                    this.nextPage(9);
                  }
                } else {
                  alert("Veuillez cocher une option");
                }
              })}
            </div>
          </>
        );
      case 8:
        return (
          <>
            {this.getRadio(
              "PRE",
              "Êtes-vous d’accord pour l’utilisation de pièces de réemploi ?"
            )}
            <FormInput
              title="Nom"
              name="PRE_text"
              value={this.state["PRE_text"]}
              onChange={({ target }) =>
                this.updateData(`PRE_text`, target.value)
              }
            />
            <div className="d-flex">
              <b className="mt-auto mb-auto">Signature</b>
              <ButtonIcon
                className="red"
                small
                onClick={() => this.signatureRef.current.clear()}
              >
                <FontAwesomeIcon icon={faTrash} />
              </ButtonIcon>
            </div>
            <SignatureCanvas
              ref={this.signatureRef}
              penColor="black"
              canvasProps={{
                style: {
                  border: "var(--gray) dashed 2px",
                  borderRadius: "15px",
                  minHeight: "200px",
                  maxHeight: "240px",
                  maxWidth: "280px",
                },
                className: "sigCanvas mt-5 mb-10",
              }}
            />
            <div className="d-flex mt-10">
              {this.getButtonBack()}
              {this.getButtonNext(() => {
                this.updateData(
                  `PRE_base64`,
                  this.signatureRef.current
                    .getTrimmedCanvas()
                    .toDataURL("image/png"),
                  () => {
                    if (this.state.data["PRE_radio"] === 1) {
                      this.nextPage();
                    } else if (this.state.data["PRE_radio"] === 0) {
                      this.nextPage();
                    } else {
                      alert("Veuillez cocher une option");
                    }
                  }
                );
              })}
            </div>
          </>
        );
      case 9:
        return (
          <>
            <p>
              Vous avez la possibilité de nous faire parvenir des photos du
              véhicule ci-dessous
            </p>
            <div className="d-flex" style={{ flexWrap: "wrap" }}>
              <Button
                text="3/4 AVD"
                className={`w-auto m-10 ${
                  this.state.data && this.state.data["carAVD_picture"]
                    ? "bg-green"
                    : ""
                }`}
                outline={
                  this.state.data && this.state.data["carAVD_picture"]
                    ? false
                    : true
                }
                onClick={() =>
                  this.setState({
                    isCamera: true,
                    cameraLocation: "carAVD",
                  })
                }
              >
                <FontAwesomeIcon icon={faCamera} />
              </Button>
              <Button
                text="3/4 ARD"
                className={`w-auto m-10 ${
                  this.state.data && this.state.data["carARD_picture"]
                    ? "bg-green"
                    : ""
                }`}
                outline={
                  this.state.data && this.state.data["carARD_picture"]
                    ? false
                    : true
                }
                onClick={() =>
                  this.setState({
                    isCamera: true,
                    cameraLocation: "carARD",
                  })
                }
              >
                <FontAwesomeIcon icon={faCamera} />
              </Button>
              <Button
                text="3/4 ARG"
                className={`w-auto m-10 ${
                  this.state.data && this.state.data["carARG_picture"]
                    ? "bg-green"
                    : ""
                }`}
                outline={
                  this.state.data && this.state.data["carARG_picture"]
                    ? false
                    : true
                }
                onClick={() =>
                  this.setState({
                    isCamera: true,
                    cameraLocation: "carARG",
                  })
                }
              >
                <FontAwesomeIcon icon={faCamera} />
              </Button>
              <Button
                text="3/4 AVG"
                className={`w-auto m-10 ${
                  this.state.data && this.state.data["carAVG_picture"]
                    ? "bg-green"
                    : ""
                }`}
                outline={
                  this.state.data && this.state.data["carAVG_picture"]
                    ? false
                    : true
                }
                onClick={() =>
                  this.setState({
                    isCamera: true,
                    cameraLocation: "carAVG",
                  })
                }
              >
                <FontAwesomeIcon icon={faCamera} />
              </Button>
            </div>
            <div className="d-flex mt-10">
              {this.getButtonBack()}
              {this.getButtonNext(() => this.nextPage())}
            </div>
          </>
        );
      case 10:
        return (
          <>
            <p>
              Nous avons bien pris en compte vos éléments. Après analyse, notre
              équipe administrative reviendra vers vous au plus vite afin de
              vous proposer une date de rendez-vous d’expertise.
            </p>
            <p>MERCI DE NE PAS NOUS CONTACTER PAR TÉLÉPHONE</p>
            <div className="d-flex mt-10">
              {this.getButtonBack()}
              <Button
                text="Valider"
                className="m-auto ml-5 w-auto"
                onClick={() =>
                  this.props.onClose(this.state.data, this.state.history)
                }
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </div>
          </>
        );
      default:
        return <div className="d-flex mt-10">{this.getButtonBack()}</div>;
    }
  };

  getImage = () => {
    switch (this.state.cameraLocation) {
      case "carAVD":
        return carAVD;
      case "carARD":
        return carARD;
      case "carARG":
        return carARG;
      case "carAVG":
        return carAVG;
      default:
        return null;
    }
  };

  render() {
    return (
      <>
        <Modal
          className="m-auto"
          show={this.props.show}
          onClose={() =>
            this.props.onClose(this.state.data, this.state.history)
          }
          large
          style={{ color: "red" }}
        >
          {this.getPage()}
        </Modal>
        {this.state.isCamera ? (
          <RCamera
            model={this.getImage()}
            isConfirm={false}
            imageCompression={0.7}
            onTakePicture={(base64) => {
              const mime = base64.split(";")[0].split(":")[1];

              this.updateData(`${this.state.cameraLocation}_picture`, {
                base64,
                extension: mime.split("/")[1],
                mime: mime,
                name: `ixperience-${new Date().getTime()}`,
              });
            }}
            onClose={() =>
              this.setState({ isCamera: false, cameraLocation: null })
            }
            isFullscreen={true}
            namePicture="ixperience"
            isTorch={true}
          />
        ) : null}
      </>
    );
  }
}
