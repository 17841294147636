import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Cookie, Loader } from "ui-kit-ck-consultant";

import routes from "./routes";

import AuthContext from "./context/AuthContext";

import { rgpdAndCookies } from "./assets/rgpd";

import logoLangResize from "./images/logo-lang-resize.png";

import { refreshToken, signout } from "./requests/auth";

import ReactGA from "react-ga4";
ReactGA.initialize("G-EBKKGPK48B");

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
      connected: null,
      user: null,
      username: null,
      email: null,
      source: null,
      type: null,
      role: null,
      agency: null,
      region: null,
      color: null,
    };
  }

  componentDidMount() {
    this.checkAuth();
    this.intervalToken = setInterval(() => {
      this.checkAuth();
    }, 30 * 60 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalToken);
  }

  checkAuth = () => {
    let token = null;
    const pathArray = document.location.href.split("/");
    if (pathArray.includes("success")) {
      token = pathArray[pathArray.length - 1];
    }

    if (!document.location.href.includes("signout")) {
      const urlSearchString = window.location.search;
      const params = new URLSearchParams(urlSearchString);

      refreshToken(token, params.get("_k"), (result) => {
        if (result.success) {
          if (window.location.href.includes("hubex")) {
            window.location.href = "/";
          } else if (
            window.location.href.includes("signin") &&
            window.location.href.split("/").pop() !== "signin"
          ) {
            window.location.href = `/folder/${window.location.href
              .split("/")
              .pop()}`;
          }
          const oldState = this.state;
          const newState = {
            isReady: true,
            connected: true,
            user: result.data.id,
            username: result.data.username,
            email: result.data.email,
            source: result.data.source,
            type: result.data.type,
            role: result.data.role,
            agency: result.data.agency,
            region: result.data.region,
            color: result.data.color,
          };
          if (JSON.stringify(oldState) !== JSON.stringify(newState)) {
            this.setState(newState, () => {
              if (window.location.href.includes("success")) {
                window.location.href = "/";
              } else {
                ReactGA.set({ userSource: result.data.source }, ["tracker2"]);
              }
            });
          }
        } else {
          if (window.location.href.includes("hubex")) {
            window.location.href = result.redirect;
          }
          const oldState = this.state;
          const newState = {
            isReady: true,
            connected: false,
            id: null,
            username: null,
            email: null,
            source: null,
            type: null,
            role: null,
            agency: null,
            region: null,
            color: null,
          };
          if (JSON.stringify(oldState) !== JSON.stringify(newState)) {
            this.setState(newState);
          }
        }
      });
    } else {
      this.setState({ connected: false });
    }
  };

  render() {
    if (!this.state.isReady) {
      return <Loader show={true}></Loader>;
    }

    return (
      <AuthContext.Provider
        value={{
          user: this.state.user,
          username: this.state.username,
          email: this.state.email,
          source: this.state.source,
          type: this.state.type,
          role: this.state.role,
          agency: this.state.agency,
          region: this.state.region,
          color: this.state.color,
        }}
      >
        <Router basename={process.env.REACT_APP_BASENAME || ""}>
          <Switch>
            {routes.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={(props) => {
                    if (props.match.params.redirection) {
                      localStorage.setItem(
                        "lastToken",
                        props.match.params.redirection
                      );
                    } else if (props.match.params.token) {
                      localStorage.setItem(
                        "lastToken",
                        props.match.params.token
                      );
                    }

                    return (
                      <route.layout
                        {...props}
                        connected={this.state.connected}
                        source={this.state.source}
                        username={this.state.username}
                      >
                        <route.component {...props} />
                      </route.layout>
                    );
                  }}
                />
              );
            })}
          </Switch>
        </Router>
        <Cookie
          logo={logoLangResize}
          name="ixperience"
          infos={rgpdAndCookies}
          options={{
            maxAge: 365 * 24 * 60 * 60,
            path: "/",
          }}
          onReject={() => {
            signout(() => {
              document.location.href = "/signin";
            });
          }}
        />
      </AuthContext.Provider>
    );
  }
}
